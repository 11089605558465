import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';
import { PerfilcadastroComponent } from './perfilcadastro.component';


const routes: Routes = [

    { path: '', component: PerfilcadastroComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PerfilcadastroRoutingModule { }
