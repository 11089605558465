import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CKEditorModule } from 'ng2-ckeditor';
import { CadastrarobservacaofuncionalidadeComponent } from './cadastrarobservacaofuncionalidade.component';
import { PesquisarobservacaofuncionalidadeComponent } from './pesquisarobservacaofuncionalidade.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AmbienteSistemaRadioComponent } from '../ambiente-sistema-radio/ambiente-sistema-radio.component';
import { PipeModule } from '../../pipe.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { ObservacaofuncionalidadeRoutingModule } from './observacaofuncionalidade-routing.module';

@NgModule({
  declarations: [
    PesquisarobservacaofuncionalidadeComponent, CadastrarobservacaofuncionalidadeComponent, AmbienteSistemaRadioComponent
  ],
  imports: [
    CommonModule, FormsModule, TranslateModule, ReactiveFormsModule, ObservacaofuncionalidadeRoutingModule,
    AngularMultiSelectModule, NgxPaginationModule, PipeModule, CKEditorModule
  ]
})
export class ObservacaofuncionalidadeModule { }
