import { Injectable } from '@angular/core';
import { LogService } from '../../services/log/log.service';
import { Color} from 'ng2-charts';

const VERSION = '2.0.0';
const SERIES_COLOR: string[] = ['#277db6', '#f0ad4e', '#3c763d', '#f1ca3a', '#e2431e', '#5cb85c', '#F7464A', '#1c91c0', '#43459d', '#F7464A', '#46BFBD', '#B276B2', '#6f9654', '#e7711b', '#f1ca3a'];
declare const $: any;

@Injectable()
export class UtilsService {

  constructor(private log: LogService) { }

  chartTypeBar = 'bar';
  chartTypeLine = 'line';
  chartColors: Color[] = [
    { // #33B1AE
      backgroundColor: 'rgba(51,177,174,0.7)',
      borderColor: 'rgba(51,177,174,1)',
      pointBackgroundColor: 'rgba(51,177,174,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(51,177,174,0.8)'
    },
    { // #0065B7
      backgroundColor: 'rgba(0,101,183,0.7)',
      borderColor: 'rgba(0,101,183,1)',
      pointBackgroundColor: 'rgba(0,101,183,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(0,101,183,1)'
    },
    { // #1280B3
      backgroundColor: 'rgba(18,128,179,0.7)',
      borderColor: 'rgba(18,128,179,1)',
      pointBackgroundColor: 'rgba(18,128,179,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(18,128,179,0.8)'
    },
    {
      backgroundColor: 'rgba(57, 224, 63, 0.55)',
      borderColor: 'rgba(18,128,179,1)',
      pointBackgroundColor: 'rgba(18,128,179,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(18,128,179,0.8)'
    }
  ];

  private removeMask(string) {
    return string.replace(/([-.-/])/g, '');
  }

  private cleanUpSpecialChars(str) {
    str = str.replace(/[ÀÁÂÃÄÅ]/g, 'A');
    str = str.replace(/[àáâãäå]/g, 'a');
    str = str.replace(/[ÈÉÊË]/g, 'E');
    str = str.replace(/[ÌÍÎ]/g, 'I');
    str = str.replace(/[íìî]/g, 'i');
    str = str.replace(/[ÒÓÔ]/g, 'O');
    str = str.replace(/[òòô]/g, 'o');
    str = str.replace(/[ÙÚÛ]/g, 'U');
    str = str.replace(/[ùúû]/g, 'u');
    str = str.replace(/[Ç]/g, 'c');
    str = str.replace(/[ç]/g, 'c');
    return str.replace(/[^a-z0-9]/gi, ''); // final clean up
  }

  private _cordova(): any {
    return window['cordova'] != null;
  }

  private _isAndroid() {
    return navigator.userAgent.match(/Android/i) != null;
  }
  private _isIOS() {
    let found = navigator.userAgent.match(/iPhone/i) != null;
    if (!found) {
      found = navigator.userAgent.match(/iPad/i) != null;
    }
    if (!found) {
      found = navigator.userAgent.match(/iPod/i) != null;
    }
    return found;
  }
  private _isWPhone() {
    let found = navigator.userAgent.match(/Windows Phone/i) != null;
    if (!found) {
      found = navigator.userAgent.match(/iPad/i) != null;
    }
    if (!found) {
      found = navigator.userAgent.match(/iPod/i) != null;
    }

    return found;
  }
  private _isMobile() {
    return this._isAndroid() || this._isIOS() || this._isWPhone();
  }

  private _isNative() {
    const result = this._cordova();
    return result;
  }

  private _escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  }


  version(): string {
    return VERSION;
  }

  getRandom(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  getASCII(char: string) {
    return this.cleanUpSpecialChars(char);
  }

  replaceAll(str: string, find: string, replace: string) {
    if (str == null) {
      return str;
    }
    return str.replace(new RegExp(this._escapeRegExp(find), 'g'), replace);
  }

  getProfile(id: string) {
    // if (id == PROFILE_ADMIN) return 'ADMINISTADOR'
    // if (id == PROFILE_USUARIO) return 'USUÁRIO'
    return 'Perfil ' + id + ' não reconhecido';
  }

  getDevice() {
    const device = {
      isMobile: this._isMobile(),
      isAndroid: this._isAndroid(),
      isIOS: this._isIOS(),
      isNative: this._isNative()
    };
    return device;
  }

  getPlatforms() {
    let platforms = '';

    if (this._isMobile()) {

      if (this._isNative()) {
        platforms += 'nativo ';
      } else {
        platforms += 'mobile ';
      }

      if (this._isAndroid()) {
        platforms += 'android ';
      } else if (this._isIOS()) {
        platforms += 'ios ';
      } else if (this._isWPhone()) {
        platforms += 'winphone ';
      }
    } else {
      platforms = 'desktop';
    }

    return platforms;
  }

  isNumber(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  isEmail(email: string) {
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  validateCondition(condition, value, mask) {
    let found = true;
    if ((value != null) && (value !== '')) {
      value = value.toLowerCase();
      if (mask) {
        value = this.removeMask(value);
      }
      condition = condition.toLowerCase();
      if (condition.indexOf(value) < 0) {
        found = false;
      }
    }
    return found;
  }

  exportToFile(filename: string, data: any) {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  exportImage(filename: string, data: any) {
    const imgData = data.replace(/^data:[a-z/]*;base64,/, '');

    const byteString = atob(imgData);
    const buffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(buffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([buffer], { type: 'image/png' });

    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  exportBinary(filename: string, data: any) {
    const blob = new Blob([data], { type: 'application/zip;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  exportJsonToText(filename: string, json: JSON, separator: string) {
    let csvFile = '';

    for (const i of Object.keys(json)) {
      let line = '';
      const item = json[i];
      for (const key of Object.keys(item)) {
        line += item + separator;
      }
      csvFile += line + '\n';
    }

    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  exportToCsv(filename: string, data: any) {
    let csvFile = '';

    let rowCount = 0;
    $.each(data, function (i, row) {// Cada registro
      let line = '';
      let colCount = 0;

      if (rowCount === 0) { // Processar Header
        $.each(row, function (j, col) { // cada coluna
          if (colCount > 0) {
            line += ';';
          }
          line += j;
          colCount += 1;
        });

        csvFile += line + '\n';
        colCount = 0;
        line = '';
      }

      // Processar Dados
      $.each(row, function (j, col) { // cada coluna
        // Log.debug('col: ' +  col + ':' + j);
        let innerValue = col === null ? '' : col.toString();
        if (col instanceof Date) {
          innerValue = col.toLocaleString();
        }

        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = '"' + result + '"';
        }

        if (colCount > 0) {
          line += ';';
        }
        line += result;
        colCount += 1;
      });
      // Log.debug('line: ' + line);
      csvFile += line + '\n';
      rowCount += 1;
    });

    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  clearDataTable(dataTable, dataTableName) {
    if (dataTable != null) {
      dataTable.destroy();
      dataTable = null;
      $('#' + dataTableName).empty();
    }
  }

  plotDataTable(dataTableName, dataSource, showColor) {
    /*if (showColor == null) showColor = true;

    var columnTitle = Enumerable.From(dataSource.table).Select('$.title').ToArray();
    var columnName = Enumerable.From(dataSource.table).Select('$.name').ToArray();

    var columns = [];
    var i = 0;
    for (var i = 0; i < columnName.length; i++) {
      var name = columnName[i];
      var title = columnTitle[i];

      var column = null;
      if (i == 0) {
        column = {
          'mData': name,
          'sTitle': title,
          'mRender': function (data, type, full) {
            var color = null;

            if (full.serie != null) color = SERIES_COLOR[full.serie];
            if (color == null) color = SERIES_COLOR[full.id - 1]

            return '<button class="btn btn-info btn-sm" style="border-color:#fff;background-color:' + color + '">' + data + '</button>';
          }
        };
      } else {
        column = JSON.parse('{"mDataProp":"' + name + '","sTitle":"' + title + '"}');
      }
      //console.log('column:' + column.sTitle);
      columns.push(column);
    }

    var customRow = null;
    if (dataSource.customRow != null) {
      try {
        eval('customRow = ' + dataSource.customRow);
      } catch (error) {
        this.log.error(error);
      }
    }

    //Cria Tabela
    var dataTable = $('#' + dataTableName).DataTable({
      aaData: dataSource.data,
      'createdRow': customRow,
      //'oLanguage': dataTable_language,
      responsive: true,
      paging: false,
      'order': [[0, 'asc']],
      'info': false,
      searching: false,
      //scrollY: 300,
      aoColumns: columns
    });
    return dataTable;*/
  }

  plotChartBar(chart, chartName, response, legend) {
    if (legend == null) {
      legend = false;
    }

    if (response.data.length === 0) {
      $('#' + chartName).hide();
    } else {
      $('#' + chartName).show();
    }

    const data = [];
    const series = response.chart.series != null;
    const dataPoints = [];

    $.each(response.data, function (i, item) {
      let serie = item.serie;
      const labels = response.chart.labels;
      if (!serie) {
        serie = item.id - 1;
      }

      let label = item.label;
      if (series) {
        label = response.chart.series[0];
      }
      if (labels) {
        label = response.chart.labels[i];
      }

      let itemdata = {
        y: item.value,
        // x: i,
        label: label,
        color: SERIES_COLOR[serie]
      };
      dataPoints.push(itemdata);

      if (item.subvalue != null) {
        let labelx = item.label;
        if (series) {
          labelx = response.chart.series[1];
        }

        itemdata = {
          y: item.subvalue,
          // x: i,
          label: labelx,
          color: SERIES_COLOR[serie]
        };
        dataPoints.push(itemdata);
      }

    });
    data.push({ dataPoints: dataPoints });

    const options = { data: null };
    const chartOptions = null;
    $.extend(true, options, response.chart, chartOptions);
    options.data = data;
    console.log('options: ' + JSON.stringify(options));

    // var chart = new CanvasJS.Chart(chartName, options);
    // chart.render();

    return chart;
  }

  exportCanvas(canvas, format, fileName) {
    if (!canvas || !format || !fileName) {
      return;
    }

    const fullFileName = fileName + '.' + (format === 'jpeg' ? 'jpg' : format);
    const mimeType = 'image/' + format;
    const img = canvas.toDataURL(mimeType);
    let saved = false;

    const downloadLink = document.createElement('a');
    downloadLink.download = fullFileName;
    downloadLink.href = img;
    downloadLink.target = '_blank';

    if (typeof (Blob) !== 'undefined' && !!new Blob()) {

      // alert('blob');
      const imgData = img.replace(/^data:[a-z/]*;base64,/, '');

      const byteString = atob(imgData);
      const buffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(buffer);
      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([buffer], { type: 'image/' + format });

      // Save the blob
      try {
        window.navigator.msSaveBlob(blob, fullFileName);
        saved = true;
      } catch (e) {
        downloadLink.dataset.downloadurl = [mimeType, downloadLink.download, downloadLink.href].join(':');
        downloadLink.href = window.URL.createObjectURL(blob);
      }
    }

    if (!saved) {

      try {

        event = document.createEvent('MouseEvents');

        /*event.initMouseEvent('click', true, false, window,
          0, 0, 0, 0, 0, false, false, false,
          false, 0, null);

        if (downloadLink.dispatchEvent) {
          downloadLink.dispatchEvent(event);
        }
        else if (downloadLink.fireEvent) {
          downloadLink.fireEvent('onclick');
        }*/

      } catch (e) {
        const win = window.open();
        win.document.write('<img src="' + img + '"></img><div>Please right click on the image and save it to your device</div>');
        win.document.close();
      }
    }
  }
}
