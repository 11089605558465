import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatSpinner } from '@angular/material';
import { Subject } from 'rxjs';
import { map, scan } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UiService {

  private spinnerTopRef = this.cdkSpinnerCreate();

  spin$: Subject<boolean> = new Subject();

  constructor(private overlay: Overlay) {
    this.spin$
      .asObservable()
      .pipe(
        map(val => val ? 1 : -1 ),
        scan((acc, one) => (acc + one) >= 0 ? acc + one : 0, 0)
      )
      .subscribe(
        (res) => {
          if (res === 1) {
            this.showSpinner();
          }  else if ( res === 0 ) {
            if (this.spinnerTopRef.hasAttached() ) {
              this.stopSpinner();
            }
          }
        }
      );
  }

  private cdkSpinnerCreate() {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    });
  }

  public showSpinner() {
    console.log('attach');
    this.spinnerTopRef.attach(new ComponentPortal(MatSpinner));
  }

  public stopSpinner() {
    console.log('dispose');
    this.spinnerTopRef.detach() ;
  }

  /**
   * Executa acoes apos falha ao executar api.
   *
   * OBSERVACAO:
   * - metodo criado para testar retorno 401 token expirado na tela de pesquisar usuario.
   * - pesquisarusuario.component.ts
   *
   * @param vthis objeto da classe que executou api.
   * @param clsname nome da classe.
   * @param method nome do metodo.
   * @param xhr objeto de retorno da api.
   * @param status status de resposta da api.
   * @param err mensagem de erro da api.
   */
  public failApi(vthis, clsname, method, xhr, status, err) {
    vthis.analytics.trackEvent(clsname, method);
    let message = 'Falha na operação';
    if (xhr && xhr.responseText) {
      try {
        const response = JSON.parse(xhr.responseText);
        if (response && response.msgsErro && response.msgsErro.length > 0) {
          message = response.msgsErro[0];
        }
      } catch (ignore) {
      }
    }
    if (status === 401 || status === 403 || status === 500) {
      if (err) {
        message = err;
      }
    }
    vthis.log.error(message, err);
    if (status === 403 || status === 401) {
      vthis.alertService.showMessageConfirm('Erro', message, function(){
        vthis.appService.goAuth();
      });
    } else {
      vthis.alertService.showMessage('Erro', message);
    }
  }
}
