import { Injectable } from '@angular/core';
import { ErrorService } from '../../services/error/error.service';
import { LogService } from '../../services/log/log.service';
import { WebServiceService } from '../web-service/web-service.service';
import { Config } from '../../../environments/config';

const VERSION = '2.0.2';
const INIT_EVENT = 'INIT';
const INIT_AGENT = 'AGENT';
const NOTE = 'NOTE';
const ISP = 'ISP';
const SESSION_EVENT = 'SESSION';
const PAGE = 'page';
const ERROR = 'error';
const WARN = 'warn';
const TIMEOUT = 120000;

@Injectable()
export class AnalyticsService {
  evaluationRate: number = null;
  userFeedback: number = null;
  userAgent: string = null;
  token: string = null;
  provedor: string = null;
  session: number = null;
  category = '';
  enabled = true;

  constructor(private log: LogService, private errorService: ErrorService, private webservice: WebServiceService) {
    const vm = this;
    if (window && window.navigator && window.navigator.userAgent) {
      vm.userAgent = window.navigator.userAgent;
    }
  }

  version(): string {
    return VERSION;
  }

  private getEvaluationRest() {
    const vm = this;

    function success(res) {
      if (res.temErro) {
        console.error(res.msgsErro[0]);
      } else {
        const response = res;
        vm.evaluationRate = JSON.parse(response.message);
      }
    }
    function fail(xhr, message) {
      console.error('Ocorreu um erro ao obter o json Evaluation: ' + xhr.responseText);
    }

    // this.webservice.getAnalytics(Config.URL_CAIXA_ANALYTICS + 'ws/json/listarEvaluationUserFeedback?json=evaluationRate',null, success, fail)
  }


  private getUserFeedbackRest() {
    const vm = this;

    function success(res) {
      if (res.temErro) {
        console.error(res.msgsErro[0]);
      } else {
        const response = res;
        vm.userFeedback = JSON.parse(response.message);
      }
    }
    function fail(xhr, message) {
      console.error('Ocorreu um erro ao obter o json UserFeedback: ' + xhr.responseText);
    }

   // this.webservice.getAnalytics(Config.URL_CAIXA_ANALYTICS + 'ws/json/listarEvaluationUserFeedback?json=userFeedback',null, success, fail)
  }

  private getStamp(name) {
    let result = null;
    try {
      result = window.localStorage.getItem(name);
      if (result === '' || result === 'null') {
        result = null;
      }
    } catch (ignore) {
    }
    return result;
  }
  private setStamp(name, value) {
    try {
      window.localStorage.setItem(name, value);
    } catch (ignore) {
    }
  }
  montarJson(key, session, category, action, label, value, device) {
    const vm = this;

    const json = {
      chave: null,
      sessao: '',
      categoria: null,
      acao: null,
      etiqueta: null,
      valor: '',
      nomeSistemaOperacional: '',
      versaoSistemaOperacional: '',
      nomeNavegador: '',
      versaoNavegador: '',
      dispositivo: '',
      userAgent: null,
      token: ''
    };
    json.chave = key;

    if (session) {
      json.sessao = session + '';
    }
    json.categoria = category;
    json.acao = action;
    json.etiqueta = label;
    if (value) {
      json.valor = value;
    }

    if (device != null && device['soname']) {
      json.nomeSistemaOperacional = device['soname'];
    }

    if (device != null && device['sover']) {
      json.versaoSistemaOperacional = device['sover'];
    }

    if (device != null && device['navname']) {
      json.nomeNavegador = device['navname'];
    }

    if (device != null && device['navver']) {
      json.versaoNavegador = device['navver'];
    }

    if (device != null && device['devname']) {
      json.dispositivo = device['devname'];
    }

    json.userAgent = vm.userAgent;

    if (vm.token != null) {
      json.token = vm.token;
    }
    return json;
  }

  enviarEventoRest(json) {
    // const vm = this
    // let url = null
    // if (vm.token == null
    //   url = Config.URL_CAIXA_ANALYTICS + 'ws/evento/registrar'
    // } else
    //   url = Config.URL_CAIXA_ANALYTICS + 'ws/evento/registrarAutenticado'
    //
    // function success(res)
    //   if (res.temErro
    //     console.error(res.msgsErro[0] + ': ' + json)
    //
    //
    // function fail(xhr, message)
    //   console.error('Ocorreu um erro ao enviar o evento: ' + xhr.statusText)
    //
    // const hostname = window.location.hostname
    // if (hostname !== 'localhost'
    //  this.webservice.postAnalytics(url, json, null, success, fail)
    //
  }

  private registerEvent(category, action, label, value) {
    const vm = this;

    const json = vm.montarJson(Config.ANALYTICS_ID, vm.session, category, action, label, value, null);
    const jsontext = JSON.stringify(json);

    vm.enviarEventoRest(jsontext);
  }

  private configs(id, newcategory) {
    const vm = this;
    vm.getEvaluationRest();
    vm.getUserFeedbackRest();

    if (!id) {
      return;
    }
    if (newcategory) {
      vm.category = newcategory;
    }
    vm.session = vm.makeid();
    const lastsession = vm.getStamp(vm.category);

    vm.enabled = true;

    vm.trackEvent(INIT_EVENT, lastsession);

    if (vm.userAgent != null) {
      vm.trackEvent(INIT_AGENT, vm.userAgent);
    }

    vm.setStamp(vm.category, vm.session);

    vm.registrarProvedor();

  }

  private getISPService() {
    const vm = this;
    function success(data) {
      try {
        if (data) {
          vm.provedor = data.org;
          if (!vm.provedor) {
            vm.provedor = data.isp;
          }
          vm.trackEvent('ISP', vm.provedor);
        }
      } catch (ignore) {
      }
    }
    function fail(data) {
      console.error('Não foi possível obter as informações do provedor');
    }

    // this.webservice.getAnalytics('https://extreme-ip-lookup.com/json/', null, success, fail)
  }

  private registrarProvedor() {
    const vm = this;

    function success(res) {
      if (res.temErro) {
        vm.getISPService();
      } else {
        const response = res;
        vm.provedor = response.message;
        vm.trackEvent('ISP', vm.provedor);
      }
    }
    function fail(xhr, message) {
      console.error('Ocorreu um erro ao verificar se o provedor já está cadastrado: ' + xhr.responseText);
    }

    // this.webservice.getAnalytics(Config.URL_CAIXA_ANALYTICS + 'ws/json/checarProvedor', null, success, fail)
  }


  private makeid() {
    return this.getRandom(1, 2147483647);
  }

  private getRandom(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  init(id, newcategory) {
    this.configs(id, newcategory);
  }

  initAuth(user, pass, id, newcategory, devmode) {
    this.auth(user, pass, id, newcategory, devmode);
  }

  auth(user, pass, id, newcategory, devmode) {
    const vm = this;
    const json = { usuario: user, senha: pass };

    function success(res) {
      if (!res.temErro) {
        vm.token = res.user.token;
      } else if (res.temErro) {
        console.error(res.message + ': ' + JSON.stringify(json));
      }
    }
    function fail(xhr, message) {
      let cause = null;
      if (xhr != null && xhr.responseText) {
        cause = xhr.responseText;
      }
      if (cause == null && xhr != null && xhr.statusText) {
        cause = xhr.statusText;
      }
      console.error('Ocorreu um erro ao se autenticar ' + cause);
    }

    // this.webservice.postAnalytics(Config.URL_CAIXA_ANALYTICS + 'ws/autenticacao/autenticarInterface', JSON.stringify(json), null, success, fail)

    vm.configs(id, newcategory);
  }

  setEnable(value) {
    this.enabled = value;
  }
  setSession(id) {
    this.session = id;
  }
  setCategory(id) {
    this.category = id;
  }
  isEnabled() {
    return this.enabled;
  }
  trackWarn(label, value = null) {
    if (!this.enabled) {
      return;
    }
    if (!Config.ANALYTICS_ID) {
      return;
    }

    this.registerEvent(this.category, WARN, label, value);
  }
  trackException(label, value = null) {
    if (!this.enabled) {
      return;
    }
    if (!Config.ANALYTICS_ID) {
      return;
    }

    this.registerEvent(this.category, ERROR, label, value);
  }
  trackEvent(action, label = null, value = null) {
    if (!this.enabled) {
      return;
    }
    if (!Config.ANALYTICS_ID) {
      return;
    }

    this.registerEvent(this.category, action, label, value);
  }
  trackPage(page, value) {
    if (!this.enabled) {
      return;
    }
    if (!Config.ANALYTICS_ID) {
      return;
    }

    this.registerEvent(this.category, PAGE, page, value);
  }
  getEvaluationRate() {
    return this.evaluationRate;
  }
  getUserFeedback() {
    return this.userFeedback;
  }
  isFeedbackReady() {
    return this.userFeedback != null && this.evaluationRate != null;
  }
}
