import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { Config } from '../../../environments/config';
import { CurrentView } from '../../../environments/currentview';
import { AlertService } from '../../services/alert/alert.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ApplicationService } from '../../services/application/application.service';
import { GeolocationService } from '../../services/geolocation/geolocation.service';
import { LogService } from '../../services/log/log.service';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { NativeInterfaceService } from '../../services/native-interface/native-interface.service';

declare const $: any;

@Component({
  selector: 'app-cadastrararquivo',
  templateUrl: './cadastrararquivo.component.html',
  styleUrls: ['./cadastrararquivo.component.css']
})
export class CadastrarArquivoComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private geolocation: GeolocationService, private actroute: ActivatedRoute,
    private nativeInterface: NativeInterfaceService, private el: ElementRef, private translate: TranslateService) {
    //
  }
  currentview = CurrentView;
  //
  timer = null;
  tipo = 'A';
  sitLegislacao = 'V';
  perfil = null;
  fileToUpload: File = null;
  myForm: FormGroup;
  bUpload = false;
  iFile = '';
  isLegislacao = false;
  isCapacitacaoPdf = false;
  categorias = [];
  caminho = null;
  isPasta = false;

  ngOnInit() {
    const vm = this;

    this.obterCategorias();

    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      tipo: [ 'A', [ Validators.required ]],
      sitLegislacao: [],
      palavras: [ null, null ],
      arquivo: [ null, null ],
      pasta: [ null, null ],
      caminho: [ null, null ],
      nome: [ null, null ],
      descricao: [ null, null ],
      id: null,
      pagina: 'Cad',
      file: null,
      descricaoOld: null,
      palavrasOld: null,
      dataPublicacao: null,
      numeroLegislacao: null,
      dataRevogacao: null,
      dataPublicacaoOld: null,
      numeroLegislacaoOld: null,
      dataRevogacaoOld: null,
      reservada: false,
      autor: null,
      autorOld: null,
      categoria: null,
      categoriaOld: null
    });
    this.perfil = this.appService.getPerfilUsuario();

    /* Recuperar o caminho selecionado */
    const prms = this.actroute.snapshot.params;
    this.myForm.patchValue({
      descricaoOld: prms.descricao,
      palavrasOld: prms.palavraChave,
      dataPublicacaoOld: prms.dataPublicacao,
      numeroLegislacaoOld: prms.numeroLegislacao,
      dataRevogacaoOld: prms.dataRevogacao,
      caminho: prms.caminho,
      palavras: prms.palavraChave,
      descricao: prms.descricao == null || prms.descricao === 'null' ? '' : prms.descricao,
      pagina: prms.pagina,
      nome: prms.nome,
      dataPublicacao: prms.dataPublicacao,
      numeroLegislacao: prms.numeroLegislacao,
      dataRevogacao: prms.dataRevogacao,
      id: prms.id,
      reservada: prms.reservada,
      autor: prms.autor == null || prms.autor === 'null' ? '' : prms.autor,
      autorOld: prms.autor,
      categoria: prms.categoria,
      categoriaOld: prms.categoria
    });

    vm.caminho = vm.myForm.get('caminho').value;
    vm.isPasta = prms.tipo !== undefined && prms.tipo === 'DIRETORIO';

    if (this.myForm.get('dataRevogacao').value === 'null') {
      this.myForm.get('sitLegislacao').setValue('V');
    } else {
      this.myForm.get('sitLegislacao').setValue('R');
    }

    if (this.myForm.get('numeroLegislacao').value === 'null') {
      this.myForm.get('numeroLegislacao').setValue(null);
    }

    this.isLegislacao = this.myForm.get('caminho').value.indexOf(Config.PASTA_LEGISLACAO) === 0;
    this.isCapacitacaoPdf = this.myForm.get('caminho').value.indexOf(Config.PASTA_CAPACITACAO_PDF) === 0;

    if (this.isLegislacao) {
      this.myForm.get('dataPublicacao').setValue(moment(vm.myForm.get('dataPublicacao').value, 'YYYY-MM-DD').format('DD/MM/YYYY'));
      this.myForm.get('dataPublicacaoOld').setValue(moment(vm.myForm.get('dataPublicacaoOld').value, 'YYYY-MM-DD').format('DD/MM/YYYY'));
      this.myForm.get('dataRevogacao').setValue(moment(vm.myForm.get('dataRevogacao').value, 'YYYY-MM-DD').format('DD/MM/YYYY'));
      this.myForm.get('dataRevogacaoOld').setValue(moment(vm.myForm.get('dataRevogacaoOld').value, 'YYYY-MM-DD').format('DD/MM/YYYY'));
    }
    if (this.isCapacitacaoPdf) {
      this.myForm.get('dataPublicacao').setValue(moment(vm.myForm.get('dataPublicacao').value, 'YYYY-MM-DD').format('DD/MM/YYYY'));
      this.myForm.get('dataPublicacaoOld').setValue(moment(vm.myForm.get('dataPublicacaoOld').value, 'YYYY-MM-DD').format('DD/MM/YYYY'));
      this.myForm.get('dataRevogacao').setValue(moment(vm.myForm.get('dataRevogacao').value, 'YYYY-MM-DD').format('DD/MM/YYYY'));
      this.myForm.get('dataRevogacaoOld').setValue(moment(vm.myForm.get('dataRevogacaoOld').value, 'YYYY-MM-DD').format('DD/MM/YYYY'));
    }

    this.getInfoFile();

    $(document).ready(function() {
      $('body').on('input propertychange', '.cadastrarArquivo', function (e) {
          e.preventDefault();
          const inp = $(this).val();
          const ret = inp.replace(/[^\w\-áàãâéèêíìóòõôúùçÁÀÃÂÉÈÊÍÌÓÒÕÔÚÙÇ.;, ]/gi, '');
          if ($('#infoCadastroArquivo').css('opacity') === 0) {
            $('#infoCadastroArquivo').fadeTo('fast', inp !== ret ? 1 : 0);
            $('#divinfoCadastroArquivo').css('display', 'block');
          } else if (inp.length === 0) {
            $('#infoCadastroArquivo').fadeTo('fast', 0);
            $('#divinfoCadastroArquivo').css('display', 'none');
          }
          $(this).val(ret);
        });
    });
  }

  getInfoFile() {
    this.iFile = '';
    this.translate.get('label.input.file.upload').subscribe( (text: string) => { this.iFile = text; });
  }

  limpar() {
    this.fileToUpload = null;
    this.getInfoFile();
    this.myForm.patchValue({
      tipo: 'A',
      sitLegislacao: 'V',
      palavras: null,
      arquivo: null,
      pasta: null,
      descricao: null,
      file: null,
      nome: null,
      id: null,
      dataPublicacao: null,
      numeroLegislacao: null,
      dataRevogacao: null,
      reservada: false,
      autor: null,
      categoria: null
    });
  }

  trocarAcaoTipo(v) {
    $('#infoCadastroArquivo').fadeTo('slow', 0);
    this.myForm.get('tipo').setValue(v);
    this.myForm.get('file').setValue('');
    this.myForm.get('pasta').setValue('');
    this.myForm.get('dataRevogacao').setValue('');
    this.myForm.get('dataPublicacao').setValue('');
    this.myForm.get('numeroLegislacao').setValue('');
    this.myForm.get('palavras').setValue('');
    this.myForm.get('descricao').setValue('');
  }

  enviar() {
    if (this.myForm.get('tipo').value === 'A') {
      this.enviarArquivo();
    } else {
      this.salvarDiretorio();
    }
  }

  salvarDiretorio() {
    const vm = this;
    // this.analytics.trackEvent('CadastrarArquivoController', 'salvarDiretorio()')
    const descPasta = vm.myForm.get('pasta').value;
    if (descPasta === null || descPasta === undefined || descPasta === '' || descPasta.trim() === '') {
      vm.alertService.showMessage('Atenção', 'Favor informar o nome da Pasta.');
      return;
    }
    let params = new HttpParams().append('caminho', vm.appService.fixedEncodeUri(vm.myForm.get('caminho').value));
    params = params.append('pasta', vm.appService.fixedEncodeUri(vm.myForm.get('pasta').value));
    if (this.perfil === 'AD' ) {
      const reserv = vm.appService.getInputCheckboxValue('reservada');
      params = params.append('reservada', String(reserv));
      if (reserv) {
        vm.alertService.showConfirm('Atenção', 'Confirma a Inclusão desta Pasta como Reservada do Sistema?', confirm, deny);
      } else {
        salvarPasta();
      }
    } else {
      params = params.append('reservada', 'N');
      salvarPasta();
    }

    function confirm() {
      vm.log.info('Inclusão de Pasta Reservada confirmada. Executando inclusao no backend.');
      salvarPasta();
    }

    function deny() {
      vm.log.info('Inclusão e Pasta Reservada cancelada pelo usuário.');
    }

    function salvarPasta() {
      vm.webservice.read('ws/arquivos/salvarDiretorio', params, success, fail);

      function success(res) {
        // vm.analytics.trackEvent('CadastrarArquivoController', 'salvarDiretorio():success')
        if (res.temErro) {
          const msg = 'Ocorreu um erro ao gravar pasta - ' + res.msgsErro[0];
          vm.log.error(msg);
          vm.alertService.showMessage('Erro', msg);
        } else {
          vm.myForm.patchValue({
            tipo: 'A',
            caminho: vm.myForm.get('caminho').value + '/' + vm.myForm.get('pasta').value,
            pasta: null
          });
          vm.caminho = vm.myForm.get('caminho').value;
          vm.alertService.showMessage('Atenção', 'Pasta incluída com sucesso.');
        }
      }

      function fail(xhr, status, err) {
        let message = 'Falha ao incluir Pasta.';
        // Verificar se status=403 - Pasta ja existe ou status=400 bad request
        if (status === 403 || status === 500) {
          if (err) {
            message = err;
          } else {
            message = xhr;
          }
        } else if (xhr && xhr.responseText) {
          try {
            const response = JSON.parse(xhr.responseText);
            if (response && response.msgsErro && response.msgsErro.length > 0) {
              message = response.msgsErro[0];
            }
          } catch (ignore) {
          }
        }
        vm.log.error(message);
        vm.alertService.showMessage('Erro', message);
      }
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.iFile = files.item(0).name.toString();
    this.bUpload = false;
  }

  uClick() {
    $('#file').click();
  }

  enviarArquivo() {
    const vm = this;
    console.log( new Date(), 'Enviar Arquivo()');
    const oper = vm.myForm.get('pagina').value;

    // se inclusao, validar arquivo.
    if (oper !== 'Alt' && vm.fileToUpload == null) {
      vm.alertService.showMessage('Atenção', 'Favor selecionar um Arquivo.');
      return;
    }

    // se legislacao, criticar se data publicacao, data revogação e numero foram informados
    const dp = vm.myForm.get('dataPublicacao').value;
    const nl = vm.myForm.get('numeroLegislacao').value;
    let dr = vm.myForm.get('dataRevogacao').value;
    const sl = vm.myForm.get('sitLegislacao').value;
    let bdr = false;
    if (vm.isLegislacao) {
      bdr = true;
      if (sl === 'R') {
        if (dr == null || dr === undefined || dr === 'Data inválida') {
          vm.alertService.showMessage('Atenção',  'Campo \'Data da Revogação\' deve ser informado.');
          return;
        }
      } else {
        dr = null ;
      }
      if (dp == null  || dp === undefined || dp === 'Data inválida') {
        vm.alertService.showMessage('Atenção',  'Campo \'Data da Publicação\' deve ser informado.');
        return;
      }
      if (nl == null || nl === '' || nl === undefined) {
        vm.alertService.showMessage('Atenção', 'Campo \'Número da Legislação\' deve ser informado.');
        return;
      }
      if (!(/^\d+$/.test(nl))) {
        vm.alertService.showMessage('Atenção', 'Campo \'Número da Publicação\' deve ser numérico.');
        return;
      }
    }

    const categ = vm.myForm.get('categoria').value;
    const autor = vm.myForm.get('autor').value;
    if (vm.isCapacitacaoPdf) {
      if (categ === undefined || categ == null || categ.trim() === '') {
        vm.alertService.showMessage('Atenção', 'Campo \'Categoria\' deve ser informado.');
        return;
      }
      if (autor === undefined || autor == null || autor.trim() === '') {
        vm.alertService.showMessage('Atenção', 'Campo \'Nome do Autor\' deve ser informado.');
        return;
      }
    }

    // criticar se descricao e palavras foram informados
    const descr = vm.myForm.get('descricao').value;
    const palavr = vm.myForm.get('palavras').value;
    if ((descr === undefined || descr === null || descr.trim() === '')
        || (palavr === undefined || palavr === null || palavr.trim() === '')) {
      vm.alertService.showMessage('Atenção', 'Favor informar Todos os Dados.');
      return;
    }

    // se alteracao, criticar valores alterados.
    // Verificar se for pasta legislacao para criticar tambem os campos data publicacao, data revogacao e numero.
    if (oper === 'Alt') {
      let isDadosNaoAlterados = (vm.myForm.get('descricaoOld').value === vm.myForm.get('descricao').value
                                && vm.myForm.get('palavrasOld').value === vm.myForm.get('palavras').value);
      if ( isDadosNaoAlterados && vm.isLegislacao) {
        const dpold = vm.myForm.get('dataPublicacaoOld').value;
        const nlold = vm.myForm.get('numeroLegislacaoOld').value;
        isDadosNaoAlterados = (dpold === dp && nlold === nl && !bdr);
      }
      if ( isDadosNaoAlterados && vm.isCapacitacaoPdf) {
        const catold = vm.myForm.get('categoriaOld').value;
        const autold = vm.myForm.get('autorOld').value;
        isDadosNaoAlterados = (catold === categ && autold === autor);
      }
      if (isDadosNaoAlterados) {
        vm.alertService.showMessage('Atenção', 'Dados não Alterados.');
        return;
      }
    }

    const caminhoNovo = (vm.myForm.get('caminho').value).split('/', 4).join('/');
    if (oper === 'Alt') {
      let params = new HttpParams().append('nome', vm.myForm.get('nome').value);
      if (caminhoNovo === Config.PASTA_PUBLICO) {
        params = params.append('perfil', 'CO');
      } else if (caminhoNovo === Config.PASTA_MDR || caminhoNovo === Config.PASTA_MDR_NOVA) {
        params = params.append('perfil', 'MI');
      } else {
        params = params.append('perfil', 'GE');
      }
      params = params.append('descricao', vm.appService.fixedEncodeUri(vm.myForm.get('descricao').value));
      params = params.append('palavra', vm.appService.fixedEncodeUri(vm.myForm.get('palavras').value));
      params = params.append('caminho', vm.myForm.get('caminho').value);
      let datapub = null;
      let numleg  = null;
      let datarev = null;
      if (vm.isLegislacao) {
        // datapub = moment(dp).format('DD/MM/YYYY')
        datapub = dp;
        numleg  = nl;
        datarev = dr;
        if  (sl === 'V') {
          datarev = null;
        }
      }
      params = params.append('dataPublicacao', datapub);
      params = params.append('numeroLegislacao', numleg);
      params = params.append('dataRevogacao', datarev);
      params = params.append('categoria', categ);
      params = params.append('autor', autor);
      this.webservice.read('ws/arquivos/alterar', params, successEnvio, falhaEnvio);
    } else {
      const formData: FormData = new FormData();
      if (caminhoNovo === Config.PASTA_PUBLICO) {
        formData.append('perfil', 'CO');
      } else if (caminhoNovo === Config.PASTA_MDR || caminhoNovo === Config.PASTA_MDR_NOVA) {
        formData.append('perfil', 'MI');
      } else {
        formData.append('perfil', 'GE');
      }
      formData.append('arquivo', this.fileToUpload);
      formData.append('nomeArquivo', encodeURIComponent(this.fileToUpload.name));
      formData.append('palavras', encodeURIComponent(vm.myForm.get('palavras').value));
      formData.append('descricao', encodeURIComponent(vm.myForm.get('descricao').value));
      formData.append('caminho', encodeURIComponent(vm.myForm.get('caminho').value));
      formData.append('dataPublicacao', vm.myForm.get('dataPublicacao').value);
      formData.append('numeroLegislacao', vm.myForm.get('numeroLegislacao').value);
      formData.append('dataRevogacao', vm.myForm.get('dataRevogacao').value);
      formData.append('categoria', categ);
      formData.append('autor', encodeURIComponent(autor));

      this.bUpload = true;
      setTimeout(function() { $('#pBar').css('width', $('#lblFile').outerWidth()); }, 10);

      console.log( new Date(), 'enviando arquivo', this.fileToUpload.name);
      vm.webservice.create('ws/arquivos/upload', formData, successEnvio, falhaEnvio);
    }

    function successEnvio(res) {
      if (vm.myForm.get('pagina').value === 'Alt') {
        vm.alertService.showMessage('Atenção', 'Arquivo Alterado com sucesso.');
        vm.openViewDelayed('arquivos', 2, '', vm.caminho.replace('/' + vm.myForm.get('nome').value, ''));
      } else {
        console.log( new Date(), 'arquivo enviado.');
        let mens = 'Arquivo incluído com sucesso.';
        if (res != null && res.msgsErro != null && res.msgsErro[0] != null && res.msgsErro[0].trim() !== '') {
          mens = res.msgsErro[0];
        }
        vm.bUpload = false;
        vm.alertService.showMessage('Atenção', mens);
        vm.limpar();
      }
    }

    function falhaEnvio(xhr, status, err) {
      let message = 'Erro ao incluir arquivo.';
      if ( err != null && err.trim() !== '') {
        message = err;
      }
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msg && response.msg.length > 0) {
            message = response.msg[0];
          }
          if (response != null && response.msgsErro != null && response.msgsErro[0] != null && response.msgsErro[0].trim() !== '') {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
      vm.bUpload = false;
      vm.alertService.showMessage('Erro', message);
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  openViewDelayed(id, delay, event= null, message= null) {
    const vm = this;
    vm.log.info('CadastrarArquivoComponent.openViewDelayed(' + id + ')');

    const delayms = delay * 1000;
    if (vm.timer != null) {
      clearTimeout(vm.timer);
      vm.timer = null;
    }

    vm.timer = setTimeout(() => {
      let messageaux = message;
      if (message.split('/')[message.split('/').length - 1].indexOf('.pdf') > 0
        || message.split('/')[message.split('/').length - 1].indexOf('.zip') > 0
        || message.split('/')[message.split('/').length - 1].indexOf('.jpg') > 0
        || message.split('/')[message.split('/').length - 1].indexOf('.png') > 0
        || message.split('/')[message.split('/').length - 1].indexOf('.gif') > 0
        || message.split('/')[message.split('/').length - 1].indexOf('.PDF') > 0
        || message.split('/')[message.split('/').length - 1].indexOf('.ZIP') > 0
        || message.split('/')[message.split('/').length - 1].indexOf('.JPG') > 0
        || message.split('/')[message.split('/').length - 1].indexOf('.PNG') > 0
        || message.split('/')[message.split('/').length - 1].indexOf('.GIF') > 0) {
        messageaux = messageaux.split('/',  (messageaux.split('/').length - 1) ).join('/');
      }
      const json = {'pasta': messageaux};
      vm.appService.openPage(id, json);
    }, delayms);
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.get('arquivo').setValue(file);
      this.myForm.get('nome').setValue(file.name);
    }
  }

  obterCategorias() {
    const vm = this;
    vm.webservice.read('ws/categoriaarquivos', null, success, fail);

    function success(res) {
      vm.categorias = [];
      if (res.data && res.data.length > 0) {
        vm.categorias.push({
          'id': '',
          'descricao': ''
        });
        res.data.forEach((e) => {
          vm.categorias.push({
            'id': e.id + '',
            'descricao': e.descricao
          });
        });
      }
    }

    function fail(xhr, status, err) {
      let message = 'Falha ao obter Categorias de arquivo.';
      if (status === 403 || status === 500) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
    }
  }

  novaCategoria() {
    const vm = this;
    vm.alertService.showInputRequired('Nova Categoria', 'Informe a Categoria de Arquivo:', '', confirm, deny );

    function deny(xhr, status, err) {
      vm.log.info('fechou a tela de inclusao categoria.');
    }

    function confirm(res) {
      const categ = res;
      if (categ == null || categ.trim() === '') {
        vm.alertService.showMessage('Atenção', 'Favor informar a Categoria.');
        return;
      }
      const categoria = {'id': null, 'descricao': res};
      vm.webservice.create('ws/categoriaarquivos', categoria, successCateg, failCateg);

      function successCateg() {
        vm.obterCategorias();
        vm.alertService.showMessage('Atenção', 'Categoria incluída com sucesso.');
      }

      function failCateg(xhr, status, err) {
        vm.log.error('erro ao incluir categoria.');
        let message = 'Falha ao incluir categoria.';
        if (status === 403 || status === 400) {
          if (err) {
            message = err;
          } else if (xhr) {
            message = xhr;
          }
        } else if (xhr && xhr.responseText) {
          try {
            const response = JSON.parse(xhr.responseText);
            if (response && response.msgsErro && response.msgsErro.length > 0) {
              message = response.msgsErro[0];
            }
          } catch (ignore) {
          }
        }
        vm.log.error(message);
        vm.alertService.showMessage('Erro', message);
      }
    }
  }

  isPastaPdf() {
    return this.caminho === Config.PASTA_CAPACITACAO_PDF;
  }
}
