import { Component, OnInit } from '@angular/core';
import { CurrentView } from '../../../environments/currentview';
import { Project } from '../../../environments/project';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.css']
})
export class SiteFooterComponent implements OnInit {

  currentview = CurrentView;
  project = Project;

  compiler(value) {
    return value;
  }

  constructor() {
    //
  }

  ngOnInit() {
    document.getElementsByClassName('overlay')[0].addEventListener('click', overlayClose);
    function overlayClose() {
      document.getElementsByClassName('config-intranet')[0].setAttribute('style', 'display: none');
      document.getElementsByClassName('pesquisa-intranet')[0].setAttribute('style', 'display: none');
      document.getElementsByClassName('overlay')[0].setAttribute('style', 'display: none');
      document.getElementsByClassName('calendario-intranet')[0].setAttribute('style', 'display: none');
    }
  }

  galleryClose() {
    document.getElementsByClassName('ov-item')[0].remove();
    document.getElementsByClassName('overlay-galeria')[0].setAttribute('style', 'display:none;');
  }
}
