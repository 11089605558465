import { Routes, RouterModule } from '@angular/router';
import { FaleconoscoComponent } from './components/faleconosco/faleconosco.component';
import { AuthGuard } from './components/auth/auth.guard';
import { RecuperacaoCreditoComponent } from './components/informacoesconsolidadas/recuperarcredito/recuperacaocredito/recuperacaocredito.component';
import { SaquesMensaisComponent } from './components/informacoesconsolidadas/pagar/saquesmensais/saquesmensais.component';
import { CadastroContasVinculadasComponent } from './components/informacoesconsolidadas/cadastro/cadastrocontasvinculadas/cadastrocontasvinculadas.component';
import { QtdeContasComRecolhimentoComponent } from './components/informacoesconsolidadas/arrecadar/qtdecontascomrecolhimento/qtdecontascomrecolhimento.component';
import { ArrecadacaoPrincipaisSetoresComponent } from './components/informacoesconsolidadas/arrecadar/arrecadacaoprincipaissetores/arrecadacaoprincipaissetores.component';
import { ArrecadacaoLiquidaMensalComponent } from './components/informacoesconsolidadas/arrecadar/arrecadacaoliquidamensal/arrecadacaoliquidamensal.component';
import { ArrecadacaoLiquidaAnualComponent } from './components/informacoesconsolidadas/arrecadar/arrecadacaoliquidaanual/arrecadacaoliquidaanual.component';
import { ArrecadacaoContribuicaoSocialComponent } from './components/informacoesconsolidadas/arrecadar/arrecadacaocontribuicaosocial/arrecadacaocontribuicaosocial.component';
import { AuditoriaComponent } from './components/auditoria/auditoria.component';
import { ArquivosComponent } from './components/arquivos/arquivos.component';
import { PassivoFgtsComponent } from './components/relatorios/passivofgts/passivofgts.component';
import { ExecucaoorcamentariaComponent } from './components/relatorios/execucaoorcamentaria/execucaoorcamentaria.component';
import { DesembolsoComponent } from './components/relatorios/desembolso/desembolso.component';
import { ContratacoesComponent } from './components/relatorios/contratacoes/contratacoes.component';
import { DescontosComponent } from './components/relatorios/descontos/descontos.component';
import { AtivoFgtsComponent } from './components/relatorios/ativofgts/ativofgts.component';
import { ArrecadacaoLiquidaComponent } from './components/relatorios/arrecadacaoliquida/arrecadacaoliquida.component';
import { ArrecadacaoConsolidadaComponent } from './components/relatorios/arrecadacaoconsolidada/arrecadacaoconsolidada.component';
import { InstitucionalAtribuicoesComponent } from './components/institucional/atribuicoes/institucional-atribuicoes.component';
import { InstitucionalEstruturaComponent } from './components/institucional/estrutura/institucional-estrutura.component';
import { SplashComponent } from './components/splash/splash.component';
import { PrincipalComponent } from './components/principal/principal.component';
import { PesquisarusuarioComponent } from './components/usuario/pesquisarusuario.component';
import { CadastrarusuarioComponent } from './components/usuario/cadastrarusuario.component';
import { CadastrarArquivoComponent } from './components/arquivos/cadastrararquivo.component';
import { InstitucionalHistoricoComponent } from './components/institucional/historico/institucional-historico.component';
import { TutorialComponent } from './components/relatorios/tutorial/tutorial.component';
import { SaquesConsolidadosComponent } from './components/relatorios/saquesconsolidados/saquesconsolidados.component';
import { RecolhimentoEmpregadoDomesticoComponent } from './components/informacoesconsolidadas/arrecadar/recolhimentoempregadodomestico/recolhimentoempregadodomestico.component';
import { DistribuicaoSaldosContasAtivasComponent } from './components/informacoesconsolidadas/cadastro/distribuicaosaldoscontasativas/distribuicaosaldoscontasativas.component';
import { DistribuicaoSaldosContasInativasComponent } from './components/informacoesconsolidadas/cadastro/distribuicaosaldoscontasinativas/distribuicaosaldoscontasinativas.component';
import { SaquesConectividadeSocialComponent } from './components/informacoesconsolidadas/pagar/saquesconectividadesocial/saquesconectividadesocial.component';
import { SaquesPorDuracaoVinculoComponent } from './components/informacoesconsolidadas/pagar/saquesporduracaovinculo/saquesporduracaovinculo.component';
import { SaquesPorModalidadeComponent } from './components/informacoesconsolidadas/pagar/saquespormodalidade/saquespormodalidade.component';
import { AlterarSenhaComponent } from './components/alterarsenha/alterarsenha.component';
import { AlterarSenhaDesafioComponent } from './components/alterarsenhadesafio/alterarsenhadesafio.component';
import { MensagemGestorComponent } from './components/mensagemGestor/mensagemGestor.component';
import { MensagemGestorCadastroComponent } from './components/mensagemGestor/mensagemGestorCadastro.component';
import { PalavraChaveComponent } from './components/palavrachave/palavrachave.component';
import { PalavraChaveCadastroComponent } from './components/palavrachave/palavrachavecadastro.component';
import { PalavraChaveFuncionalidadeComponent } from './components/palavrachave/palavrachavefuncionalidade.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { InstitucionalIndicadorEstrategicoComponent } from './components/institucional/indicadorestrategico/institucional-indicadorestrategico.component';

const routes: Routes = [

  { path: '', redirectTo: 'splash', pathMatch: 'full' },
  //
  { path: '',
    component: SiteLayoutComponent,
    children: [
      { path: 'splash', component: SplashComponent },
      { path: 'login', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule) },
      { path: 'principal', component: PrincipalComponent, data: { label: 'Principal' }, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'pesquisarUsuario', component: PesquisarusuarioComponent, data: { label: 'Manutencao de Usuario' }, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'cadastrarUsuario', component: CadastrarusuarioComponent, data: { label: 'Cadastrar Usuario' }, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'fgtsHistorico', component: InstitucionalHistoricoComponent, data: { label: 'Histórico'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'estrutura', component: InstitucionalEstruturaComponent, data: { label: 'Estrutura'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'atribuicoes', component: InstitucionalAtribuicoesComponent, data: { label: 'Atribuições'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'arquivos', component: ArquivosComponent, data: { label: 'Arquivos'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'cadastrarArquivo', component: CadastrarArquivoComponent, data: { label: 'Cadastrar Pasta/Arquivo' }, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'tutorial', component: TutorialComponent, data: { label: 'Tutorial'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'ativofgts', component: AtivoFgtsComponent, data: { label: 'Ativo do FGTS'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'passivofgts', component: PassivoFgtsComponent, data: { label: 'Passivo do FGTS'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'contratacoes', component: ContratacoesComponent, data: { label: 'Contratações'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'descontos', component: DescontosComponent, data: { label: 'Descontos'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'desembolso', component: DesembolsoComponent, data: { label: 'Desembolso'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'execucaoorcamentaria', component: ExecucaoorcamentariaComponent, data: { label: 'Execução Orçamentária'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'arrecadacaoliquida', component: ArrecadacaoLiquidaComponent, data: { label: 'Arrecadação Liquida'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'arrecadacaoconsolidada', component: ArrecadacaoConsolidadaComponent, data: { label: 'Arrecadação Consolidada'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'saquesconsolidados', component: SaquesConsolidadosComponent, data: { label: 'Saques Consolidados'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'arrecadacaocontribuicaosocial', component: ArrecadacaoContribuicaoSocialComponent, data: { label: 'Arrecadação Contribuição Social'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'auditoria', component: AuditoriaComponent, data: { label: 'Trilha de Auditoria'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'arrecadacaoliquidaanual', component: ArrecadacaoLiquidaAnualComponent, data: { label: 'Arrecadação Liquida Anual'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'arrecadacaoliquidamensal', component: ArrecadacaoLiquidaMensalComponent, data: { label: 'Arrecadação Liquida Mensal'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'qtdecontascomrecolhimento', component: QtdeContasComRecolhimentoComponent, data: { label: 'Quantidade de Contas e Empresas com Recolhimento'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'arrecadacaoprincipaissetores', component: ArrecadacaoPrincipaisSetoresComponent, data: { label: 'Arrecadação Principais Setores'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'recolhimentoempregadodomestico', component: RecolhimentoEmpregadoDomesticoComponent, data: { label: 'Recolhimento Empregado Domestico'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'cadastrocontasvinculadas', component: CadastroContasVinculadasComponent, data: { label: 'Cadastro Contas Vinculadas'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'distribuicaosaldoscontasativas', component: DistribuicaoSaldosContasAtivasComponent, data: { label: 'Distribuição de Saldos - Contas Ativas por Faixa de Salário Mínimo'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'distribuicaosaldoscontasinativas', component: DistribuicaoSaldosContasInativasComponent, data: { label: 'Distribuição de Saldos - Contas inativas por Faixa de Salário Mínimo'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'saquesconectividadesocial', component: SaquesConectividadeSocialComponent, data: { label: 'Saques Conectividade Social'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'saquesmensais', component: SaquesMensaisComponent, data: { label: 'Saques Mensais'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'saquesporduracaovinculo', component: SaquesPorDuracaoVinculoComponent, data: { label: 'Saques por Duração Vinculo'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'saquespormodalidade', component: SaquesPorModalidadeComponent, data: { label: 'Saques por Modalidade'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'recuperacaocredito', component: RecuperacaoCreditoComponent, data: { label: 'Recuperação de Crédito'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'faleconosco', component: FaleconoscoComponent, data: { label: 'Fale conosco'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'mensagemgestor', component: MensagemGestorComponent, data: { label: 'Mensagem Gestor'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'mensagemgestorcadastro', component: MensagemGestorCadastroComponent, data: { label: 'Mensagem Gestor Cadastro'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'palavrachave', component: PalavraChaveComponent, data: { label: 'Palavra Chave'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'palavrachavecadastro', component: PalavraChaveCadastroComponent, data: { label: 'Palavra Chave Cadastro'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'palavrachavefuncionalidade', component: PalavraChaveFuncionalidadeComponent, data: { label: 'Palavra Chave Funcionalidade'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      { path: 'alterarsenhadesafio', component: AlterarSenhaDesafioComponent, data: { label: 'Alteração de senha'} },
      { path: 'alterarsenha', component: AlterarSenhaComponent, data: { label: 'Alteração de senha'} },
      { path: 'fgtsIndicadorEstrategico', component: InstitucionalIndicadorEstrategicoComponent, data: { label: 'Indicador Estratégico'}, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      // ------------------------------------------------------------------------------------------
      // Trilha de Capacitacao
      // ------------------------------------------------------------------------------------------
      { path: 'trilhacapacitacao',
        loadChildren: () => import('./components/trilhacapacitacao/trilhacapacitacao.module').then(m => m.TrilhacapacitacaoModule),
        data: { label: 'Trilha de Capacitação' }, canActivate: [AuthGuard], canLoad: [AuthGuard] },
      // ------------------------------------------------------------------------------------------
      // Termo de Confidencialidade
      // ------------------------------------------------------------------------------------------
      { path: 'termoconfidencialidade',
        loadChildren: () => import('./components/termoconfidencialidade/termoconfidencialidade.module').then(m => m.TermoconfidencialidadeModule),
        canActivate: [AuthGuard], canLoad: [AuthGuard] },
      // ------------------------------------------------------------------------------------------
      // Observação Funcionalidade
      // ------------------------------------------------------------------------------------------
      { path: 'pesquisarObservacaoFuncionalidade',
        loadChildren: () => import('./components/observacaofuncionalidade/observacaofuncionalidade.module').then(m => m.ObservacaofuncionalidadeModule),
        canActivate: [AuthGuard], canLoad: [AuthGuard] },
      // ------------------------------------------------------------------------------------------
      // Pesquisar Perfil
      // ------------------------------------------------------------------------------------------
      { path: 'perfilpesquisar',
      loadChildren: () => import('./components/perfil/perfilpesquisar/perfilpesquisar.module').then(m => m.PerfilpesquisarModule),
      canActivate: [AuthGuard], canLoad: [AuthGuard] },
      // ------------------------------------------------------------------------------------------
      // Cadastro de Perfil
      // ------------------------------------------------------------------------------------------
      { path: 'perfilcadastro',
        loadChildren: () => import('./components/perfil/perfilcadastro/perfilcadastro.module').then(m => m.PerfilcadastroModule),
        canActivate: [AuthGuard], canLoad: [AuthGuard] },
      // ------------------------------------------------------------------------------------------
      // Perfil X Funcionalidade
      // ------------------------------------------------------------------------------------------
      { path: 'perfilfuncionalidade',
        loadChildren: () => import('./components/perfil/perfilfuncionalidade/perfilfuncionalidade.module').then(m => m.PerfilfuncionalidadeModule),
        canActivate: [AuthGuard], canLoad: [AuthGuard] },
      {  path: 'perfilpasta',
        loadChildren: () => import('./components/perfil/perfilpasta/perfilpasta.module').then(m => m.PerfilpastaModule),
        canActivate: [AuthGuard], canLoad: [AuthGuard] }
    ] },
  // ------------------------------------------------------------------------------------------
  // Relatorios Publicos
  // ------------------------------------------------------------------------------------------
  { path: '', loadChildren: () => import('./publico.module').then(m => m.PublicoModule) },
  //
  { path: '**', component: SplashComponent }
];

export const routing = RouterModule.forRoot(routes, { useHash: true });
