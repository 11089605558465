import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../../../pipe.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerfilpesquisarRoutingModule } from './perfilpesquisar-routing.module';
import { PerfilpesquisarComponent } from './perfilpesquisar.component';

@NgModule({
  declarations: [PerfilpesquisarComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule, TranslateModule, NgxPaginationModule, PipeModule,
    PerfilpesquisarRoutingModule
  ]
})
export class PerfilpesquisarModule { }
