import { Component, OnInit } from '@angular/core';
import { LogService } from '../../services/log/log.service';
import { Config } from '../../../environments/config';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CurrentView } from '../../../environments/currentview';
import { ApplicationService } from '../../services/application/application.service';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { GeolocationService } from '../../services/geolocation/geolocation.service';
import { AlertService } from '../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NativeInterfaceService } from './../../services/native-interface/native-interface.service';
declare const $: any;

@Component({
  selector: 'app-palavrachavefuncionalidade',
  templateUrl: './palavrachavefuncionalidade.component.html',
  styleUrls: ['./palavrachavefuncionalidade.component.css'],

})
export class PalavraChaveFuncionalidadeComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService, private router: Router,
    private alertService: AlertService, private geolocation: GeolocationService, private nativeInterface: NativeInterfaceService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;
  myFormOld: FormGroup;

  //

  timer = null;

  tblDataPCAssoc = [];
  filtrotblPCAssoc = null;
  pageOptionsPCAssoc = [10, 20, 50, 100];
  itemsOnPagetblPCAssoc = 10;
  numberPagetblPCAssoc = [];
  numberPagetblPCAssocSelected = 0;

  tblDataPCList = [];
  filtrotblPCList = null;
  pageOptionsPCList = [10, 20, 50, 100];
  itemsOnPagetblPCList = 10;
  numberPagetblPCList = [];
  numberPagetblPCListSelected = 0;

  funcionalidadeContent = [];
  funcionalidadeSelecionada = null;
  funcionalidadeSelecionadaID = 'null';

  native: NativeInterfaceService;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';

  palavraChaveAcessoInclusao = false;

  acessoInclusao = false;
  acessoAlteracao = false;
  acessoExclusao = false;

  ngOnInit() {
    this.log.info('PalavraChaveFuncionalidadeComponent.init()');
    this.analytics.trackEvent('controller', 'PalavraChaveFuncionalidadeController');

    this.currentview.locked = false;
    this.currentview.menu = true;

    this.palavraChaveAcessoInclusao = this.appService.temAutorizacao('palavrachave', 'I');

    this.acessoInclusao = this.appService.temAutorizacao('palavrachavefuncionalidade', 'I');
    this.acessoAlteracao = this.appService.temAutorizacao('palavrachavefuncionalidade', 'A');
    this.acessoExclusao = this.appService.temAutorizacao('palavrachavefuncionalidade', 'E');

    this.myForm = this.formBuilder.group({
      funcionalidade: [null],
      funcionalidadeResumo: null
    });
    this.myFormOld = this.formBuilder.group({
      funcionalidade: [null],
      funcionalidadeResumo: null
    });
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.initFavorite();
    this.listar(true);
  }

  changePagetblPCAssoc(pageSelectedtblPCAssoc) {
    if (pageSelectedtblPCAssoc === 0) {
      this.numberPagetblPCAssocSelected = 0;
    } else {
      this.numberPagetblPCAssocSelected = pageSelectedtblPCAssoc * this.itemsOnPagetblPCAssoc;
    }
  }
  datatblPCAssocReset() {
    this.numberPagetblPCAssocSelected = 0;
    this.numberPagetblPCAssoc = Array(Math.ceil(this.tblDataPCAssoc.length / this.itemsOnPagetblPCAssoc));
  }

  changePagetblPCList(pageSelectedtblPCList) {
    if (pageSelectedtblPCList === 0) {
      this.numberPagetblPCListSelected = 0;
    } else {
      this.numberPagetblPCListSelected = pageSelectedtblPCList * this.itemsOnPagetblPCList;
    }
  }
  datatblPCListReset() {
    this.numberPagetblPCListSelected = 0;
    this.numberPagetblPCList = Array(Math.ceil(this.tblDataPCList.length / this.itemsOnPagetblPCList));
  }

  getPlatforms(platforms) {
    return '';
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'initFavorite():error');
      let message = 'Falha ao configurar Favorito PalavraChaveFuncionalidadeController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  doFavorite() {
    const vm = this;

    // verificar operacao
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'favoritos():error');
      let message = 'Falha ao atualizar como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  listar(carregarFuncionalidade: boolean) {
    const vm = this;
    vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'listar()');
    vm.webservice.post('ws/palavrachave/funcionalidade/listar', null, success, fail);

    function success(res) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      if (carregarFuncionalidade) {
        vm.funcionalidadeContent = [];
        if (res.data.funcionalidade && res.data.funcionalidade.length > 0) {
          vm.funcionalidadeContent.push({
            'id': null,
            'descricao': 'Selecione aqui'
          });
          res.data.funcionalidade.forEach((e) => {
            vm.funcionalidadeContent.push({
              'id': e.id,
              'descricao': e.descricao
            });
          });
        }
      }
      vm.tblDataPCList = [];
      if (res.data.palavraChave && res.data.palavraChave.length > 0) {
        res.data.palavraChave.forEach((e) => {
          vm.tblDataPCList.push({
            'id': e.id,
            'palavraChave': e.palavraChave
          });
        });
      }
      vm.numberPagetblPCList = Array(Math.ceil(vm.tblDataPCList.length / vm.itemsOnPagetblPCList));
      vm.numberPagetblPCListSelected = 0;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'listar():success');
      let message = 'Falha ao listar os registros.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      message = (status === 404) && err ? err : xhr;
      vm.log.error(message);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Atenção', message);
    }
  }

  funcionalidade_Change() {
    const vm = this;
    this.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'funcionalidade_Change()');

    vm.funcionalidadeSelecionadaID = this.appService.getSelectedIndexValue('funcionalidade');
    vm.funcionalidadeSelecionada = this.appService.getSelectedIndexText('funcionalidade');

    if ( vm.funcionalidadeSelecionadaID === 'null' ) {
      vm.limpar();
      return;
    }

    function success(res) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'funcionalidade_Change():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }

      if (res.data.funcionalidadeResumo && res.data.funcionalidadeResumo.length > 0) {
        vm.myForm.get('funcionalidadeResumo').setValue(res.data.funcionalidadeResumo);
        vm.myFormOld.get('funcionalidadeResumo').setValue(res.data.funcionalidadeResumo);
        vm.FuncionalidadeResumo_KeyUp();
      }

      vm.tblDataPCAssoc = [];
      if (res.data.palavraChaveAssoc && res.data.palavraChaveAssoc.length > 0) {
        res.data.palavraChaveAssoc.forEach((e) => {
          vm.tblDataPCAssoc.push({
            'id': e.id,
            'palavraChave': e.palavraChave
          });
        });
      }
      vm.numberPagetblPCAssoc = Array(Math.ceil(vm.tblDataPCAssoc.length / vm.itemsOnPagetblPCAssoc));
      vm.numberPagetblPCAssocSelected = 0;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'funcionalidade_Change():success');
      let message = 'Falha ao listar as Palavras-chaves associadas a Funcionalidade selecionada.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      message = (status === 404) && err ? err : xhr;
      vm.log.error(message);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Atenção', message);
    }

    const params = {
      'funcionalidadeID': vm.funcionalidadeSelecionadaID
    };

    vm.webservice.post('ws/palavrachave/funcionalidade/filtrar', JSON.stringify(params), success, fail);
  }

  alterarFuncionalidadeResumo() {
    const vm = this;
    this.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'alterarFuncionalidadeResumo()');

    const funcionalidadeResumo = vm.myForm.get('funcionalidadeResumo').value;
    const funcionalidadeResumoOld = vm.myFormOld.get('funcionalidadeResumo').value;

    if ( funcionalidadeResumo === funcionalidadeResumoOld ) {
      vm.alertService.showMessage('Atenção', 'O resumo da Funcionalidade não foi alterado.');
      return;
    }

    if ( funcionalidadeResumo.length < 15 ) {
      vm.alertService.showMessage('Atenção', 'Informe ao menos 15 caracteres.');
      return;
    }

    function success(res) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'alterarFuncionalidadeResumo():success');
      if (res.temErro) {
        const msg = 'Ocorreu um erro ao alterar o resumo da Funcionalidade - ' + res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        vm.alertService.showMessage('Atenção', 'Alteração do resumo da Funcionalidade efetuada com sucesso.');
        vm.myForm.get('funcionalidadeResumo').setValue(funcionalidadeResumo);
        vm.myFormOld.get('funcionalidadeResumo').setValue(funcionalidadeResumo);
        vm.FuncionalidadeResumo_KeyUp();
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'alterarFuncionalidadeResumo():success');
      let message = 'Falha ao alerar o resumo da Funcionalidade.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      message = (status === 404) && err ? err : xhr;
      vm.log.error(message);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Atenção', message);
    }

    const params = {
      'funcionalidadeID': vm.funcionalidadeSelecionadaID,
      'funcionalidade': vm.funcionalidadeSelecionada,
      'funcionalidadeResumo': funcionalidadeResumo
    };

    vm.webservice.post('ws/palavrachave/funcionalidade/alterarResumo', JSON.stringify( params ), success, fail);
  }

  incluirPalavraChave() {
    const vm = this;
    this.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'incluirPalavraChave()');
    const palavraChave = this.appService.getInputTextValue('filtrotblPCList');
    if ( palavraChave === '' ) {
      vm.alertService.showMessage('Atenção', 'Informe a Palavra Chave para incluir.');
      return;
    }
    if ( palavraChave.length < 2 ) {
      vm.alertService.showMessage('Atenção', 'A Palavra Chave deve ter o mínimo de 2 caracteres.');
      return;
    }

    function success(res) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'incluirPalavraChave():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        vm.alertService.showMessage('Atenção', 'Inclusão da Palavra Chave efetuada com sucesso.');
        vm.listar(false);
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'incluirPalavraChave():fail');
      let message = 'Falha ao incluir a Palavra Chave.';
      message = (status === 404) && err ? err : message;
      if (status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Atenção', message);
    }

    const params = {
      'palavraChave': palavraChave
    };

    vm.webservice.post('ws/palavrachave/persistir', JSON.stringify(params), success, fail);
  }

  incluirAssociacao(row) {
    const vm = this;
    vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'incluir()');
    if (vm.funcionalidadeSelecionadaID === 'null') {
      vm.alertService.showMessage('Atenção', 'Selecione uma Funcionalidade antes de realizar essa ação.');
      return;
    }
    // vm.tblDataPCAssoc.forEach((e, i
    //   if (e.id === row.id
    //     vm.alertService.showMessage('Atenção', 'A palavra chave ' + row.palavraChave + ' já está associada a funcionalidade ' + vm.funcionalidadeSelecionada + '.');
    //     return
    if (vm.tblDataPCAssoc !== null && vm.tblDataPCAssoc.filter( a => a.id === row.id).length > 0) {
      vm.alertService.showMessage('Atenção', 'A palavra chave ' + row.palavraChave + ' já está associada a funcionalidade ' + vm.funcionalidadeSelecionada + '.');
      return;
    }

    function success(res) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'incluir():success');
      if (res.temErro) {
        const msg = 'Ocorreu um erro ao incluir a associação da Palavra Chave X Funcionalidade - ' + res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        vm.tblDataPCAssoc.push(row);
        vm.tblDataPCAssoc.sort((a, b) => a.palavraChave.localeCompare(b.palavraChave) );
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'incluir():fail');
      let message = 'Falha ao incluir a associação da Palavra Chave X Funcionalidade';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
    }

    const params = {
      'funcionalidadeID': vm.funcionalidadeSelecionadaID,
      'funcionalidade': vm.funcionalidadeSelecionada,
      'palavraChaveID': row.id,
      'palavraChave': row.palavraChave
    };
    vm.webservice.post('ws/palavrachave/funcionalidade/incluir', JSON.stringify( params ), success, fail);
  }

  excluirAssociacao(row) {
    const vm = this;
    vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'excluir()');

    function success(res) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'excluir():success');
      if (res.temErro) {
        const msg = 'Ocorreu um erro ao excluir a associação da Palavra Chave X Funcionalidade - ' + res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        vm.tblDataPCAssoc.forEach((e, i) => {
          if (e.id === row.id) {
            vm.tblDataPCAssoc.splice(i, 1);
          }
        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PalavraChaveFuncionalidadeController', 'excluir():fail');
      let message = 'Falha ao excluir a associação da Palavra Chave X Funcionalidade';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
    }

    const params = {
      'funcionalidadeID': vm.funcionalidadeSelecionadaID,
      'funcionalidade': vm.funcionalidadeSelecionada,
      'palavraChaveID': row.id,
      'palavraChave': row.palavraChave
    };

    vm.webservice.post('ws/palavrachave/funcionalidade/excluir', JSON.stringify( params ), success, fail);
  }

  FuncionalidadeResumo_KeyUp() {
    const vm = this;
    if (vm.acessoAlteracao) {
      const fr = (<HTMLInputElement>document.getElementById('funcionalidadeResumo'));
      const bt = (<HTMLInputElement>document.getElementById('btnAtualizarResumo'));
      bt.disabled = !(vm.funcionalidadeSelecionadaID !== 'null' && fr.value !== '');
    }
  }

  FiltrarPalavraChave_KeyUp() {
    const vm = this;
    if (vm.palavraChaveAcessoInclusao) {
      const fr = (<HTMLInputElement>document.getElementById('filtrotblPCList'));
      const bt = (<HTMLInputElement>document.getElementById('btnIncluirPalavraChave'));
      bt.disabled = !($('#tblPCList tbody tr').length === 0 && fr.value !== '');
    }
  }

  limpar() {
    const vm = this;
    vm.myForm.patchValue({
      funcionalidade: null,
      funcionalidadeResumo: null
    });
    vm.myFormOld.patchValue({
      funcionalidade: null,
      funcionalidadeResumo: null
    });
    vm.tblDataPCAssoc = [];
    vm.FuncionalidadeResumo_KeyUp();
  }
}
