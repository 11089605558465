import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../services/application/application.service';
import { CurrentView } from '../../../environments/currentview';
import { Project } from '../../../environments/project';
import { LogService } from '../../services/log/log.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../services/alert/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.css']
})
export class SiteHeaderComponent implements OnInit, AfterViewInit {

  currentview = CurrentView;
  project = Project;
  home = null;
  // telainicial = false;
  searchFilesTopForm: FormGroup;

  constructor(private translate: TranslateService, private appService: ApplicationService, private log: LogService,
    private alertService: AlertService, private formBuilder: FormBuilder, private router: Router) {
    translate.setDefaultLang('pt');
  }

  title: string = this.appService.getTitle();

  ngOnInit() {
    // this.telainicial = (this.project.views.length > 0 && this.appService.getState().indexOf(this.project.views[0].id) >= 0);
    this.searchFilesTopForm = this.formBuilder.group({ searchBoxTop: null });
    this.currentview.menu = this.appService.getCpfUsuario() != null;
    this.currentview.searchbox = !window.location.hash.includes('/arquivos') && this.appService.getCpfUsuario() != null;
  }

  ngAfterViewInit() {

    function nvtFunction() {
      const aux = document.getElementsByClassName('navbar-collapse')[0];
      const auxAtrr = aux.getAttribute('aria-expanded');
      const auxhasAtrr = aux.hasAttribute('aria-expanded');
      if (  auxAtrr === 'false' || !auxhasAtrr || typeof auxAtrr === 'undefined') {
          document.getElementsByClassName('overlay')[0].setAttribute('style', 'display: block');
          document.getElementsByClassName('side-menubar')[0].setAttribute('style', 'margin-left: 0px');
      } else {
       document.getElementsByClassName('overlay')[0].setAttribute('style', 'display: none');
       document.getElementsByClassName('side-menubar')[0].setAttribute('style', 'margin-left: -700px');
      }
    }

    function calendarFunction() {
      document.getElementsByClassName('config-intranet')[0].setAttribute('style', 'display: none');
      document.getElementsByClassName('pesquisa-intranet')[0].setAttribute('style', 'display: none');
      const calendar = document.getElementsByClassName('calendario-intranet')[0];
      if (calendar.hasAttribute('style')) {
        if (calendar.getAttribute('style') === 'display: none') {
           calendar.setAttribute('style', 'display: block');
           document.getElementsByClassName('overlay')[0].setAttribute('style', 'display: block');
        } else {
           calendar.setAttribute('style', 'display: none');
           document.getElementsByClassName('overlay')[0].setAttribute('style', 'display: none');
        }
      } else {
        calendar.setAttribute('style', 'display: block');
        document.getElementsByClassName('overlay')[0].setAttribute('style', 'display: block');
      }
    }

    function pesquisaFunction() {
      document.getElementsByClassName('calendario-intranet')[0].setAttribute('style', 'display: none');
      document.getElementsByClassName('config-intranet')[0].setAttribute('style', 'display: none');
      const pesquisa = document.getElementsByClassName('pesquisa-intranet')[0];
      if (pesquisa.hasAttribute('style')) {
        if (pesquisa.getAttribute('style') === 'display: none') {
          pesquisa.setAttribute('style', 'display: block');
          document.getElementsByClassName('overlay')[0].setAttribute('style', 'display: block');
        } else {
          pesquisa.setAttribute('style', 'display: none');
          document.getElementsByClassName('overlay')[0].setAttribute('style', 'display: none');
        }
      } else {
        pesquisa.setAttribute('style', 'display: block');
        document.getElementsByClassName('overlay')[0].setAttribute('style', 'display: block');
      }
    }

    function configFunction() {
      document.getElementsByClassName('calendario-intranet')[0].setAttribute('style', 'display: none');
      document.getElementsByClassName('pesquisa-intranet')[0].setAttribute('style', 'display: none');
      const config = document.getElementsByClassName('config-intranet')[0];
      if (config.hasAttribute('style')) {
        if (config.getAttribute('style') === 'display: none') {
          config.setAttribute('style', 'display: block');
          document.getElementsByClassName('overlay')[0].setAttribute('style', 'display: block');
        } else {
          config.setAttribute('style', 'display: none');
          document.getElementsByClassName('overlay')[0].setAttribute('style', 'display: none');
          }
      } else {
        config.setAttribute('style', 'display: block');
        document.getElementsByClassName('overlay')[0].setAttribute('style', 'display: block');
      }
    }
  }

  pesquisarTelas(str, telas) {
    const matches = [];
    telas.forEach(function(tela) {
        if (tela.title.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) {
            matches.push(tela);
        }
    });
    return matches;
  }

  compiler(value) {
    return value;
  }

  getPlatforms() {
    return null;
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  goSearchFiles() {
    const searchBoxTop = this.searchFilesTopForm.get('searchBoxTop').value;
    if (searchBoxTop === null || searchBoxTop === '' ) {
      this.alertService.showMessage('Atenção', 'Informe a palavra para a pesquisa.');
      return;
    }
    this.router.navigate(['/arquivos', {searchBoxTop: searchBoxTop}]);
  }
}
