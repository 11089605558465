import { NgModule } from '@angular/core';
import { FilterTablePipe } from './FilterTablePipe.pipe';

@NgModule({
     imports: [],
     declarations: [FilterTablePipe],
     exports: [FilterTablePipe]
})
export class PipeModule {
   static forRoot() {
      return {
          ngModule: PipeModule,
          providers: [],
      };
   }
}
