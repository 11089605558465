import { Component, OnInit, ViewChild } from '@angular/core';
import { LogService } from '../../../services/log/log.service';
import { Config } from '../../../../environments/config';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { CurrentView } from '../../../../environments/currentview';
import { ApplicationService } from '../../../services/application/application.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { AlertService } from '../../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NativeInterfaceService } from '../../../services/native-interface/native-interface.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSort, Sort } from '@angular/material';

declare const $: any;

@Component({
  selector: 'app-descontospub',
  templateUrl: './descontospub.component.html',
  styleUrls: ['./descontospub.component.css',
              '../../../../assets/css/publico/publico.css']
})
export class DescontosPubComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    public appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private http: HttpClient, private nativeInterface: NativeInterfaceService,
    private translate: TranslateService) {
    this.native = nativeInterface;
    translate.setDefaultLang('pt');
  }

  native: NativeInterfaceService;
  currentview = CurrentView;
  myForm: FormGroup;

  //
  criterio = '7';
  tblData = [];
  filtrotbl = null;
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;
  areas = [];
  colSpanRes = 0;
  colResTot = 0;
  exibirTabela = false;
  exibirAnoOrca = false;
  dataAtual = new Date();
  ordemPeriodoResultado = 'asc';

  totVrFinanc = null;
  totFgtsCompl = null;
  totFgtsEquil = null;
  totOguCompl = null;
  totOguEquil = null;
  totNumUnid = null;
  totEmpGerados = null;
  totPopBenef = null;

  regioes = [];
  ufs = [];
  programas = [];
  faixaProgramas = [];
  subprogramas = [];
  rendas = [];
  linhasCredito = [];
  periodos = [];
  anosOrcamento = [];
  municipios = [];

  timer = null;
  dtPosicao: '';
  today = null;
  periodoRes = null;
  regiaoRes = null;
  ufRes = null;
  municipioRes = null;
  areaRes = null;
  programaRes = null;
  subprogramaRes = null;
  faixaProgramaRes = null;
  rendaRes = null;
  linhaCreditoRes = null;
  anoOrcamentoRes = null;
  tipoImovelRes = null;

  multSelDropdownSettings = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  multSelDropdownSettingsGroupBy = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    groupBy: 'category',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  regioesSelected = [];
  ufsSelected = [];
  municipiosSelected = [];
  programasSelected = [];
  subprogramasSelected = [];
  faixaProgramasSelected = [];
  linhascreditoSelected = [];
  rendasSelected = [];

  observacao = null;
  public p: number;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    const vm = this;
    const element = <HTMLElement> document.getElementsByClassName('theme-app')[0];
    element.style.backgroundColor = '#FFF';
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.today = Date.now();
    this.myForm = this.formBuilder.group({
      id: null,
      regiao: [ null ],
      uf: [ null ],
      municipio: [ null ],
      linhasCredito: [ null ],
      programa: [ null ],
      subprograma: [ null ],
      faixaPrograma: [ null ],
      detFaixaPrograma: null,
      renda: [ null ],
      periodo: 'M',
      periodoInicial: [ '' ],
      periodoFinal: [ '' ],
      anoOrcamento: [ null ],
      tipoImovel: [ '' ],
      detalhamento: [ 'null' ],
      detalhe0: null,
      detalhe1: null,
      detalhe1a: null,
      detalhe2: null,
      detalhe2a: null,
      detalhe3: null,
      detalhe5: null,
      detalhe6: null,
      detalhe7: null,
      detalhe8: true,
      detalhe9: true,
      detalhe10: true,
      detalhe11: true,
      detalhe12: true,
      detalhe13: true,
      detalhe14: true,
      detalhe15: true,
      ordemPeriodoResultado: 'C'
    });
    this.popularCampos(vm.myForm.get('periodo').value);
    this.executarJS();
    this.p = 1;
    this.ordemPeriodoResultado = 'asc';
  }

  datatbl2Reset() {
    this.numberPagetbl2Selected = 0;
    this.numberPagetbl2 = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl2));
  }

  listar(target) {
    const vm = this;
    this.analytics.trackEvent('DescontosPubController', 'listar()');

    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt( vm.myForm.get('periodoInicial').value, 10) > parseInt( vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let detChecked = 0;
    $('input[type=checkbox]:checked').each(function() {
      detChecked += 1;
    });
    if (detChecked === 0 ) {
      vm.alertService.showMessage('Atenção', 'Favor informar ao menos 1 ítem de detalhamento.');
      return;
    }

    let params = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('anoOrcamento', vm.myForm.get('anoOrcamento').value);
    params = params.append('tipoImovel', vm.myForm.get('tipoImovel').value);
    params = params.append('detAnoOrc', String(vm.appService.getInputCheckboxValue('detalhe0')));
    params = params.append('detPrograma', String(vm.appService.getInputCheckboxValue('detalhe1')));
    params = params.append('detSubprograma', String(vm.appService.getInputCheckboxValue('detalhe1a')));
    params = params.append('detFaixaPrograma', String(vm.appService.getInputCheckboxValue('detFaixaPrograma')));
    params = params.append('detLinCredito', String(vm.appService.getInputCheckboxValue('detalhe2')));
    params = params.append('detRenda', String(vm.appService.getInputCheckboxValue('detalhe2a')));
    params = params.append('detTpImovel', String(vm.appService.getInputCheckboxValue('detalhe3')));
    params = params.append('detRegiao', String(vm.appService.getInputCheckboxValue('detalhe5')));
    params = params.append('detUf', String(vm.appService.getInputCheckboxValue('detalhe6')));
    params = params.append('detMunicipio', String(vm.appService.getInputCheckboxValue('detalhe7')));
    params = params.append('detVrFinanc', String(vm.appService.getInputCheckboxValue('detalhe8')));
    params = params.append('detFgtsCompl', String(vm.appService.getInputCheckboxValue('detalhe9')));
    params = params.append('detFgtsEquil', String(vm.appService.getInputCheckboxValue('detalhe10')));
    params = params.append('detOguCompl', String(vm.appService.getInputCheckboxValue('detalhe11')));
    params = params.append('detOguEquil', String(vm.appService.getInputCheckboxValue('detalhe12')));
    params = params.append('detNumUnid', String(vm.appService.getInputCheckboxValue('detalhe13')));
    params = params.append('detEmpGerados', String(vm.appService.getInputCheckboxValue('detalhe14')));
    params = params.append('detPopBenef', String(vm.appService.getInputCheckboxValue('detalhe15')));
    const body = {
      'regiao': vm.appService.getListaAlterado(vm.regioesSelected, 'id'),
      'uf': vm.appService.getListaAlterado(vm.ufsSelected, 'id'),
      'municipio': vm.appService.getListaAlterado(vm.municipiosSelected, 'id'),
      'programa': vm.appService.getListaAlterado(vm.programasSelected, 'itemName'),
      'subprograma': vm.appService.getListaAlterado(vm.subprogramasSelected, 'itemName'),
      'faixaPrograma': vm.appService.getListaAlterado(vm.faixaProgramasSelected, 'id'),
      'linhaCredito': vm.appService.getListaAlterado(vm.linhascreditoSelected, 'itemName'),
      'renda': vm.appService.getListaAlterado(vm.rendasSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.create('ws/publico/descontos', body, success, fail, params);

    vm.p = 1;

    function success(res) {
      vm.analytics.trackEvent('DescontosPubController', 'listar():success');

      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      vm.observacao = null;
      vm.dataAtual = new Date();
      if (res.data && res.data.length > 0) {
        res.data.forEach((e) => {
          vm.tblData.push({
            'id': e.id,
            'anoOrc': e.anoOrc,
            'regiao': e.regiao,
            'uf': e.uf,
            'municipio': e.municipio,
            'programa': e.programa,
            'subprograma': e.subprograma,
            'faixaPrograma': e.faixaPrograma,
            'linCredito': e.linCredito,
            'renda': e.renda,
            'anoMesAssin': e.anoMesAssin,
            'tpImovel': e.tpImovel,
            'faixa15': e.faixa15,
            'sumVrFinanc': e.sumVrFinanc,
            'sumFgtsCompl': e.sumFgtsCompl,
            'sumFgtsEquil': e.sumFgtsEquil,
            'sumOguCompl': e.sumOguCompl,
            'sumOguEquil': e.sumOguEquil,
            'sumNumUnid': e.sumNumUnid,
            'sumEmpGerados': e.sumEmpGerados,
            'sumPopBenef': e.sumPopBenef
          });
          vm.exibirAnoOrca = e.orcamentoano !== null;
        });
        vm.totVrFinanc = res.totSumVrFinanc;
        vm.totFgtsCompl = res.totSumFgtsCompl;
        vm.totFgtsEquil = res.totSumFgtsEquil;
        vm.totOguCompl = res.totSumOguCompl;
        vm.totOguEquil = res.totSumOguEquil;
        vm.totNumUnid = res.totSumNumUnid;
        vm.totEmpGerados = res.totSumEmpGerados;
        vm.totPopBenef = res.totSumPopBenef;
        if (res.observacao !== null) {
          vm.observacao = vm.appService.decodeUri(res.observacao);
          setTimeout(() => {
            const divobs = document.getElementById('observacao');
            divobs.innerHTML = vm.observacao;
          }, 250);
        }
        vm.scrollToElement(target);
      }

      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.numberPagetbl2Selected = 0;
      vm.exibirTabela = true;
      vm.contarColSpan(vm);
      vm.periodoRes = $('#periodoInicial option:selected').text() + ' a ' + $('#periodoFinal option:selected').text();
      vm.regiaoRes = $('#regiao option:selected').text();
      vm.ufRes = $('#uf option:selected').text();
      vm.municipioRes = $('#municipio option:selected').text();
      vm.programaRes = $('#programa option:selected').text();
      vm.subprogramaRes = $('#subprograma option:selected').text();
      vm.faixaProgramaRes = $('#faixaPrograma option:selected').text();
      vm.linhaCreditoRes = $('#linhasCredito option:selected').text();
      vm.rendaRes = $('#renda option:selected').text();
      vm.anoOrcamentoRes = $('#anoOrcamento option:selected').text();
      vm.tipoImovelRes = $('#tipoImovel option:selected').text();
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('DescontosController', 'listar():success');
      let message = 'Falha ao listar Descontos.';
      if (status === 403 || status === 400) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
    }
  }

  private contarColSpan(vm: this) {
    vm.colSpanRes = 1;
    vm.colResTot = 1;
    if (vm.appService.getInputCheckboxValue('detalhe0')) {
      vm.colSpanRes += 1;
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe1')) {
      vm.colSpanRes += 1;
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe1a')) {
      vm.colSpanRes += 1;
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detFaixaPrograma')) {
      vm.colSpanRes += 1;
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe2')) {
      vm.colSpanRes += 1;
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe2a')) {
      vm.colSpanRes += 1;
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe3')) {
      vm.colSpanRes += 1;
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe5')) {
      vm.colSpanRes += 1;
      vm.colResTot += 1;
    }
    vm.contarColSpanOutrosDetalhes(vm);
  }

  private contarColSpanOutrosDetalhes(vm: this) {
    if (vm.appService.getInputCheckboxValue('detalhe7')) {
      vm.colSpanRes += 2;
      vm.colResTot += 1;
    } else if (vm.appService.getInputCheckboxValue('detalhe6')) {
      vm.colSpanRes += 1;
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe8')) {
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe9')) {
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe10')) {
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe11')) {
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe12')) {
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe13')) {
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe14')) {
      vm.colResTot += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe15')) {
      vm.colResTot += 1;
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    const vm = this;
    this.myForm.patchValue({
      id: null,
      regiao: null,
      uf: null,
      municipio: null,
      linhasCredito: null,
      programa: null,
      subprograma: null,
      faixaPrograma: null,
      detFaixaPrograma: null,
      renda: null,
      periodo: 'M',
      periodoInicial: '',
      periodoFinal: '',
      anoOrcamento: null,
      tipoImovel: [ '' ],
      detalhamento: null,
      detalhe0: null,
      detalhe1: null,
      detalhe1a: null,
      detalhe2: null,
      detalhe2a: null,
      detalhe3: null,
      detalhe5: null,
      detalhe6: null,
      detalhe7: null,
      detalhe8: true,
      detalhe9: true,
      detalhe10: true,
      detalhe11: true,
      detalhe12: true,
      detalhe13: true,
      detalhe14: true,
      detalhe15: true,
      ordemPeriodoResultado: 'C'
    });
    this.tblData = [];
    this.exibirTabela = false;
    this.exibirAnoOrca = false;
    this.popularCampos(vm.myForm.get('periodo').value);
    this.ordemPeriodoResultado = 'asc';
  }

  popularCampos(per) {
    this.exibirTabela = false;
    const vm = this;
    this.analytics.trackEvent('DescontosPubController', 'popularCampos()');

    // ------------------------------------------------------------------------
    // desabilitar botoes de tipo de periodo (mensal/anual)
    // ------------------------------------------------------------------------
    const elMes = <HTMLInputElement> document.getElementById('periodom');
    elMes.disabled = true;
    const elAno = <HTMLInputElement> document.getElementById('periodoa');
    elAno.disabled = true;
    // ------------------------------------------------------------------------

    const params = new HttpParams().append('tipoPeriodo', per);
    this.webservice.read('ws/publico/descontos/filtros', params, success, fail);
    function success(res) {
      vm.analytics.trackEvent('DescontosController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.regioes = [];
      vm.ufs = [];
      vm.programas = [];
      vm.subprogramas = [];
      vm.faixaProgramas = [];
      vm.rendas = [];
      vm.periodos = [];
      vm.municipios = [];
      vm.linhasCredito = [];
      vm.regioesSelected = [];
      vm.ufsSelected = [];
      vm.municipiosSelected = [];
      vm.programasSelected = [];
      vm.subprogramasSelected = [];
      vm.faixaProgramasSelected = [];
      vm.rendasSelected = [];
      vm.linhascreditoSelected = [];
      if (res.data && res.data.length > 0) {
        vm.dtPosicao = res.dtPosicao;
        vm.periodos.push({
          'periodo': '',
          'periodofmt': 'Escolha o Período'
        });
        vm.anosOrcamento.push({
          'anoOrcamentoPer': null,
          'anoOrcamento': 'Todos'
        });
        res.data.forEach((filtros) => {
          filtros.regioes.forEach((data) => {
            vm.regioes.push({
              'id': data.codigo,
              'itemName': data.nome
            });
          });
          filtros.programas.forEach((data) => {
            vm.programas.push({
              'id': data.programa,
              'itemName': data.programa
            });
          });
          filtros.subprogramas.forEach((data) => {
            vm.subprogramas.push({
              'id': data.subprograma,
              'itemName': data.subprograma
            });
          });
          filtros.faixaProgramas.forEach((data) => {
            vm.faixaProgramas.push({
              'id': data.id,
              'itemName': data.descricao
            });
          });
          filtros.rendas.forEach((data) => {
            vm.rendas.push({
              'id': data.codigo,
              'itemName': data.descricao
            });
          });
          filtros.linhasCredito.forEach((data) => {
            vm.linhasCredito.push({
              'id': data.codigoLinhaCredito,
              'itemName': data.codigoLinhaCredito
            });
          });
          filtros.orcamentoAno.forEach((data) => {
            vm.anosOrcamento.push({
              'anoOrcamentoPer': data.periodo,
              'anoOrcamento': data.periodo
            });
          });
          filtros.periodosInicial.forEach((data) => {
            if (per === 'M') {
              vm.periodos.push({
                'periodo': data.periodo,
                'periodofmt': data.periodo.substring(4, 6) + '/' + data.periodo.substring(0, 4)
              });
            } else {
              vm.periodos.push({
                'periodo': data.periodo,
                'periodofmt': data.periodo
              });
            }
          });
        });
      }
      elMes.disabled = false;
      elAno.disabled = false;
    }
    function fail(xhr, status, err) {
      elMes.disabled = false;
      elAno.disabled = false;
      vm.analytics.trackEvent('DescontosPubController', 'popularCampos():error');
      let message = 'Falha ao popular campos DescontosPubController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  popularPeriodo(per) {
    const vm = this;
    this.exibirTabela = false;
    this.analytics.trackEvent('DescontosPubController', 'popularPeriodo()');

    // ------------------------------------------------------------------------
    // desabilitar botoes de tipo de periodo (mensal/anual)
    // ------------------------------------------------------------------------
    const elMes = <HTMLInputElement> document.getElementById('periodom');
    elMes.disabled = true;
    const elAno = <HTMLInputElement> document.getElementById('periodoa');
    elAno.disabled = true;
    // ------------------------------------------------------------------------

    const params = new HttpParams().append('tipoPeriodo', per);
    this.webservice.read('ws/publico/descontos/periodo', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('DescontosController', 'popularPeriodo():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.periodos = [];
      if (res.data && res.data.length > 0) {
        vm.periodos.push({
          'periodo': '',
          'periodofmt': 'Escolha o Período'
        });
        res.data.forEach((filtros) => {
          filtros.periodosInicial.forEach((data) => {
            if (per === 'M') {
              vm.periodos.push({
                'periodo': data.periodo,
                'periodofmt': data.periodo.substring(4, 6) + '/' + data.periodo.substring(0, 4)
              });
            } else {
              vm.periodos.push({
                'periodo': data.periodo,
                'periodofmt': data.periodo
              });
            }
          });
        });
      }
      elMes.disabled = false;
      elAno.disabled = false;
    }

    function fail(xhr, status, err) {
      elMes.disabled = false;
      elAno.disabled = false;
      vm.analytics.trackEvent('DescontosPubController', 'popularPeriodo():error');
      let message = 'Falha ao popular periodo DescontosPubController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  limpaTabela() {
    this.exibirTabela = false;
    this.totVrFinanc = null;
    this.totFgtsCompl = null;
    this.totFgtsEquil = null;
    this.totOguCompl = null;
    this.totOguEquil = null;
    this.totNumUnid = null;
    this.totEmpGerados = null;
    this.totPopBenef = null;
  }

  carregaperiodo(per) {
    this.myForm.patchValue({
      periodoInicial: null,
      periodoFinal: null,
    });
    this.myForm.get('periodo').setValue(per);
    this.popularPeriodo(per);
  }

  public exportar()  {
    const vm = this;
    this.analytics.trackEvent('DescontosPubController', 'exportar()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt( vm.myForm.get('periodoInicial').value, 10) > parseInt( vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let detChecked = 0;
    $('input[type=checkbox]:checked').each(function() {
      detChecked += 1;
    });
    if (detChecked === 0) {
      vm.alertService.showMessage('Atenção', 'Favor informar ao menos 1 ítem de detalhamento.');
      return;
    }
    let params = new HttpParams().append('rltr', 'r01');
    params = params.append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('anoOrcamento', vm.myForm.get('anoOrcamento').value);
    params = params.append('tipoImovel', vm.myForm.get('tipoImovel').value);
    params = params.append('detAnoOrc', String(vm.appService.getInputCheckboxValue('detalhe0')));
    params = params.append('detPrograma', String(vm.appService.getInputCheckboxValue('detalhe1')));
    params = params.append('detSubprograma', String(vm.appService.getInputCheckboxValue('detalhe1a')));
    params = params.append('detFaixaPrograma', String(vm.appService.getInputCheckboxValue('detFaixaPrograma')));
    params = params.append('detLinCredito', String(vm.appService.getInputCheckboxValue('detalhe2')));
    params = params.append('detRenda', String(vm.appService.getInputCheckboxValue('detalhe2a')));
    params = params.append('detTpImovel', String(vm.appService.getInputCheckboxValue('detalhe3')));
    params = params.append('detRegiao', String(vm.appService.getInputCheckboxValue('detalhe5')));
    params = params.append('detUf', String(vm.appService.getInputCheckboxValue('detalhe6')));
    params = params.append('detMunicipio', String(vm.appService.getInputCheckboxValue('detalhe7')));
    params = params.append('detVrFinanc', String(vm.appService.getInputCheckboxValue('detalhe8')));
    params = params.append('detFgtsCompl', String(vm.appService.getInputCheckboxValue('detalhe9')));
    params = params.append('detFgtsEquil', String(vm.appService.getInputCheckboxValue('detalhe10')));
    params = params.append('detOguCompl', String(vm.appService.getInputCheckboxValue('detalhe11')));
    params = params.append('detOguEquil', String(vm.appService.getInputCheckboxValue('detalhe12')));
    params = params.append('detNumUnid', String(vm.appService.getInputCheckboxValue('detalhe13')));
    params = params.append('detEmpGerados', String(vm.appService.getInputCheckboxValue('detalhe14')));
    params = params.append('detPopBenef', String(vm.appService.getInputCheckboxValue('detalhe15')));
    params = params.append('regiaoDsc', $('#regiao option:selected').text());
    params = params.append('ufDsc', $('#uf option:selected').text());
    params = params.append('municipioDsc', $('#municipio option:selected').text());
    params = params.append('programaDsc', $('#programa option:selected').text());
    params = params.append('subprogramaDsc', $('#subprograma option:selected').text());
    params = params.append('linhaCreditoDsc', $('#linhasCredito option:selected').text());
    params = params.append('rendaDsc', $('#renda option:selected').text());
    params = params.append('anoOrcamentoDsc', $('#anoOrcamento option:selected').text());
    params = params.append('tipoImovelDsc', $('#tipoImovel option:selected').text());
    params = params.append('ambiente', 'publico');
    const body = {
      'regiao': vm.appService.getListaAlterado(vm.regioesSelected, 'id'),
      'uf': vm.appService.getListaAlterado(vm.ufsSelected, 'id'),
      'municipio': vm.appService.getListaAlterado(vm.municipiosSelected, 'id'),
      'programa': vm.appService.getListaAlterado(vm.programasSelected, 'itemName'),
      'subprograma': vm.appService.getListaAlterado(vm.subprogramasSelected, 'itemName'),
      'faixaPrograma': vm.appService.getListaAlterado(vm.faixaProgramasSelected, 'id'),
      'linhaCredito': vm.appService.getListaAlterado(vm.linhascreditoSelected, 'itemName'),
      'renda': vm.appService.getListaAlterado(vm.rendasSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.postDownload('ws/publico/descontos/download', body, 'text/plain', true, 'descontos.txt', params);
  }

  executarJS() {
    const vm = this;
    jQuery(function() {
      $('#optDetMarcar').on('click', function(){
        $(':checkbox[name^=\'detalhe\']').each(function(){
          vm.myForm.get(this.id).setValue(true);
          vm.limpaTabela();
        });
      });
      $('#optDetDesmarcar').on('click', function(){
        $(':checkbox[name^=\'detalhe\']').each(function(){
          vm.myForm.get(this.id).setValue(false);
          vm.limpaTabela();
        });
      });
    });
  }

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  onItemSelectReg(item: any) {
    const vm = this;
    this.limpaTabela();
    vm.popularUfs(vm.regioesSelected);
  }

  OnItemDeSelectReg(item: any) {
    const vm = this;
    vm.ufsSelected = vm.ufsSelected.filter(u => u.category !== item.itemName);
    vm.municipiosSelected = vm.municipiosSelected.filter(mmu => vm.ufsSelected.some((usl) => usl.itemName === mmu.category));
    vm.onItemSelectReg(null);
    vm.onItemSelectUf(null);
    vm.limpaTabela();
  }

  onSelectAllReg(items: any) {
    const vm = this;
    vm.limpaTabela();
    vm.popularUfs(vm.regioes);
  }
  onDeSelectAllReg(items: any) {
    this.municipiosSelected = [];
    this.municipios = [];
    this.ufsSelected = [];
    this.ufs = [];
    this.limpaTabela();
  }

  onItemSelectUf(item: any) {
    const vm = this;
    vm.popularMunicipios(vm.ufsSelected);
    this.limpaTabela();
  }

  onItemDeSelectUf(item: any) {
    const vm = this;
    vm.municipiosSelected = vm.municipiosSelected.filter(mmu => vm.ufsSelected.some(usl => usl.itemName === mmu.category));
    vm.onItemSelectUf(null);
    vm.limpaTabela();
  }
  onSelectAllUf(items: any) {
    const vm = this;
    vm.popularMunicipios(vm.ufs);
    vm.limpaTabela();
  }
  onDeSelectAllUf(items: any) {
    this.municipiosSelected = [];
    this.municipios = [];
    this.limpaTabela();
  }
  onGroupDeSelectUf(items: any) {
    this.municipios = [];
    this.onItemSelectUf(null);
    if (items !== null) {
      items.list.forEach((uf, i) => {
        this.onItemDeSelectUf(uf);
      });
    }
  }

  onItemSelectMun(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelectMun(item: any) {
    this.limpaTabela();
  }
  onSelectAllMun(items: any) {
    this.limpaTabela();
  }
  onDeSelectAllMun(items: any) {
    this.limpaTabela();
  }

  onItemSelectSubprog(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelectSubprog(item: any) {
    this.limpaTabela();
  }
  onSelectAllSubprog(items: any) {
    this.limpaTabela();
  }
  onDeSelectAllSubprog(items: any) {
    this.limpaTabela();
  }

  onItemSelectFaixaPrograma(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelectFaixaPrograma(item: any) {
    this.limpaTabela();
  }
  onSelectAllFaixaPrograma(items: any) {
    this.limpaTabela();
  }
  onDeSelectAllFaixaPrograma(items: any) {
    this.limpaTabela();
  }

  onItemSelectPrograma(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelectPrograma(item: any) {
    this.limpaTabela();
  }
  onSelectAllPrograma(items: any) {
    this.limpaTabela();
  }
  onDeSelectAllPrograma(items: any) {
    this.limpaTabela();
  }

  onItemSelectLinha(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelectLinha(item: any) {
    this.limpaTabela();
  }
  onSelectAllLinha(items: any) {
    this.limpaTabela();
  }
  onDeSelectAllLinha(items: any) {
    this.limpaTabela();
  }

  onItemSelectRenda(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelectRenda(item: any) {
    this.limpaTabela();
  }
  onSelectAllRenda(items: any) {
    this.limpaTabela();
  }
  onDeSelectAllRenda(items: any) {
    this.limpaTabela();
  }

  popularUfs(listaRegiao: any) {
    const vm = this;
    vm.limpaTabela();
    if (listaRegiao == null || listaRegiao.length === 0) {
      vm.ufs = [];
      return;
    }
    const arrayList: Array<string> = listaRegiao.map(o => o.id);
    this.webservice.create('ws/publico/ufs', {'regioes': arrayList}, success, fail);
    function success(res) {
      vm.analytics.trackEvent('DescontosPubController', 'onSelectAllReg():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.ufs = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((uf) => {
          vm.ufs.push({
            'id': uf.sigla,
            'itemName': uf.nome,
            'category': uf.regiao
          });
        });
      }
    }
    function fail(xhr, status, err) {
      vm.analytics.trackEvent('DescontosPubController', 'onSelectAllReg():fail');
      let message = 'Falha ao carregar as UF`s.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  ordenarResultado(val: string) {
    this.myForm.get('ordemPeriodoResultado').setValue(val);
    this.ordemPeriodoResultado = val === 'D' ? 'desc' : 'asc';
  }

  popularMunicipios(listaUf: any) {
    const vm = this;
    if (listaUf == null || listaUf.length === 0) {
      vm.municipios = [];
      return;
    }
    const arrayList: Array<string> = listaUf.map(o => o.id);
    vm.webservice.create('ws/publico/municipios', {'ufs': arrayList}, success, fail);
    function success(res) {
      vm.analytics.trackEvent('DescontosPubController', 'popularMunicipios():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.municipios = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((mun) => {
          vm.municipios.push({
            'id': mun.id,
            'itemName': mun.descricao,
            'category': mun.ufDescricao
          });
        });
      }
    }
    function fail(xhr, status, err) {
      vm.analytics.trackEvent('DescontosPubController', 'popularMunicipios():fail');
      let message = 'Falha ao carregar os municipios.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  /**
   * Retorna os dados da tabela ordenados por uma coluna.
   */
  sortData(sort: Sort) {
    this.tblData = this.appService.sortData(sort, this.tblData);
  }
}
