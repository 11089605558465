import { Observable } from 'rxjs/Observable';
import { Component, OnInit } from '@angular/core';
import { LogService } from '../../../services/log/log.service';
import { Config } from '../../../../environments/config';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { CurrentView } from '../../../../environments/currentview';
import { ApplicationService } from '../../../services/application/application.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { AlertService } from '../../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { Moment } from 'moment';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { DownloadService } from '../../../download.service';
import { Download } from '../../../download';
import { NativeInterfaceService } from '../../../services/native-interface/native-interface.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-legislacao-pub',
  templateUrl: './legislacaopub.component.html',
  styleUrls: ['./legislacaopub.component.css',
              '../../../../assets/css/publico/publico.css']
})
export class LegislacaoPubComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private downloads: DownloadService,
    private nativeInterface: NativeInterfaceService, private translate: TranslateService) {
    translate.setDefaultLang('pt');
  }

  currentview = CurrentView;
  myForm: FormGroup;

  download$: Observable<Download>;

  criterio = '7';
  tbl = ['Tipo', 'Nome', 'Número', 'Data', 'Assunto', 'Observação'];
  tblData = [];
  tblcontentFunctionClass = ['fa-pencil-square-o', 'fa-trash-o'];

  //

  timer = null;

  filtrotbl = null;
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 100;
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;
  classificacoes = [];
  exibirTabela = false;
  minDate: Moment;
  minDateEnd: Moment;

  situacaoLegislacao = [];

  pageSelected = 0;

  public mask = {
    guide: true,
    showMask : true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  };

  multSelDropdownSettings = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  classificacoesSelected = [];

  ngOnInit() {
    this.log.info('LegislacaoPubController.init()');
    this.analytics.trackEvent('controller', 'LegislacaoControllerPub');

    const element = <HTMLElement> document.getElementsByClassName('theme-app')[0];
    element.style.backgroundColor = '#FFF';

    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      id: null,
      ano: null,
      palavra: null,
      classificacao: null,
      nome: null,
      data: null,
      descricao: null,
      caminho: null,
      dtInicial: null,
      dtFinal: null,
      sitLegislacao: null,
      dtInicialRevogacao: null,
      dtFinalRevogacao: null
    });
    this.popularCampos();
    this.pageSelected = 0;

    this.situacaoLegislacao = [];
    this.situacaoLegislacao.push({'id': null, 'sit': 'Todas'});
    this.situacaoLegislacao.push({'id': 'V' , 'sit': 'Vigente'});
    this.situacaoLegislacao.push({'id': 'R' , 'sit': 'Revogada'});

  }

  datatbl2Reset() {
    this.numberPagetbl2Selected = 0;
    this.numberPagetbl2 = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl2));
  }

  atualizarData() {
    const dtini = this.myForm.get('dtInicial').value == null ? this.minDate : moment(this.myForm.get('dtInicial').value);
    this.minDateEnd = this.myForm.get('dtInicial') ? dtini : this.minDate;
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('LegislacaoControllerPub', 'listar()');
    const anoNl = vm.myForm.get('ano').value;
    if  (anoNl !== null && anoNl.trim() !== '') {
      if (!(/^\d+$/.test(anoNl))) {
        vm.alertService.showMessage('Atenção', 'Campo \'Número da Publicação ou Ano\' deve ser numérico.');
      }
    }
    vm.tblData = [];
    let dini = vm.myForm.get('dtInicial').value;
    dini = !dini || dini == null ? null : moment(dini).format('DD/MM/YYYY');
    let dfim = vm.myForm.get('dtFinal').value;
    dfim = !dfim || dfim == null ? null : moment(dfim).format('DD/MM/YYYY');
    const dinirev = vm.myForm.get('dtInicialRevogacao').value;
    const dfimrev = vm.myForm.get('dtFinalRevogacao').value;
    const body = {
      'tipo': vm.appService.getListaAlterado(vm.classificacoesSelected, 'id'),
      'tipoNomes': vm.appService.getListaAlterado(vm.classificacoesSelected, 'itemName'),
      'ano': vm.myForm.get('ano').value,
      'palavra': vm.myForm.get('palavra').value,
      'dataInicial': dini,
      'dataFinal': dfim,
      'dataInicialRevogacao': dinirev,
      'dataFinalRevogacao': dfimrev,
      'sitLegislacao': vm.myForm.get('sitLegislacao').value,
    };
    vm.webservice.create('ws/publico/legislacoes/listar', body, success, fail, null);

    function success(res) {
      vm.analytics.trackEvent('LegislacaoControllerPub', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          element.descricao = element.descricao.replace(/_br_/ig, '<br>');
          element.descricao = element.descricao.replace(/_p_/ig, '<p>');
          element.descricao = element.descricao.replace(/_p,/ig, '</p>');
          element.descricao = element.descricao.replace(/_b_/ig, '<b>');
          element.descricao = element.descricao.replace(/_b,/ig, '</b>');
          element.descricao = element.descricao.replace(/_i_/ig, '<i>');
          element.descricao = element.descricao.replace(/_i,/ig, '</i>');
          element.descricao = element.descricao.replace(/_u_/ig, '<u>');
          element.descricao = element.descricao.replace(/_u,/ig, '</u>');
          vm.tblData.push({
            'nome': element.nome,
            'data': element.dataInclusao,
            'dataPublicacao': element.dataPublicacao,
            'descricao': element.descricao,
            'caminho': element.caminho,
            'dataRevogacao': element.dataRevogacao
          });
        });
      }
      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.numberPagetbl2Selected = 0;
      vm.exibirTabela = true;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('LegislacaoControllerPub', 'listar():error');
      let message = 'Falha ao listar LegislacaoControllerPub';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      id: null,
      ano: null,
      palavra: null,
      classificacao: null,
      nome: null,
      data: null,
      descricao: null,
      caminho: null,
      dtInicial: null,
      dtFinal: null,
      sitLegislacao: null,
      dtInicialRevogacao: null,
      dtFinalRevogacao: null
    });
    this.classificacoes = [];
    this.tblData = [];
    this.popularCampos();
    this.exibirTabela = false;
  }

  doExportData(nome, caminho) {
    const vm = this;
    this.analytics.trackEvent('LegislacaoControllerPub', 'doExportData()');

    const params = new HttpParams().append('chave', caminho);
    this.webservice.read('ws/publico/legislacoes/chave', params, success, null);

    function success(res) {
      vm.analytics.trackEvent('LegislacaoControllerPub', 'doExportData():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        vm.alertService.showMessage('Atenção', 'Arquivo Inexistente para Download.');
        return;
      }
      const filename = nome;
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.data.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/x-download'});
      const url = window.URL.createObjectURL(blob);
        linkElement.setAttribute('href', url);
        linkElement.setAttribute('download', filename);
        const clickEvent = new MouseEvent('click', {
          'view': window,
          'bubbles': true,
          'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  public imprimir()  {
    const vm = this;
    this.analytics.trackEvent('LegislacaoControllerPub', 'imprimir()');
    let dini = vm.myForm.get('dtInicial').value;
    dini = !dini || dini == null ? null : moment(dini).format('DD/MM/YYYY');
    let dfim = vm.myForm.get('dtFinal').value;
    dfim = !dfim || dfim == null ? null : moment(dfim).format('DD/MM/YYYY');
    const dinirev = vm.myForm.get('dtInicialRevogacao').value;
    const dfimrev = vm.myForm.get('dtFinalRevogacao').value;
    const body = {
      'tipo': vm.appService.getListaAlterado(vm.classificacoesSelected, 'id'),
      'tipoNomes': vm.appService.getListaAlterado(vm.classificacoesSelected, 'itemName'),
      'ano': vm.myForm.get('ano').value,
      'palavra': vm.myForm.get('palavra').value,
      'dataInicial': dini,
      'dataFinal': dfim,
      'dataInicialRevogacao': dinirev,
      'dataFinalRevogacao': dfimrev,
      'sitLegislacao': vm.myForm.get('sitLegislacao').value,
    };
    vm.webservice.create('ws/publico/legislacoes/imprimir', body, success, null, null);

    function success(res) {
      vm.analytics.trackEvent('LegislacaoControllerPub', 'imprimir():success');
      const filename = 'Legislacao.pdf';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  popularCampos() {
    const vm = this;
    this.analytics.trackEvent('ArquivosController', 'popularCampos()');
    this.webservice.read('ws/publico/legislacoes/filtros', null, success, fail);

    function success(res) {
      vm.analytics.trackEvent('ArquivosController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.classificacoes = [];
      vm.classificacoesSelected = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((filtros) => {
            vm.classificacoes.push({
              'id': filtros.sigla,
              'itemName': filtros.nome
            });
        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArquivosController', 'popularCampos():error');
      let message = 'Falha ao popular campos ArquivosController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  onItemSelect(item: any) {
    //
  }
  OnItemDeSelect(item: any) {
    //
  }
  onSelectAll(items: any) {
    //
  }
  onDeSelectAll(items: any) {
    //
  }
}
