import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../../../pipe.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerfilfuncionalidadeRoutingModule } from './perfilfuncionalidade-routing.module';
import { PerfilfuncionalidadeComponent } from './perfilfuncionalidade.component';

@NgModule({
  declarations: [PerfilfuncionalidadeComponent],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, NgxPaginationModule, PipeModule,
    PerfilfuncionalidadeRoutingModule
  ]
})
export class PerfilfuncionalidadeModule { }
