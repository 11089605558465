import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.css']
})
export class ModalErrorComponent implements OnInit {

  title: string;
  message: string;
  cause: string;
  isCollapsed = true;
  stack: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    //
  }
}
