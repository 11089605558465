import { Component, OnInit } from '@angular/core';
import { LogService } from '../../../services/log/log.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { CurrentView } from '../../../../environments/currentview';
import { ApplicationService } from '../../../services/application/application.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { AlertService } from '../../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { VgAPI } from 'videogular2/compiled/core';

@Component({
  selector: 'app-pesquisatrilhacapacitacao',
  templateUrl: './pesquisatrilhacapacitacao.component.html',
  styleUrls: ['./pesquisatrilhacapacitacao.component.css']
})
export class PesquisaTrilhaCapacitacaoComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    public appService: ApplicationService, private webservice: WebServiceService, private domSanitizer: DomSanitizer,
    private alertService: AlertService, private translate: TranslateService, private router: Router) {
    translate.setDefaultLang('pt');
  }

  currentview = CurrentView;
  myForm: FormGroup;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  idUsuario = null;
  caminho = '';
  videos = [];
  api: VgAPI;
  duracao: number;
  consulta = false;
  abrirPdf = false;
  categorias = [];
  nomeArq = '';

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      idVideo: null,
      idUsuario: null
    });
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.initFavorite();
    this.listar();
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('PesquisaTrilhaCapacitacaoComponent', 'initFavorite()');
    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('PesquisaTrilhaCapacitacaoComponent', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PesquisaTrilhaCapacitacaoComponent', 'initFavorite():error');
      let message = 'Falha ao configurar Favorito PesquisaTrilhaCapacitacaoComponent';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        vm.appService.goAuth();
      }
    }
  }

  doFavorite() {
    const vm = this;

    // verificar operacao
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('PesquisaTrilhaCapacitacaoComponent', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PesquisaTrilhaCapacitacaoComponent', 'favoritos():error');
      let message = 'Falha ao atualizar o pesquisa trilha capacitacao como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        vm.appService.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  listar() {
    const vm = this;
    vm.consulta = false;
    this.analytics.trackEvent('PesquisaTrilhaCapacitacaoController', 'listar()');
    this.webservice.read('ws/videos', null, success, fail);

    function success(res) {
      vm.consulta = true;
      vm.analytics.trackEvent('PesquisaTrilhaCapacitacaoController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.videos = [];
      vm.categorias = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((e) => {
          if (e.tipo === 'V') {
            const obj = {
              'id': e.id,
              'nome': e.nomeArquivo,
              'descricao': e.descricaoArquivo,
              'nota': e.avaliacao,
              'justitificativa': e.descricaoJustificativaAvaliacao,
              'decorrido': e.tempoDecorrido,
              'imagem': (e.imagem ? vm.domSanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + e.imagem.base64) : null)
            };
            vm.videos.push(obj);
          } else {
            const categ = {
              'id': e.id,
              'nome': e.nomeArquivo,
              'descricao': e.descricaoArquivo,
              'arquivos': e.arquivos,
              'exibe': false
            };
            vm.categorias.push(categ);
          }
        });
      }
    }

    function fail(xhr, status, err) {
      vm.consulta = true;
      vm.analytics.trackEvent('PesquisaTrilhaCapacitacaoController', 'listar():error');
      let message = 'Falha ao buscar vídeos PesquisaTrilhaCapacitacaoController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  exportarPdf(arq) {
    const vm = this;
    this.analytics.trackEvent('ArquivosController', 'exportarPdf()');
    const params = new HttpParams().append('arquivo', arq.nome);
    vm.nomeArq = arq.nome;
    this.webservice.getDownloadPdf('ws/videos/download/pdf', params, success);
    function success(res) {
      if (res) {
        vm.alertService.showMessagePdf(vm.nomeArq, res);
      }
    }
  }

  abrirPdfs(categ) {
    categ.exibe = !categ.exibe;
  }
}
