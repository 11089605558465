import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../../../pipe.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerfilcadastroRoutingModule } from './perfilcadastro-routing.module';
import { PerfilcadastroComponent } from './perfilcadastro.component';

@NgModule({
  declarations: [PerfilcadastroComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule, TranslateModule, NgxPaginationModule, PipeModule,
    PerfilcadastroRoutingModule
  ]
})
export class PerfilcadastroModule { }
