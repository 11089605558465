import { Component, OnInit, ViewChild } from '@angular/core';
import { LogService } from '../../../services/log/log.service';
import { Config } from '../../../../environments/config';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { CurrentView } from '../../../../environments/currentview';
import { ApplicationService } from '../../../services/application/application.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { GeolocationService } from '../../../services/geolocation/geolocation.service';
import { AlertService } from '../../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { NativeInterfaceService } from '../../../services/native-interface/native-interface.service';
import { MatSort, Sort } from '@angular/material';

declare const $: any;

@Component({
  selector: 'app-contratacoes',
  templateUrl: './contratacoes.component.html',
  styleUrls: ['./contratacoes.component.css']
})
export class ContratacoesComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    public appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private geolocation: GeolocationService,
    private router: Router, private nativeInterface: NativeInterfaceService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;
  criterio = '7';
  tblData = [];
  filtrotbl = null;
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;
  periodos = [];
  anosOrcamento = [];
  municipios = [];
  exibirTabela = false;
  exibirAnoOrca = false;
  colSpanRes = 0;
  dataAtual = new Date();
  totQuantidadeContratos = null;
  totValorEmprestimo = null;
  totQuantidadeUnidades = null;
  totQuantidadeEmpregosGerados = null;
  totQuantidadePopulacaoBeneficiada = null;
  ordemPeriodoResultado = 'asc';
  //
  regioes = [];
  ufs = [];
  areas = [];
  programas = [];
  faixaProgramas = [];
  timer = null;
  dtPosicao = null;

  native: NativeInterfaceService;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';

  multSelDropdownSettings = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  multSelDropdownSettingsGroupBy = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    groupBy: 'category',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  regioesSelected = [];
  ufsSelected = [];
  municipiosSelected = [];
  areasSelected = [];
  programasSelected = [];
  observacao = null;
  public p: number;
  faixaProgramasSelected = [];
  periodoRes = null;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    const vm = this;
    this.log.info('ContratacoesComponent.init()');
    this.analytics.trackEvent('ContratacoesController', 'ngOnInit()');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      id: null,
      regiao: [ null ],
      uf: [ null ],
      municipio: [ '' ],
      areas: [ null ],
      programa: [ null ],
      faixaPrograma: [ null ],
      detFaixaPrograma: null,
      periodo: [ 'M' ],
      periodoInicial: [ '' ],
      periodoFinal: [ '' ],
      anoOrcamento: [ null ],
      tipoImovel: [ '' ],
      detalhamento: [ 'null' ],
      detalhe0: null,
      detalhe1: null,
      detalhe2: null,
      detalhe3: null,
      detalhe4: null,
      detalhe5: null,
      detalhe6: null,
      detalhe7: null,
      ordemPeriodoResultado: 'C'
    });
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.initFavorite();
    this.popularCampos(vm.myForm.get('periodo').value);
    this.p = 1;
    this.ordemPeriodoResultado = 'asc';
    jQuery(function() {
      $('#optDetMarcar').on('click', function(){
        $(':checkbox[name^=\'det\']').each(function(){
          vm.myForm.get(this.id).setValue(true);
          vm.limpaTabela();
        });
      });
      $('#optDetDesmarcar').on('click', function(){
        $(':checkbox[name^=\'det\']').each(function(){
          vm.myForm.get(this.id).setValue(false);
          vm.limpaTabela();
        });
      });
    });
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('ContratacoesController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('ContratacoesController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ContratacoesController', 'initFavorite():error');
      let message = 'Falha ao configurar favorito RelatorioContratacoesController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  datatbl2Reset() {
    this.numberPagetbl2Selected = 0;
    this.numberPagetbl2 = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl2));
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('ContratacoesController', 'listar()');
    this.filtrotbl = null;
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if ( parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10) ) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    vm.p = 1;
    let inicio = vm.myForm.get('periodoInicial').value;
    if (vm.myForm.get('periodo').value === 'M') {
      inicio = inicio.substring(4, 6) + '/' + inicio.substring(0, 4);
    }
    let fim = vm.myForm.get('periodoFinal').value;
    if (vm.myForm.get('periodo').value === 'M') {
      fim = fim.substring(4, 6) + '/' + fim.substring(0, 4);
    }
    this.periodoRes = inicio + ' a ' + fim;
    let params = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('anoOrcamento', vm.myForm.get('anoOrcamento').value);
    params = params.append('tipoImovel', vm.myForm.get('tipoImovel').value);
    params = params.append('detalheArea', vm.myForm.get('detalhe0').value);
    params = params.append('detalheModa', vm.myForm.get('detalhe3').value);
    params = params.append('detalheProg', vm.myForm.get('detalhe1').value);
    params = params.append('detalheUf', vm.myForm.get('detalhe2').value);
    params = params.append('detalheMun', vm.myForm.get('detalhe4').value);
    params = params.append('detalhePJ', vm.myForm.get('detalhe5').value);
    params = params.append('detalhePF', vm.myForm.get('detalhe6').value);
    params = params.append('detalheRegiao', vm.myForm.get('detalhe7').value);
    params = params.append('detFaixaPrograma', vm.myForm.get('detFaixaPrograma').value);
    const body = {
      'regiao': vm.appService.getListaAlterado(vm.regioesSelected, 'itemName'),
      'uf': vm.appService.getListaAlterado(vm.ufsSelected, 'id'),
      'municipio': vm.appService.getListaAlterado(vm.municipiosSelected, 'itemName'),
      'areaAplicacao': vm.appService.getListaAlterado(vm.areasSelected, 'itemName'),
      'programa': vm.appService.getListaAlterado(vm.programasSelected, 'itemName'),
      'faixaPrograma': vm.appService.getListaAlterado(vm.faixaProgramasSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.create('ws/relatorios/contratacoes', body, success, fail, params);

    function success(res) {
      vm.analytics.trackEvent('ContratacoesController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      vm.observacao = null;
      vm.dataAtual = new Date();
      if (res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          vm.tblData.push({
            'id': element.id,
            'periodo': element.periodo,
            'regiao': element.regiao,
            'uf': element.cdUf,
            'municipio': element.municipio,
            'area': element.area,
            'programa': element.programa,
            'faixaPrograma': element.faixaPrograma,
            'modalidade': element.modalidade,
            'tipopessoa': element.tipoPessoa,
            'orcamentoano': element.orcamentoano,
            'quantidadeContratos': element.quantidadeContratos,
            'valorEmprestimo': element.valorEmprestimo,
            'quantidadeUnidades': element.quantidadeUnidades,
            'quantidadeEmpregosGerados': element.quantidadeEmpregosGerados,
            'quantidadePopulacaoBeneficiada': element.quantidadePopulacaoBeneficiada
          });
          vm.exibirAnoOrca = element.orcamentoano !== null;
        });
        vm.totQuantidadeContratos = res.totQuantidadeContratos;
        vm.totValorEmprestimo = res.totValorEmprestimo;
        vm.totQuantidadeUnidades = res.totQuantidadeUnidades;
        vm.totQuantidadeEmpregosGerados = res.totQuantidadeEmpregosGerados;
        vm.totQuantidadePopulacaoBeneficiada = res.totQuantidadePopulacaoBeneficiada;
        if (res.observacao !== null) {
          vm.observacao = vm.appService.decodeUri(res.observacao);
          setTimeout(() => {
            const divobs = document.getElementById('observacao');
            divobs.innerHTML = vm.observacao;
          }, 250);
        }
      }
      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.numberPagetbl2Selected = 0;
      vm.exibirTabela = true;
      vm.contarColSpan(vm);
      $(document).ready(function() {
        $('.colSpanRes').attr('colspan', (7 - vm.colSpanRes));
      });
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ContratacoesController', 'listar():error');
      let message = 'Falha ao listar Contratações.';
      if (status === 403 || status === 400) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
    }
  }

  private contarColSpan(vm: this) {
    vm.colSpanRes = 1;
    if (vm.exibirAnoOrca) {
      vm.colSpanRes += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe0')) {
      vm.colSpanRes += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe1')) {
      vm.colSpanRes += 1;
    }
    if (vm.appService.getInputCheckboxValue('detFaixaPrograma')) {
      vm.colSpanRes += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe3')) {
      vm.colSpanRes += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe5') || vm.appService.getInputCheckboxValue('detalhe6')) {
      vm.colSpanRes += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe4') || vm.appService.getInputCheckboxValue('detalhe2') || vm.appService.getInputCheckboxValue('detalhe7')) {
      vm.colSpanRes += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe4') || vm.appService.getInputCheckboxValue('detalhe2')) {
      vm.colSpanRes += 1;
    }
    if (vm.appService.getInputCheckboxValue('detalhe4')) {
      vm.colSpanRes += 1;
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    const vm = this;
    this.myForm.patchValue({
      id: null,
      regiao: null,
      uf: null,
      municipio: '',
      areas: null,
      programa: null,
      periodo: 'M',
      periodoInicial: '',
      periodoFinal: '',
      anoOrcamento: null,
      tipoImovel: '',
      detalhamento: null,
      detalhe0: null,
      detalhe1: null,
      detalhe2: null,
      detalhe3: null,
      detalhe4: null,
      detalhe5: null,
      detalhe6: null,
      detalhe7: null,
      detFaixaPrograma: null,
      ordemPeriodoResultado: 'C'
    });
    this.tblData = [];
    this.exibirTabela = false;
    this.exibirAnoOrca = false;
    this.popularCampos(vm.myForm.get('periodo').value);
    this.ordemPeriodoResultado = 'asc';
    this.p = 1;
  }

  popularCampos(tipoPeriodo) {
    this.exibirTabela = false;
    const vm = this;
    this.analytics.trackEvent('ContratacoesController', 'popularCampos()');

    // ------------------------------------------------------------------------
    // desabilitar botoes de tipo de periodo (mensal/anual)
    // ------------------------------------------------------------------------
    const elMes = <HTMLInputElement> document.getElementById('optPeriodoMensal');
    elMes.disabled = true;
    const elAno = <HTMLInputElement> document.getElementById('optPeriodoAnual');
    elAno.disabled = true;
    // ------------------------------------------------------------------------

    function success(res) {
      vm.analytics.trackEvent('ContratacoesController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.regioes = [];
      vm.regioesSelected = [];
      vm.ufs = [];
      vm.ufsSelected = [];
      vm.municipios = [];
      vm.municipiosSelected = [];
      vm.areas = [];
      vm.areasSelected = [];
      vm.programas = [];
      vm.programasSelected = [];
      vm.faixaProgramas = [];
      vm.faixaProgramasSelected = [];
      vm.periodos = [];
      if (res.data && res.data.length > 0) {
        vm.dtPosicao = res.dtPosicao;
        vm.periodos.push({
          'periodo': '',
          'periodofmt': 'Escolha o Período'
        });
        vm.anosOrcamento.push({
          'anoOrcamentoPer': null,
          'anoOrcamento': 'Todos'
        });

        res.data.forEach((filtros) => {
          filtros.regioes.forEach((dataRegiao) => {
            vm.regioes.push({
              'id': dataRegiao.codigo,
              'itemName': dataRegiao.nome
            });
          });
          filtros.programas.forEach((dataPro) => {
            vm.programas.push({
              'id': dataPro.codigoPrograma,
              'itemName': dataPro.codigoPrograma
            });
          });
          filtros.faixaProgramas.forEach((f) => {
            vm.faixaProgramas.push({
              'id': f.id,
              'itemName': f.descricao
            });
          });
          filtros.areas.forEach((dataArea) => {
            vm.areas.push({
              'id': dataArea.area,
              'itemName': toCamelCase(dataArea.area)
            });
          });
          filtros.orcamentoAno.forEach((dataOrc) => {
            vm.anosOrcamento.push({
              'anoOrcamentoPer': dataOrc.periodo,
              'anoOrcamento': dataOrc.periodo
            });
          });
          filtros.periodosInicial.forEach((dataPer) => {
            if (tipoPeriodo === 'M') {
              vm.periodos.push({
                'periodo': dataPer.periodo,
                'periodofmt': dataPer.periodo.substring(4, 6) + '/' + dataPer.periodo.substring(0, 4)
              });
            } else {
              vm.periodos.push({
                'periodo': dataPer.periodo,
                'periodofmt': dataPer.periodo
              });
            }
          });
        });
      }
      elMes.disabled = false;
      elAno.disabled = false;
    }

    function toCamelCase(str) {
      // Lower cases the string
      return str.toLowerCase()
        // Uppercases the first character in each group immediately following a space
        // (delimited by spaces)
        .replace( / (.)/g, function($1) { return $1.toUpperCase(); })
        // Uppercases the first character
        .replace( /^./, function($1) { return $1.toUpperCase(); })
        .replace( 'Saude', 'Saúde')
        .replace( 'Habitacao', 'Habitação')
        .replace( 'Basico', 'Básico');
    }

    function fail(xhr, status, err) {
      elMes.disabled = false;
      elAno.disabled = false;
      vm.analytics.trackEvent('ContratacoesController', 'popularCampos():error');
      let message = 'Falha ao popular campos ContratacoesController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }

    const params = new HttpParams().append('tipoPeriodo', vm.myForm.get('periodo').value);
    this.webservice.read('ws/relatorios/contratacoes/filtros', params, success, fail);
  }

  popularPeriodo(tipoPeriodo) {
    this.exibirTabela = false;
    const vm = this;
    this.analytics.trackEvent('ContratacoesController', 'popularPeriodo()');

    // ------------------------------------------------------------------------
    // desabilitar botoes de tipo de periodo (mensal/anual)
    // ------------------------------------------------------------------------
    const elMes = <HTMLInputElement> document.getElementById('optPeriodoMensal');
    elMes.disabled = true;
    const elAno = <HTMLInputElement> document.getElementById('optPeriodoAnual');
    elAno.disabled = true;
    // ------------------------------------------------------------------------

    const params = new HttpParams().append('tipoPeriodo', tipoPeriodo);
    this.webservice.read('ws/publico/contratacoes/filtros/periodos', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('ContratacoesController', 'popularPeriodo():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.periodos = [];
      if (res.data && res.data.length > 0) {
        vm.periodos.push({
          'periodo': '',
          'periodofmt': 'Escolha o Período'
        });
        res.data.forEach((filtros) => {
          filtros.periodosInicial.forEach((dataPer) => {
            if (tipoPeriodo === 'M') {
              vm.periodos.push({
                'periodo': dataPer.periodo,
                'periodofmt': dataPer.periodo.substring(4, 6) + '/' + dataPer.periodo.substring(0, 4)
              });
            } else {
              vm.periodos.push({
                'periodo': dataPer.periodo,
                'periodofmt': dataPer.periodo
              });
            }
          });
        });
      }
      elMes.disabled = false;
      elAno.disabled = false;
    }

    function fail(xhr, status, err) {
      elMes.disabled = false;
      elAno.disabled = false;
      vm.analytics.trackEvent('ContratacoesController', 'popularPeriodo():error');
      let message = 'Falha ao popular periodo ContratacoesController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  limpaTabela() {
    this.exibirTabela = false;
    this.totQuantidadeContratos = null;
    this.totValorEmprestimo = null;
    this.totQuantidadeUnidades = null;
    this.totQuantidadeEmpregosGerados = null;
    this.totQuantidadePopulacaoBeneficiada = null;
  }

  carregaperiodo(per) {
    this.myForm.patchValue({
      periodoInicial: null,
      periodoFinal: null,
    });
    this.myForm.get('periodo').setValue(per);
    this.popularPeriodo(per);
  }

  public imprimir() {
    const vm = this;
    this.analytics.trackEvent('ContratacoesController', 'imprimir()');

    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10) ) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }

    let params = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('anoOrcamento', vm.myForm.get('anoOrcamento').value);
    params = params.append('tipoImovel', vm.myForm.get('tipoImovel').value);
    params = params.append('detalheArea', vm.myForm.get('detalhe0').value);
    params = params.append('detalheModa', vm.myForm.get('detalhe3').value);
    params = params.append('detalheProg', vm.myForm.get('detalhe1').value);
    params = params.append('detalheRegiao', vm.myForm.get('detalhe7').value);
    params = params.append('detalheUf', vm.myForm.get('detalhe2').value);
    params = params.append('detalheMun', vm.myForm.get('detalhe4').value);
    params = params.append('detalhePJ', vm.myForm.get('detalhe5').value);
    params = params.append('detalhePF', vm.myForm.get('detalhe6').value);
    params = params.append('detFaixaPrograma', vm.myForm.get('detFaixaPrograma').value);

    const body = {
      'regiao': vm.appService.getListaAlterado(vm.regioesSelected, 'itemName'),
      'uf': vm.appService.getListaAlterado(vm.ufsSelected, 'id'),
      'municipio': vm.appService.getListaAlterado(vm.municipiosSelected, 'itemName'),
      'areaAplicacao': vm.appService.getListaAlterado(vm.areasSelected, 'itemName'),
      'programa': vm.appService.getListaAlterado(vm.programasSelected, 'itemName'),
      'faixaPrograma': vm.appService.getListaAlterado(vm.faixaProgramasSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.postDownload('ws/relatorios/imprimircontratacoes', body, 'application/pdf', true, 'contratacoes.pdf', params);

    function success(res) {
      vm.analytics.trackEvent('ContratacoesController', 'imprimir():success');
      const filename = 'Contratacoes.pdf';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ContratacoesController', 'imprimir():error');
      let message = 'Falha ao imprimir Contratações.';
      if (status === 403 || status === 400) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
          try {
            const response = JSON.parse(xhr.responseText);
            if (response && response.msgsErro && response.msgsErro.length > 0) {
              message = response.msgsErro[0];
            }
          } catch (ignore) {
          }
        }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
    }
  }

  public exportar()  {
    const vm = this;
    this.analytics.trackEvent('ContratacoesController', 'exportar()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10) ) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('anoOrcamento', vm.myForm.get('anoOrcamento').value);
    params = params.append('tipoImovel', vm.myForm.get('tipoImovel').value);
    params = params.append('detalheArea', vm.myForm.get('detalhe0').value);
    params = params.append('detalheModa', vm.myForm.get('detalhe3').value);
    params = params.append('detalheProg', vm.myForm.get('detalhe1').value);
    params = params.append('detalheUf', vm.myForm.get('detalhe2').value);
    params = params.append('detalheMun', vm.myForm.get('detalhe4').value);
    params = params.append('detalhePJ', vm.myForm.get('detalhe5').value);
    params = params.append('detalhePF', vm.myForm.get('detalhe6').value);
    params = params.append('detalheRegiao', vm.myForm.get('detalhe7').value);
    params = params.append('detFaixaPrograma', vm.myForm.get('detFaixaPrograma').value);
    const body = {
      'regiao': vm.appService.getListaAlterado(vm.regioesSelected, 'itemName'),
      'uf': vm.appService.getListaAlterado(vm.ufsSelected, 'id'),
      'municipio': vm.appService.getListaAlterado(vm.municipiosSelected, 'itemName'),
      'areaAplicacao': vm.appService.getListaAlterado(vm.areasSelected, 'itemName'),
      'programa': vm.appService.getListaAlterado(vm.programasSelected, 'itemName'),
      'faixaPrograma': vm.appService.getListaAlterado(vm.faixaProgramasSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.postDownload('ws/relatorios/exportarcontratacoes', body, 'application/xls', true, 'contratacoes.xls', params);

    function success(res) {
      vm.analytics.trackEvent('ContratacoesController', 'exportar():success');
      const filename = 'Contratacoes.xls';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/xls'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ContratacoesController', 'exportar():error');
      let message = 'Falha ao exportar Contratações.';
      if (status === 403 || status === 400) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
          try {
            const response = JSON.parse(xhr.responseText);
            if (response && response.msgsErro && response.msgsErro.length > 0) {
              message = response.msgsErro[0];
            }
          } catch (ignore) {
          }
        }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
    }
  }

  doFavorite() {
    const vm = this;
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('ContratacoesController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ContratacoesController', 'favoritos():error');
      let message = 'Falha ao atualizar a funcionalidade como favorita.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  ordenarResultado(val: string) {
    this.myForm.get('ordemPeriodoResultado').setValue(val);
    this.ordemPeriodoResultado = val === 'D' ? 'desc' : 'asc';
  }

  onItemSelectMun(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelectMun(item: any) {
    this.limpaTabela();
  }
  onSelectAllMun(items: any) {
    this.limpaTabela();
  }
  onDeSelectAllMun(items: any) {
    this.limpaTabela();
  }

  onItemSelectArea(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelectArea(item: any) {
    this.limpaTabela();
  }
  onSelectAllArea(items: any) {
    this.limpaTabela();
  }
  onDeSelectAllArea(items: any) {
    this.limpaTabela();
  }

  onItemSelectPrograma(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelectPrograma(item: any) {
    this.limpaTabela();
  }
  onSelectAllPrograma(items: any) {
    this.limpaTabela();
  }
  onDeSelectAllPrograma(items: any) {
    this.limpaTabela();
  }

  onItemSelectFaixaPrograma(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelectFaixaPrograma(item: any) {
    this.limpaTabela();
  }
  onSelectAllFaixaPrograma(items: any) {
    this.limpaTabela();
  }
  onDeSelectAllFaixaPrograma(items: any) {
    this.limpaTabela();
  }

  popularUfs(listaRegiao: any) {
    const vm = this;
    vm.limpaTabela();
    if (listaRegiao == null || listaRegiao.length === 0) {
      vm.ufs = [];
      return;
    }
    const arrayList: Array<string> = listaRegiao.map(o => o.id);
    this.webservice.create('ws/publico/ufs', {'regioes': arrayList}, success, fail);
    function success(res) {
      vm.analytics.trackEvent('ContratacoesController', 'onSelectAllReg():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.ufs = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((uf) => {
          if (uf.sigla !== 'MZ' ) {
            vm.ufs.push({
              'id': uf.sigla,
              'itemName': uf.nome,
              'category': uf.regiao
            });
          }
        });
      }
    }
    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ContratacoesController', 'onSelectAllReg():fail');
      let message = 'Falha ao carregar as UF`s.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  onItemSelectReg(item: any) {
    const vm = this;
    this.limpaTabela();
    vm.popularUfs(vm.regioesSelected);
  }

  OnItemDeSelectReg(item: any) {
    const vm = this;
    vm.ufsSelected = vm.ufsSelected.filter(u => u.category !== item.itemName);
    vm.municipiosSelected = vm.municipiosSelected.filter(mmu => vm.ufsSelected.some((usl) => usl.itemName === mmu.category));
    vm.onItemSelectReg(null);
    vm.onItemSelectUf(null);
    vm.limpaTabela();
  }

  onSelectAllReg(items: any) {
    const vm = this;
    vm.limpaTabela();
    vm.popularUfs(vm.regioes);
  }

  onDeSelectAllReg(items: any) {
    this.municipiosSelected = [];
    this.municipios = [];
    this.ufsSelected = [];
    this.ufs = [];
    this.limpaTabela();
  }

  onItemSelectUf(item: any) {
    const vm = this;
    vm.popularMunicipios(vm.ufsSelected);
    this.limpaTabela();
  }

  onItemDeSelectUf(item: any) {
    const vm = this;
    vm.municipiosSelected = vm.municipiosSelected.filter(mmu => vm.ufsSelected.some(usl => usl.itemName === mmu.category));
    vm.onItemSelectUf(null);
    vm.limpaTabela();
  }

  onSelectAllUf(items: any) {
    const vm = this;
    vm.popularMunicipios(vm.ufs);
    vm.limpaTabela();
  }
  onDeSelectAllUf(items: any) {
    this.municipiosSelected = [];
    this.municipios = [];
    this.limpaTabela();
  }
  onGroupDeSelectUf(items: any) {
    this.municipios = [];
    this.onItemSelectUf(null);
    if (items !== null) {
      items.list.forEach((uf, i) => {
        this.onItemDeSelectUf(uf);
      });
    }
  }

  popularMunicipios(listaUf: any) {
    const vm = this;
    if (listaUf == null || listaUf.length === 0) {
      vm.municipios = [];
      return;
    }
    const arrayList: Array<string> = listaUf.map(o => o.id);
    vm.webservice.create('ws/publico/municipios', {'ufs': arrayList}, success, fail);
    function success(res) {
      vm.analytics.trackEvent('ContratacoesController', 'popularMunicipios():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.municipios = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((mun) => {
          vm.municipios.push({
            'id': mun.id,
            'itemName': mun.descricao,
            'category': mun.ufDescricao
          });
        });
      }
    }
    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ContratacoesController', 'popularMunicipios():fail');
      let message = 'Falha ao carregar os municipios.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  public exportarCsv()  {
    const vm = this;
    this.analytics.trackEvent('ContratacoesController', 'exportarCsv()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10) ) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('anoOrcamento', vm.myForm.get('anoOrcamento').value);
    params = params.append('tipoImovel', vm.myForm.get('tipoImovel').value);
    params = params.append('detalheArea', vm.myForm.get('detalhe0').value);
    params = params.append('detalheModa', vm.myForm.get('detalhe3').value);
    params = params.append('detalheProg', vm.myForm.get('detalhe1').value);
    params = params.append('detalheUf', vm.myForm.get('detalhe2').value);
    params = params.append('detalheMun', vm.myForm.get('detalhe4').value);
    params = params.append('detalhePJ', vm.myForm.get('detalhe5').value);
    params = params.append('detalhePF', vm.myForm.get('detalhe6').value);
    params = params.append('detalheRegiao', vm.myForm.get('detalhe7').value);
    params = params.append('detFaixaPrograma', vm.myForm.get('detFaixaPrograma').value);
    const body = {
      'regiao': vm.appService.getListaAlterado(vm.regioesSelected, 'itemName'),
      'uf': vm.appService.getListaAlterado(vm.ufsSelected, 'id'),
      'municipio': vm.appService.getListaAlterado(vm.municipiosSelected, 'itemName'),
      'areaAplicacao': vm.appService.getListaAlterado(vm.areasSelected, 'itemName'),
      'programa': vm.appService.getListaAlterado(vm.programasSelected, 'itemName'),
      'faixaPrograma': vm.appService.getListaAlterado(vm.faixaProgramasSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.postDownload('ws/relatorios/exportarcontratacoescsv', body, 'text/plain', true, 'contratacoes.csv', params);
  }

  /**
   * Retorna os dados da tabela ordenados por uma coluna.
   */
  sortData(sort: Sort) {
    this.tblData = this.appService.sortData(sort, this.tblData);
  }
}
