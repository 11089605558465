import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-input',
  templateUrl: './modal-input.component.html',
  styleUrls: ['./modal-input.component.css']
})
export class ModalInputComponent implements OnInit {

  required = false;
  title: string = null;
  message: string = null;
  tipo: string = null;
  lblInput: string = null;
  inputValue: string = null;

  constructor(public activeModal: NgbActiveModal, public modalConfig: NgbModalConfig) {}

  ngOnInit() {
  }

}
