import { Router } from '@angular/router';
import { Config } from './../environments/config';
import { ApplicationService } from './services/application/application.service';
import { NativeInterfaceService } from './services/native-interface/native-interface.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { CurrentView } from '../environments/currentview';
import { WebServiceService } from './services/web-service/web-service.service';

@Injectable()
export class MyInterceptor implements HttpInterceptor {
    currentview = CurrentView;

    constructor(private nativeInterface: NativeInterfaceService, private router: Router,
                private appService: ApplicationService, private webservice: WebServiceService) {
        //
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const code = this.nativeInterface.getPreference(Config.storageKeys.token, null);
        if (code) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + code) });
        }
        this.currentview.searchbox = !window.location.hash.includes('/arquivos') && this.appService.getCpfUsuario() != null;
        return next.handle(request).pipe(
            tap(
                event => {
                    // logging the http response to browser's console in case of a success
                    if (event instanceof HttpResponse) {
                        // console.log('api call success :', event)
                    }
                },
                error => {
                    // if (event instanceof HttpResponse
                    //     console.log('api call error :', event
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 400) {
                            // this.alertService.showMessage('Falha de autenticação', 'Usuário ou senha inválidos'
                            return this.router.navigate(['login']);
                        }
                    }
                    // Observable.throw(error
                }
            )
        );
    }
}
