export const Project = {
    'name': 'Canal FGTS',
    'description': 'Canal FGTS',
    'footer': {
        'left': 'Caixa Econômica Federal',
        'middle': '',
        'right': 'Canal do FGTS'
    },
    'header': {
        'help': 'SICNL - Canal FGTS',
        'subtitle': '',
        'title': 'Canal do FGTS'
    },
    'owner': 'Caixa Econômica Federal'
};
