import { Injectable } from '@angular/core';

const VERSION = '2.0.0';

@Injectable()
export class NativeInterfaceService {

  constructor() { }

  version(): string {
    return VERSION;
  }

  private _window(): any {
    return window;
  }

  hasSupport() {
    try {
      return this._window().JSInterface;
    } catch (ignore) {
      return false;
    }
  }

  getPreference(key: string, defaultValue: string) {
    let result = defaultValue;
    try {
      result = this._window().JSInterface.getPreference(key, defaultValue);
    } catch (ignore) {

      try {
        result = this._window().localStorage.getItem(key);
      } catch (ignore) {
      }

      if (result == null || result === '' || result === 'null') {
        result = defaultValue;
      }
    }
    return result;
  }

  setPreference(key: string, value: string) {
    try {
      return this._window().JSInterface.setPreference(key, value);
    } catch (ignore) {
      try {
        return this._window().localStorage.setItem(key, value);
      } catch (ignore) {
      }
    }
  }

  clearLocalStorage() {
    return this._window().localStorage.clear();
  }

}
