import { Config } from './../../../../environments/config';
import { NativeInterfaceService } from './../../../services/native-interface/native-interface.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CurrentView } from '../../../../environments/currentview';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { HttpParams } from '@angular/common/http';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { LogService } from '../../../services/log/log.service';
import { ApplicationService } from '../../../services/application/application.service';
declare const $: any;

@Component({
  selector: 'app-menu-principal',
  templateUrl: './menu-principal.component.html',
  styleUrls: ['./menu-principal.component.css']
})
export class MenuPrincipalComponent implements OnInit, AfterViewInit {

  currentview = CurrentView;
  perfil = null;
  acessoMenuInstitucional = false;
  acessoMenuRelatorios = false;
  acessoMenuRelatoriosAtivoFGTS = false;
  acessoMenuRelatoriosPassivoFGTS = false;
  acessoMenuRelatoriosTutorial = false;
  acessoMenuInfoConsolid = false;
  acessoMenuInfoConsolidAplicarRecuperarCreditos = false;
  acessoMenuInfoConsolidArrecadar = false;
  acessoMenuInfoConsolidCadastro = false;
  acessoMenuInfoConsolidPagar = false;
  acessoMenuAdministrativo = false;
  acessoPrincipal = false;
  acessoAtribuicoes = false;
  acessoEstrutura = false;
  acessoHistorico = false;
  acessoArquivos = false;
  acessoContratacoes = false;
  acessoDescontos = false;
  acessoDesembolso = false;
  acessoExecucaoOrcamentaria = false;
  acessoArrecadacaoLiquida = false;
  acessoArrecadacaoConsolidada = false;
  acessoSaquesConsolidados = false;
  acessoTutorial = false;
  acessoRecuperacaoCredito = false;
  acessoArrecadacaoContribuicaoSocial = false;
  acessoArrecadacaoLiquidaAnual = false;
  acessoArrecadacaoLiquidaMensal = false;
  acessoArrecadacaoPrincipaisSetores = false;
  acessoQtdeContasComRecolhimento = false;
  acessoRecolhimentoEmpregadoDomestico = false;
  acessoCadastroContasVinculadas = false;
  acessoDistribuicaoSaldosContasAtivas = false;
  acessoDistribuicaoSaldosContasInativas = false;
  acessoSaquesconectividadesocial = false;
  acessoSaquesmensais = false;
  acessoSaquesporduracaovinculo = false;
  acessoSaquespormodalidade = false;
  acessofaleconosco = false;
  acessotrilhacapacitacao = false;
  acessoCadastrarUsuario = false;
  acessoPesquisarUsuario = false;
  acessoPerfilPesquisar = false;
  acessoPerfilFuncionalidade = false;
  acessoPerfilPasta = false;
  acessomensagemgestor = false;
  acessopesquisarObservacaoFuncionalidade = false;
  acessopalavrachave = false;
  acessopalavrachavefuncionalidade = false;
  acessotermoconfidencialidade = false;
  acessoauditoria = false;
  acessocadastrarUsuario = false;
  acessoSaquesExterior = false;
  acessoGuiaNavegacao = false;
  acessoIndicadorEstrategico = false;

  constructor( nativeInterface: NativeInterfaceService, private analytics: AnalyticsService, private log: LogService,
    private webservice: WebServiceService, private appService: ApplicationService) {
    //
  }

  ngOnInit() {
    this.analytics.trackEvent('controller', 'MenuPrincipalController');
    this.perfil = this.appService.getPerfilUsuario();
    this.executarSubmenu();
    this.acessoPrincipal = this.appService.temAutorizacao('principal');
    this.acessoAtribuicoes = this.appService.temAutorizacao('atribuicoes');
    this.acessoEstrutura = this.appService.temAutorizacao('estrutura');
    this.acessoHistorico = this.appService.temAutorizacao('fgtsHistorico');
    this.acessoMenuInstitucional = this.acessoAtribuicoes || this.acessoEstrutura || this.acessoHistorico;
    this.acessoArquivos = this.appService.temAutorizacao('arquivos');
    this.acessoContratacoes = this.appService.temAutorizacao('contratacoes');
    this.acessoDescontos = this.appService.temAutorizacao('descontos');
    this.acessoDesembolso = this.appService.temAutorizacao('desembolso');
    this.acessoExecucaoOrcamentaria = this.appService.temAutorizacao('execucaoorcamentaria');
    this.acessoArrecadacaoLiquida = this.appService.temAutorizacao('arrecadacaoliquida');
    this.acessoArrecadacaoConsolidada = this.appService.temAutorizacao('arrecadacaoconsolidada');
    this.acessoSaquesConsolidados = this.appService.temAutorizacao('saquesconsolidados');
    this.acessoTutorial = this.appService.temAutorizacao('tutorial');
    this.acessoRecuperacaoCredito = this.appService.temAutorizacao('recuperacaocredito');
    this.acessoArrecadacaoContribuicaoSocial = this.appService.temAutorizacao('arrecadacaocontribuicaosocial');
    this.acessoArrecadacaoLiquidaAnual = this.appService.temAutorizacao('arrecadacaoliquidaanual');
    this.acessoArrecadacaoLiquidaMensal = this.appService.temAutorizacao('arrecadacaoliquidamensal');
    this.acessoArrecadacaoPrincipaisSetores = this.appService.temAutorizacao('arrecadacaoprincipaissetores');
    this.acessoQtdeContasComRecolhimento = this.appService.temAutorizacao('qtdecontascomrecolhimento');
    this.acessoRecolhimentoEmpregadoDomestico = this.appService.temAutorizacao('recolhimentoempregadodomestico');
    this.acessoCadastroContasVinculadas = this.appService.temAutorizacao('cadastrocontasvinculadas');
    this.acessoDistribuicaoSaldosContasAtivas = this.appService.temAutorizacao('distribuicaosaldoscontasativas');
    this.acessoDistribuicaoSaldosContasInativas = this.appService.temAutorizacao('distribuicaosaldoscontasinativas');
    this.acessoSaquesconectividadesocial = this.appService.temAutorizacao('saquesconectividadesocial');
    this.acessoSaquesmensais = this.appService.temAutorizacao('saquesmensais');
    this.acessoSaquesporduracaovinculo = this.appService.temAutorizacao('saquesporduracaovinculo');
    this.acessoSaquespormodalidade = this.appService.temAutorizacao('saquespormodalidade');
    this.acessofaleconosco = this.appService.temAutorizacao('faleconosco');
    this.acessotrilhacapacitacao = this.appService.temAutorizacao('trilhacapacitacao');
    this.acessoCadastrarUsuario = this.appService.temAutorizacao('cadastrarUsuario');
    this.acessoPesquisarUsuario = this.appService.temAutorizacao('pesquisarUsuario');
    this.acessoPerfilPesquisar = this.appService.temAutorizacao('perfilpesquisar');
    this.acessoPerfilFuncionalidade = ( this.perfil === 'AD' || this.perfil === 'AU' );
    this.acessoPerfilPasta = this.appService.temAutorizacao('perfilpasta');
    this.acessomensagemgestor = this.appService.temAutorizacao('mensagemgestor');
    this.acessopesquisarObservacaoFuncionalidade = this.appService.temAutorizacao('pesquisarObservacaoFuncionalidade');
    this.acessopalavrachave = this.appService.temAutorizacao('palavrachave');
    this.acessopalavrachavefuncionalidade = this.appService.temAutorizacao('palavrachavefuncionalidade');
    this.acessotermoconfidencialidade = this.appService.temAutorizacao('termoconfidencialidade');
    this.acessoauditoria = this.appService.temAutorizacao('auditoria');
    this.acessocadastrarUsuario = this.appService.temAutorizacao('cadastrarUsuario');
    this.acessoSaquesExterior = this.appService.temAutorizacao('saquesExterior');
    this.acessoGuiaNavegacao = this.appService.temAutorizacao('guiaNavegacao');
    this.acessoIndicadorEstrategico = this.appService.temAutorizacao('fgtsIndicadorEstrategico');
    this.acessoMenuRelatoriosAtivoFGTS = this.acessoContratacoes || this.acessoDescontos || this.acessoDesembolso || this.acessoExecucaoOrcamentaria;
    this.acessoMenuRelatoriosPassivoFGTS = this.acessoArrecadacaoLiquida || this.acessoArrecadacaoConsolidada || this.acessoSaquesConsolidados;
    this.acessoMenuRelatoriosTutorial = this.acessoTutorial;
    this.acessoMenuRelatorios = this.acessoMenuRelatoriosAtivoFGTS || this.acessoMenuRelatoriosPassivoFGTS || this.acessoMenuRelatoriosTutorial;
    this.acessoMenuInfoConsolidAplicarRecuperarCreditos = this.acessoContratacoes || this.acessoDescontos || this.acessoDesembolso || this.acessoExecucaoOrcamentaria || this.acessoRecuperacaoCredito;
    this.acessoMenuInfoConsolidArrecadar = this.acessoArrecadacaoContribuicaoSocial || this.acessoArrecadacaoLiquidaAnual || this.acessoArrecadacaoLiquidaMensal || this.acessoArrecadacaoPrincipaisSetores || this.acessoQtdeContasComRecolhimento || this.acessoRecolhimentoEmpregadoDomestico;
    this.acessoMenuInfoConsolidCadastro = this.acessoCadastroContasVinculadas || this.acessoDistribuicaoSaldosContasAtivas || this.acessoDistribuicaoSaldosContasInativas;
    this.acessoMenuInfoConsolidPagar = this.acessoSaquesconectividadesocial || this.acessoSaquesmensais || this.acessoSaquesExterior || this.acessoSaquesporduracaovinculo || this.acessoSaquespormodalidade;
    this.acessoMenuInfoConsolid = this.acessoMenuInfoConsolidAplicarRecuperarCreditos || this.acessoMenuInfoConsolidArrecadar || this.acessoMenuInfoConsolidCadastro || this.acessoMenuInfoConsolidPagar;

    this.acessoMenuAdministrativo = this.acessopesquisarObservacaoFuncionalidade
      || this.acessoPesquisarUsuario || this.acessopalavrachave || this.acessopalavrachavefuncionalidade
      || this.acessomensagemgestor || this.acessoauditoria || this.acessotermoconfidencialidade || this.acessoPerfilPesquisar || this.acessoPerfilFuncionalidade || this.acessoPerfilPasta;

  }

  ngAfterViewInit() {
    $(document).ready(function() {
      // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
      // AJUSTES DE MENU * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
      // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

      // () -> Relatórios
      const mnuRelatoriosCols = $('#subMenuRelatorios > div').length;
      if (mnuRelatoriosCols) {
        // console.log('mnuRelatoriosCols -> change' + mnuRelatoriosCols)
        $('.submenu.submenu-relatorios').addClass('change' + mnuRelatoriosCols);
      }

      // () -> Informações Consolidadas
      const mnuInfoConsolidCols = $('#subMenuInfoConsolid > div').length;
      if (mnuInfoConsolidCols) {
        // console.log('mnuInfoConsolidCols -> change' + mnuInfoConsolidCols)
        $('.submenu.submenu-infoConsoli').addClass('change' + mnuInfoConsolidCols);
      }
    });
  }

  doExportData(pasta, chave) {
    const vm = this;
    this.analytics.trackEvent('MenuPrincipalController', 'doExportData()');
    if (pasta === 'publi') {
      chave = Config.PASTA_PUBLI + chave;
    } else {
      chave = Config.PASTA_PUBLICO + chave;
    }
    const params = new HttpParams().append('chave', chave);
    this.webservice.read('ws/arquivos/chave', params, success, null);

    function success(res) {
      vm.analytics.trackEvent('MenuPrincipalController', 'doExportData():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.data.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/pdf'});

      // Abre no PopUp
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
      } else {
        const objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
      }

      linkElement.setAttribute('target', '_blank');
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  executarSubmenu() {
    $(document).ready(function() {
        $('.has-submenu > a').on('click', function(e){
          e.preventDefault();
          const s = $(this).parent().find('.submenu');
          $('.submenu.active').not(s).removeClass('active');
          s.toggleClass('active');
          $('.has-submenu.active').not(s.parent()).removeClass('active');
          $(this).parent().toggleClass('active');
        });

        $('#icoNavegacao').click(function(e){
          if ( !$('#menu-principal').is(':visible') ) {
            $('html, body').animate({scrollTop: 0}, 'slow');
          }
        });

        $('.fa.fa-navicon.fa-fw.fa-2x').click(function(e){
          $('.main-menu').show();
        });

        $(window).on('click', function(e) {
          if (e && e.target && e.target.parentElement) {
            const classes = e.target.parentElement.className;
            const classesIconMenu = e.target.className;
            if (!classes.includes('has-submenu') && !classesIconMenu.includes('iconFaMenu')) {
              $('.submenu.active').removeClass('active');
              $('.has-submenu.active').removeClass('active');
            }
          }
          const tgt = e.target || e.srcElement;
          if ( ($(window).width() <= 768) && ( ( tgt.tagName === 'A' && tgt.rel === 'menu' && tgt.href.includes('#/') ) || (tgt.className === 'fa fa-home') ) ) {
            $('#icoNavegacao').click();
          }
        });
    });
  }
}
