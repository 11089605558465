import { FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

export class FormValidations {

  static REGEX_NIS_PIS_PASEP = '^\\d{3}\\.\\d{5}\\.\\d{2}\\-\\d{1}$';
  static REGEX_CEP = '^\\d{5}\\-\\d{3}$';
  static REGEX_AGENCIA = '^\\d{4}$';
  static REGEX_MATRICULA = '^\([A-z]{1})\\d{6}$';
  static REGEX_EMAIL = '^([a-z0-9_\.-]+)@([\da-z\.-]+)\\.([a-z\.]{2,6})$';
  static REGEX_TELEFONE = '^\\([1-9]{2}\\)[2-9][0-9]{3,4}\\-[0-9]{4}$';
  static REGEX_MOEDA_BRASIL  = '^R\$(\d{1,3}(\.\d{3})*|\d+)(\,\d{2})?$';
  static REGEX_MOEDA_EUA = '/^((?:\d{1,3}\,?)+)(\.\d{1,2})/';
  static REGEX_CONTA = '';

  static cnpjValidator(control: FormControl) {
    const strCNPJ = control.value;
    const padrao = new RegExp('^\\d{2}\\.\\d{3}\\.\\d{3}\\/\\d{4}\\-\\d{2}$');
    if (!padrao.test(strCNPJ.value)) {
      strCNPJ.value = '';
      strCNPJ.focus();
      return false;
    }

    // Retira do valor apenas os dados numericos
    const cnpj = strCNPJ.value.replace(/[^\d]+/g, '');

    // verifica se o cnpj está vazio
    if (cnpj === '') {
      strCNPJ.value = '';
      strCNPJ.focus();
      return false;
    }

    // Verifica se o campo digitado tem o tamanho adequado para um CNPJ
    if (cnpj.length !== 14) {
      strCNPJ.value = '';
      strCNPJ.focus();
      return false;
    }

    // Verifica numeros repetidos no cnpj, para evitar que processe cnpj já invalido
    if (cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999') {

      strCNPJ.value = '';
      strCNPJ.focus();
      return false;

    }

    // Validar o digito verificadore - 01 - do CNPJ
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    // Digito validador invalido
    if (resultado !== digitos.charAt(0)) {
      strCNPJ.value = '';
      strCNPJ.focus();
      return false;
    }

    // Validar o digito verificadore - 02 - do CNPJ
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    // Digito validador invalido
    if (resultado !== digitos.charAt(1)) {
      strCNPJ.value = '';
      strCNPJ.focus();
      return false;
    }

    return true;
  }

  static cpfValidator(control: FormControl) {
    const strCPF = control.value;
    const isInvalid = { cpfInvalido: true };
    if (strCPF && strCPF !== '') {
      const padrao = new RegExp('^\\d{3}\\.\\d{3}\\.\\d{3}\\-\\d{2}$');
      if (!padrao.test(strCPF)) {
        return isInvalid;
      }

      // Retira do valor apenas os númericos para efetuar o calculo
      const cpf = strCPF.replace(/[^\d]+/g, '');

      // Verifica se o campo está vazio
      if (cpf === '') {
        return isInvalid;
      }

      // Verifica numeros repetidos no cpf, para evitar que processe cpf já invalido
      if (cpf.length !== 11 ||
        cpf === '00000000000' ||
        cpf === '11111111111' ||
        cpf === '22222222222' ||
        cpf === '33333333333' ||
        cpf === '44444444444' ||
        cpf === '55555555555' ||
        cpf === '66666666666' ||
        cpf === '77777777777' ||
        cpf === '88888888888' ||
        cpf === '99999999999') {
        return isInvalid;
      }

      // Valida 1o digito verificador
      let add = 0;
      for (let i = 0; i < 9; i ++) {
        add += parseInt(cpf.charAt(i), 10) * (10 - i);
      }
      let rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) {
        rev = 0;
      }
      if (rev !== parseInt(cpf.charAt(9), 10)) {
        return isInvalid;
      }

      // Valida 2o digito verificador
      add = 0;
      for (let i = 0; i < 10; i ++) {
        add += parseInt(cpf.charAt(i), 10) * (11 - i);
      }
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) {
        rev = 0;
      }
      if (rev !== parseInt(cpf.charAt(10), 10)) {
        return isInvalid;
      }

      return null;
    }
    return null;
  }

  static getErrorMsg(fieldName: string, validatorName: string, validatorValue?: any) {
    const config = {
      'required': `${fieldName} é obrigatório.`,
      'minlength': `${fieldName} precisa ter no mínimo ${validatorValue.requiredLength} caracteres.`,
      'maxlength': `${fieldName} precisa ter no máximo ${validatorValue.requiredLength} caracteres.`,
      'cpfInvalido': 'CPF inválido.',
      'emailInvalido': 'Email já cadastrado!',
      'equalsTo': 'Campos não são iguais',
      'pattern': 'Campo inválido'
    };

    return config[validatorName];
  }
}
