import { Component, OnInit, ViewChild } from '@angular/core';
import { LogService } from '../../../services/log/log.service';
import { Config } from '../../../../environments/config';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { CurrentView } from '../../../../environments/currentview';
import { ApplicationService } from '../../../services/application/application.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { GeolocationService } from '../../../services/geolocation/geolocation.service';
import { AlertService } from '../../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { NativeInterfaceService } from '../../../services/native-interface/native-interface.service';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { MatSort, Sort } from '@angular/material';

@Component({
  selector: 'app-saquesconsolidados',
  templateUrl: './saquesconsolidados.component.html',
  styleUrls: ['./saquesconsolidados.component.css']
})
export class SaquesConsolidadosComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private geolocation: GeolocationService,
    private router: Router, private nativeInterface: NativeInterfaceService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;

  criterio = '7';
  tblData = [];
  filtrotbl = null;
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;
  periodos = [];
  exibirTabela = false;
  //
  tipoSaque = [];
  timer = null;
  dataAtual = new Date();
  totQuantidade = null;
  totValor = null;
  dtPosicao: '';
  ordemPeriodoResultado = 'asc';

  native: NativeInterfaceService;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';

  multSelDropdownSettings = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  tiposSelected = [];
  observacao = null;
  public p: number;
  dtPosicaoFormatada: string = null;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.log.info('SaquesConsolidadosComponent.init()');
    this.analytics.trackEvent('controller', 'SaquesConsolidadosController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      id: null,
      tiposaque: [ null ],
      periodo: [ 'M' ],
      periodoInicial: [ '' ],
      periodoFinal: [ '' ],
      detalhe1: null,
      ordemPeriodoResultado: 'C'
    });
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.initFavorite();
    this.popularCampos();
    this.p = 1;
    const vm = this;
    this.ordemPeriodoResultado = 'asc';
    jQuery(function() {
      $('#optDetMarcar').on('click', function(){
        $(':checkbox[name^=\'detalhe\']').each(function(){
          vm.myForm.get(this.id).setValue(true);
          vm.limpaTabela();
        });
      });
      $('#optDetDesmarcar').on('click', function(){
        $(':checkbox[name^=\'detalhe\']').each(function(){
          vm.myForm.get(this.id).setValue(false);
          vm.limpaTabela();
        });
      });
    });
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('RelatoriosaquesconsolidadosController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('RelatoriosaquesconsolidadosController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('RelatoriosaquesconsolidadosController', 'initFavorite():error');
      let message = 'Falha ao configurar favorito RelatoriosaquesconsolidadosController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  doFavorite() {
    const vm = this;
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('RelatoriosaquesconsolidadosController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('RelatoriosaquesconsolidadosController', 'favoritos():error');
      let message = 'Falha ao atualizar a funcionalidade como favorita.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  datatbl2Reset() {
    this.numberPagetbl2Selected = 0;
    this.numberPagetbl2 = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl2));
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('SaquesConsolidadosController', 'listar()');
    this.filtrotbl = null;
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (Number(vm.myForm.get('periodoInicial').value) > Number(vm.myForm.get('periodoFinal').value)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }

    let params = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheUf', '');
    params = params.append('detalheSaque', vm.myForm.get('detalhe1').value);
    const body = {
      'uf': 'Todas',
      'tipoSaque': vm.appService.getListaAlterado(vm.tiposSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.p = 1;
    vm.webservice.create('ws/relatorios/saquesconsolidados', body, success, fail, params);

    function success(res) {
      vm.analytics.trackEvent('SaquesConsolidadosController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      vm.observacao = null;
      vm.dataAtual = new Date();
      if (res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          vm.tblData.push({
            'periodo': element.periodo,
            'uf': element.uf,
            'codigo': element.codigoSaque,
            'saque': element.descricaoSaque,
            'quantidade': element.quantidade,
            'valor': element.valor
          });
        });
      }
      vm.totQuantidade = res.totQuantidade;
      vm.totValor = res.totValor;
      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.numberPagetbl2Selected = 0;
      vm.exibirTabela = true;
      if (res.observacao !== null) {
        vm.observacao = vm.appService.decodeUri(res.observacao);
        setTimeout(() => {
          const divobs = document.getElementById('observacao');
          divobs.innerHTML = vm.observacao;
        }, 250);
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('SaquesConsolidadosController', 'listar():error');
      let message = 'Falha ao listar SaquesConsolidadosController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      id: null,
      tiposaque: null,
      periodo: 'M',
      periodoInicial: '',
      periodoFinal: '',
      detalhe1: null,
      ordemPeriodoResultado: 'C'
    });
    this.tblData = [];
    this.exibirTabela = false;
    this.popularCampos();
    this.ordemPeriodoResultado = 'asc';
  }

  limpaTabela() {
    this.exibirTabela = false;
  }

  popularCampos() {
    const vm = this;
    this.analytics.trackEvent('SaquesConsolidadosController', 'popularCampos()');

    // ------------------------------------------------------------------------
    // desabilitar botoes de tipo de periodo (mensal/anual)
    // ------------------------------------------------------------------------
    const elMes = <HTMLInputElement> document.getElementById('optPeriodoMensal');
    elMes.disabled = true;
    const elAno = <HTMLInputElement> document.getElementById('optPeriodoAnual');
    elAno.disabled = true;
    // ------------------------------------------------------------------------

    const entrada = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    this.webservice.read('ws/relatorios/saquesconsolidados/filtros', entrada, success, fail);

    function success(res) {
      vm.analytics.trackEvent('SaquesConsolidadosController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.periodos = [];
      vm.tipoSaque = [];
      vm.tiposSelected = [];
      if (res.data && res.data.length > 0) {
        vm.dtPosicao = res.dtPosicao;
        vm.dtPosicaoFormatada = moment(vm.dtPosicao).format('DD/MM/YYYY');
        vm.periodos.push({
          'periodo': '',
          'periodofmt': 'Escolha o Período'
        });
        res.data.forEach((filtros) => {
          filtros.tipoSaque.forEach((dataSq) => {
            vm.tipoSaque.push({
              'id': dataSq.codigo,
              'itemName': dataSq.codigo + ' - ' + dataSq.saque
            });
          });
          filtros.periodosInicial.forEach((dataPer) => {
            if (vm.myForm.get('periodo').value === 'M') {
              vm.periodos.push({
                'periodo': dataPer.periodo,
                'periodofmt': dataPer.periodo.substring(4, 6) + '/' + dataPer.periodo.substring(0, 4)
              });
            } else {
              vm.periodos.push({
                'periodo': dataPer.periodo,
                'periodofmt': dataPer.periodo
              });
            }
          });
        });
      }
      elMes.disabled = false;
      elAno.disabled = false;
    }

    function fail(xhr, status, err) {
      elMes.disabled = false;
      elAno.disabled = false;
      vm.analytics.trackEvent('SaquesConsolidadosController', 'popularCampos():error');
      let message = 'Falha ao popular campos SaquesConsolidadosController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  carregaperiodo(per) {
    this.myForm.patchValue({
      periodoInicial: null,
      periodoFinal: null,
    });
    this.myForm.get('periodo').setValue(per);
    this.popularPeriodo();
    this.tblData = [];
    this.exibirTabela = false;
  }

  popularPeriodo() {
    const vm = this;
    this.analytics.trackEvent('SaquesConsolidadosController', 'popularPeriodo()');

    // ------------------------------------------------------------------------
    // desabilitar botoes de tipo de periodo (mensal/anual)
    // ------------------------------------------------------------------------
    const elMes = <HTMLInputElement> document.getElementById('optPeriodoMensal');
    elMes.disabled = true;
    const elAno = <HTMLInputElement> document.getElementById('optPeriodoAnual');
    elAno.disabled = true;
   // ------------------------------------------------------------------------

    const entrada = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    this.webservice.read('ws/publico/saquesconsolidados/periodo', entrada, success, fail);

    function success(res) {
      vm.analytics.trackEvent('SaquesConsolidadosController', 'popularPeriodo():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.periodos = [];
      if (res.data && res.data.length > 0) {
        vm.periodos.push({
          'periodo': '',
          'periodofmt': 'Escolha o Período'
        });
        res.data.forEach((filtros) => {
          filtros.periodosInicial.forEach((dataPer) => {
            if (vm.myForm.get('periodo').value === 'M') {
              vm.periodos.push({
                'periodo': dataPer.periodo,
                'periodofmt': dataPer.periodo.substring(4, 6) + '/' + dataPer.periodo.substring(0, 4)
              });
            } else {
              vm.periodos.push({
                'periodo': dataPer.periodo,
                'periodofmt': dataPer.periodo
              });
            }
          });
        });
      }
      elMes.disabled = false;
      elAno.disabled = false;
    }

    function fail(xhr, status, err) {
      elMes.disabled = false;
      elAno.disabled = false;
      vm.analytics.trackEvent('SaquesConsolidadosController', 'popularPeriodo():error');
      let message = 'Falha ao popular campos SaquesConsolidadosPubController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  public imprimir()  {
    const vm = this;
    this.analytics.trackEvent('SaquesConsolidadosController', 'imprimir()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (Number(vm.myForm.get('periodoInicial').value) > Number(vm.myForm.get('periodoFinal').value)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheUf', '');
    params = params.append('detalheSaque', vm.myForm.get('detalhe1').value);
    params = params.append('descTipoSaque', vm.appService.getListaAlterado(vm.tiposSelected, 'itemName'));
    params = params.append('dataPosicao', vm.dtPosicaoFormatada);
    const body = {
      'uf': 'Todas',
      'tipoSaque': vm.appService.getListaAlterado(vm.tiposSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.create('ws/relatorios/imprimirsaquesconsolidados', body, success, null, params);

    function success(res) {
      vm.analytics.trackEvent('SaquesConsolidadosController', 'imprimir():success');
      const filename = 'SaquesConsolidados.pdf';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  public exportar()  {
    const vm = this;
    this.analytics.trackEvent('SaquesConsolidadosController', 'exportar()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (Number(vm.myForm.get('periodoInicial').value) > Number(vm.myForm.get('periodoFinal').value)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheUf', '');
    params = params.append('detalheSaque', vm.myForm.get('detalhe1').value);
    params = params.append('descTipoSaque', vm.appService.getListaAlterado(vm.tiposSelected, 'itemName'));
    params = params.append('dataPosicao', vm.dtPosicaoFormatada);
    const body = {
      'uf': 'Todas',
      'tipoSaque': vm.appService.getListaAlterado(vm.tiposSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.create('ws/relatorios/exportarsaquesconsolidados', body, success, null, params);

    function success(res) {
      vm.analytics.trackEvent('SaquesConsolidadosController', 'exportar():success');
      const filename = 'SaquesConsolidados.xls';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/xls'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  onItemSelect(item: any) {
    this.limpaTabela();
  }
  OnItemDeSelect(item: any) {
    this.limpaTabela();
  }
  onSelectAll(items: any) {
    this.limpaTabela();
  }
  onDeSelectAll(items: any) {
    this.limpaTabela();
  }

  ordenarResultado(val: string) {
    this.myForm.get('ordemPeriodoResultado').setValue(val);
    this.ordemPeriodoResultado = val === 'D' ? 'desc' : 'asc';
  }

  exportarCsv()  {
    const vm = this;
    this.analytics.trackEvent('SaquesConsolidadosController', 'exportarCsv()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheTipo', vm.myForm.get('detalhe1').value);
    const body = {
      'tipoSaque': vm.appService.getListaAlterado(vm.tiposSelected, 'id'),
      'ordemPeriodoResultado': vm.myForm.get('ordemPeriodoResultado').value
    };
    vm.webservice.postDownload('ws/relatorios/exportarsaquesconsolidadoscsv', body, 'text/plain', true, 'saquesconsolidados.csv', params);
  }

  /**
   * Retorna os dados da tabela ordenados por uma coluna.
   */
  sortData(sort: Sort) {
    this.tblData = this.appService.sortData(sort, this.tblData);
  }
}
