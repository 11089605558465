import { Component, OnInit } from '@angular/core';
import { LogService } from '../../services/log/log.service';
import { Config } from '../../../environments/config';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CurrentView } from '../../../environments/currentview';
import { ApplicationService } from '../../services/application/application.service';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { GeolocationService } from '../../services/geolocation/geolocation.service';
import { AlertService } from '../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NativeInterfaceService } from './../../services/native-interface/native-interface.service';

@Component({
  selector: 'app-mensagemgestor',
  templateUrl: './mensagemGestor.component.html',
  styleUrls: ['./mensagemGestor.component.css'],

})
export class MensagemGestorComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService, private router: Router,
    private alertService: AlertService, private geolocation: GeolocationService, private nativeInterface: NativeInterfaceService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;

  perfilContent = [];
  tblData = [];
  tblcontentFunctionClass = ['fa-pencil-square-o', 'fa-trash-o'];

  //
  p: number;
  timer = null;

  filtrotbl = null;
  fulltbl2Data = [];
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;

  exibirTabela = false;

  native: NativeInterfaceService;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';
  acessoInclusao = false;
  acessoAlteracao = false;
  acessoExclusao = false;

  ngOnInit() {
    this.log.info('MensagemGestorComponent.init()');
    this.analytics.trackEvent('controller', 'MensagemGestorController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({});
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.acessoInclusao = this.appService.temAutorizacao('mensagemgestor', 'I');
    this.acessoAlteracao = this.appService.temAutorizacao('mensagemgestor', 'A');
    this.acessoExclusao = this.appService.temAutorizacao('mensagemgestor', 'E');
    this.initFavorite();
    this.listar();
    this.p = 1;
  }

  datatbl2Reset() {
    this.numberPagetbl2Selected = 0;
    this.numberPagetbl2 = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl2));
  }

  getPlatforms(platforms) {
    return '';
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('MensagemGestorController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('MensagemGestorController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('MensagemGestorController', 'initFavorite():error');
      let message = 'Falha ao configurar Favorito MensagemGestorController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  doFavorite() {
    const vm = this;

    // verificar operacao
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('MensagemGestorController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('FaleconoscoController', 'favoritos():error');
      let message = 'Falha ao atualizar o pesquisa usuario como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('MensagemGestorController', 'listar()');

    function success(res) {
      vm.analytics.trackEvent('MensagemGestorController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((e) => {
          vm.tblData.push({
            'id': e.id,
            'titulo': e.titulo,
            'mensagem': e.mensagem,
            'dataInicial': e.dataInicial,
            'dataFinal': e.dataFinal,
            'destaque': e.destaque,
            'exibicao': e.exibicao,
            'status': e.status,
            'statusDescricao': e.statusDescricao
          });
        });
      }

      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      vm.numberPagetbl2Selected = 0;
      vm.exibirTabela = true;

    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('MensagemGestorController', 'listar():success');
      let message = 'Falha ao listar os registros.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      message = (status === 404) && err ? err : xhr;
      vm.log.error(message);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Atenção', message);
    }

    vm.p = 1;
    vm.webservice.read('ws/mensagemGestor', null, success, fail);
  }

  incluir() {
    const vm = this;
    vm.analytics.trackEvent('MensagemGestorController', 'incluir()');
    vm.appService.openPage('mensagemgestorcadastro');
  }

  excluir(id) {
    const vm = this;
    vm.analytics.trackEvent('MensagemGestorController', 'excluir()');

    function success(res) {
      vm.analytics.trackEvent('MensagemGestorController', 'excluir():success');
      if (res.temErro) {
        const msg = 'Ocorreu um erro ao excluir a mensagem - ' + res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        vm.alertService.showMessage('Atenção', 'Mensagem excluída com sucesso.');
        vm.listar();
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('MensagemGestorController', 'excluir():fail');
      let message = 'Falha ao excluir a mensagem';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
    }

    function confirm() {
      vm.analytics.trackEvent('MensagemGestorController', 'confirm()');
      vm.log.info('Exclusão confirmada. Executando exclusao no backend.');

      vm.webservice.delete('ws/mensagemGestor/' + id, null, success, fail);
    }

    function deny() {
      vm.analytics.trackEvent('MensagemGestorController', 'deny()');
      vm.log.info('Exclusão cancelada pelo usuário.');
    }

    vm.alertService.showConfirm('Atenção', 'A exclusão desta notificação implica na remoção de todos os itens desta mensagem que ainda não foram visualizados pelos usuários do sistema. Essa ação não poderá ser revertida, confirma essa ação?', confirm, deny);
  }
}
