import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '../../services/log/log.service';

const VERSION = '2.0.0';

@Injectable()
export class WebResourceService {

  constructor(private http: HttpClient, private log: LogService) { }

  version(): string {
    return VERSION;
  }

  get(request: string, success, fail) {
    this.http.get(request)
    .subscribe(data => {
      if (success && data && data['_body']) {
        success(data['_body']);
      }
    });
  }
}
