import { NativeInterfaceService } from './../native-interface/native-interface.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { LogService } from '../../services/log/log.service';
import { AlertService } from '../../services/alert/alert.service';
import { ApplicationService } from '../../services/application/application.service';
import { Config } from '../../../environments/config';
import { environment } from '../../../environments/environment';
import { saveAs } from 'file-saver';

const VERSION = '2.1.0';

@Injectable()
export class WebServiceService {

  constructor(private http: HttpClient, private log: LogService,
              private alertService: AlertService, private applicationService: ApplicationService,
              private nativeInterface: NativeInterfaceService) {
    //
  }

  version(): string {
    return VERSION;
  }

  createHeader(appjson) {
    let header = new HttpHeaders();
    if (appjson) {
      header = header.append('Content-Type', 'application/json');
    }
    header = header.append('Accept', 'application/json');
    const token = this.nativeInterface.getPreference(Config.storageKeys.token, null);
    if (token) {
      header = header.append(Config.AUTHORIZATION, token);
    }
    header = header.append(Config.KEYPASS, Config.KEYPASSVAL);
    return header;
  }

  createHeaderDownload(contentType: string) {
    let header = new HttpHeaders();
    header = header.append('Content-Type', contentType);
    header = header.append('Accept', 'application/octet-stream');
    const token = this.nativeInterface.getPreference(Config.storageKeys.token, null);
    if (token) {
      header = header.append(Config.AUTHORIZATION, token);
    }
    header = header.append(Config.KEYPASS, Config.KEYPASSVAL);
    return header;
  }

  createHeaderForAnalytics() {
    let header = new HttpHeaders();
    header = header.append('Cache-Control', 'no-cache');
    header = header.append('Cache-Control', 'no-store');
    header = header.append('Pragma', 'no-cache');
    header = header.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    // header = header.append('timeout', '600000')
    return header;
  }

  getEndpointBackend() {
    let ept = `${environment.endpoint}`;
    const hostname = window.location.hostname;
    if (hostname === 'localhost') {
      if (ept == null || ept === '') {
        ept = 'http://localhost:8080/sicnl/';
      }
    }
    return ept;
  }

  read(request: string, options, success, fail) {
    const vm = this;
    const headers = vm.createHeader(true);
    this.http.get(vm.getEndpointBackend() + `${request}`, {
      headers: headers,
      params: options
    }).subscribe( data => {
        if (success) {
          success(data);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.log.error(err.error.message, '1 Client-side error occured.');
          if (fail) {
            fail(err.error.message);
          }
        } else {
          if (err.error && err.error.msgsErro && err.error.msgsErro.length > 0) {
            this.log.error(err.error.msgsErro[0], 'Erro no servidor.');
            fail(err.message, err.status, err.error.msgsErro[0]);
            return;
          }
          this.log.error(err.message, '2 Server-side error occured.');
          if (fail) {
            fail(err.message, err.status);
          }
        }
      });
  }

  getObservable(request: string, options) {
    const vm = this;
    const headers = vm.createHeader(true);
    return this.http.get(vm.getEndpointBackend() + `${request}`, {
      headers: headers,
      params: options
    });
  }

  getAnalytics(request: string, options, success, fail) {
    const vm = this;
    this.http.get(`${request}`, options ? options : { headers: vm.createHeaderForAnalytics() })
      .subscribe(data => {
        if (success) {
          success(data);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.log.error(err.error.message, 'Client-side error occured.');
          if (fail) {
            fail(err.error.message);
          }
        } else {
          this.log.error(err.message, 'Server-side error occured.');
          if (fail) {
            fail(err.message, err.status);
          }
        }
      });
  }

  params() {
    return new HttpParams();
  }

  post(request: string, body, success, fail) {
    const vm = this;
    const headers = vm.createHeader(!(body instanceof FormData));

    this.http.post(vm.getEndpointBackend() + `${request}`, body, {headers})
      .subscribe(data => {
        if (success) {
          success(data);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.log.error(err.error.message, 'Client-side error occured.');
          if (fail && fail.error) {
            fail(err.error.message);
          }
        } else {
          if (err.status === 400) {
            if (fail) {
              fail(err.error.msgsErro[0], err.status);
            }
            return;
          }
          this.log.error(err.message, 'Server-side error occured.');
          if (fail) {
            if (err.error && err.error.msgsErro && err.error.msgsErro.length > 0) {
              this.log.error(err.error.msgsErro[0], 'Erro no servidor.');
              fail(err.message, err.status, err.error.msgsErro[0]);
              return;
            }
            fail(err.message, err.status);
          }
        }

      });
  }

  postAnalytics(request: string, body, params, success, fail) {
    const vm = this;
    this.http.post(`${request}`, body,  params ? params : { headers: vm.createHeaderForAnalytics() })
      .subscribe(data => {
        if (data && data['code'] === 603) {
          this.alertService.showWarning('Atenção', 'Sessão expirada. Reiniciando...', 0, 3000);
          this.applicationService.openPage('/');
          return;
        }
        if (success) {
          success(data);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.log.error(err.error.message, 'Client-side error occured.');
          if (fail) {
            fail(err.error.message);
          }
        } else {
          this.log.error(err.message, 'Server-side error occured.');
          if (fail) {
            fail(err.message, err.status);
          }
        }

      });
  }

  put(request: string, body, options, success, fail) {
    const vm = this;
    const headers = vm.createHeader(true);
    this.http.put(vm.getEndpointBackend() + `${request}`, body, {
      headers: headers,
      params: options
    }).subscribe( data => {
        if (success) {
          success(data);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.log.error(err.error.message, 'Client-side error occured.');
          if (fail && fail.error) {
            fail(err.error.message);
          }
        } else {
          if (err.status === 400) {
            if (fail) {
              let msg = '';
              if (err && err.error && err.error.msgsErro) {
                msg = err.error.msgsErro[0];
              }
              fail(msg, err.status);
            }
            return;
          }
          this.log.error(err.message, 'Server-side error occured.');
          if (fail) {
            if (err.error && err.error.msgsErro && err.error.msgsErro.length > 0) {
              this.log.error(err.error.msgsErro[0], 'Erro no servidor.');
              fail(err.message, err.status, err.error.msgsErro[0]);
              return;
            }
            fail(err.message, err.status);
          }
        }
      });
  }

  delete(request: string, options, success, fail) {
    const vm = this;
    const headers = vm.createHeader(true);
    this.http.delete(vm.getEndpointBackend() + `${request}`, {
      headers: headers,
      params: options
    }).subscribe( data => {
        if (success) {
          success(data);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.log.error(err.error.message, 'Client-side error occured.');
          if (fail && fail.error) {
            fail(err.error.message);
          }
        } else {
          if (err.status === 400) {
            if (fail) {
              fail(err.error.msgsErro[0], err.status);
            }
            return;
          }
          this.log.error(err.message, 'Server-side error occured.');
          if (fail) {
            if (err.error && err.error.msgsErro && err.error.msgsErro.length > 0) {
              this.log.error(err.error.msgsErro[0], 'Erro no servidor.');
              fail(err.message, err.status, err.error.msgsErro[0]);
              return;
            }
            fail(err.message, err.status);
          }
        }

      });
  }

  deleteObservable(request: string, options) {
    const vm = this;
    const headers = vm.createHeader(true);
    return this.http.delete(vm.getEndpointBackend() + `${request}`, {
      headers: headers,
      params: options
    });
  }

  create(request: string, body, success, fail, params?: HttpParams) {
    const vm = this;
    const headers = vm.createHeader(!(body instanceof FormData));
    this.http.post(vm.getEndpointBackend() + `${request}`, body, {
      headers: headers,
      params: params
    }).subscribe(data => {
        if (success) {
          success(data);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.log.error(err.error.message, 'Client-side error occured.');
          if (fail && fail.error) {
            fail(err.error.message);
          }
        } else {
          if (err.status === 400) {
            if (fail) {
              let msg = '';
              if (err && err.error && err.error.msg) {
                msg = err.error.msg;
              } else if (err && err.error && err.error.msgsErro && err.error.msgsErro.length > 0) {
                msg = err.error.msgsErro[0];
              }
              fail(msg, err.status);
            }
            return;
          }
          this.log.error(err.message, 'Server-side error occured.');
          if (fail) {
            if (err.error && err.error.msg && err.error.msg.length > 0) {
              this.log.error(err.error.msg, 'Erro no servidor.');
              fail(err.message, err.status, err.error.msg);
              return;
            }
            if (err.error && err.error.msgsErro && err.error.msgsErro.length > 0) {
              this.log.error(err.error.msgsErro[0], 'Erro no servidor.');
              fail(err.message, err.status, err.error.msgsErro[0]);
              return;
            }
            fail(err.message, err.status);
          }
        }

      });
  }

  createObservable(request: string, body) {
    const vm = this;
    const headers = vm.createHeader(!(body instanceof FormData));
    return this.http.post(vm.getEndpointBackend() + `${request}`, body, {headers});
  }

  update(request: string, body, options, success, fail) {
    this.put(request, body, options, success, fail);
  }

  deleta(request: string, body, options, success, fail) {
    this.delete(request, options, success, fail);
  }

  getDownload(url: string, options?: HttpParams, filename?: string) {
    const vm = this;
    const headers = vm.createHeaderDownload('application/json');
    this.http.get(vm.getEndpointBackend() + `${url}`, {
      headers: headers,
      responseType: 'blob',
      params: options,
    }).subscribe( data => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.log.error(err.error.message, '1 Client-side error occured.');
        } else {
          if (err.error && err.error.msgsErro && err.error.msgsErro.length > 0) {
            this.log.error(err.error.msgsErro[0], 'Erro no servidor.');
            return;
          }
          this.log.error(err.message, '2 Server-side error occured.');
        }
      });
  }

  postDownload(url: string, body: any, contentType: string, selfPage: boolean, filename: string, params?: HttpParams) {
    const vm = this;
    const headers = vm.createHeaderDownload('application/json');
    this.http.post(vm.getEndpointBackend() + `${url}`, body, {
      headers: headers,
      responseType: 'blob',
      params: params
    }).subscribe(data => {
      if (selfPage) {
        saveAs(data, filename);
      } else {
        const blob = new Blob([data], { type: contentType });
        const fileUrl = URL.createObjectURL(blob);
        window.open(fileUrl, '_blank');
      }
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        this.log.error(err.error.message, '1 Client-side error occured.');
      } else {
        if (err.error && err.error.msgsErro && err.error.msgsErro.length > 0) {
          this.log.error(err.error.msgsErro[0], 'Erro no servidor.');
          return;
        }
        this.log.error(err.message, '2 Server-side error occured.');
      }
    });
  }

  getDownloadPdf(url: string, options: HttpParams, success) {
    const vm = this;
    const headers = vm.createHeaderDownload('application/json');
    this.http.get(vm.getEndpointBackend() + `${url}`, {
      headers: headers,
      responseType: 'blob',
      params: options,
    }).subscribe(data => {
      if (success) {
        const blob = new Blob([data], { type: 'application/pdf' });
        success(URL.createObjectURL(blob));
      }
    },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.log.error(err.error.message, '1 Client-side error occured.');
        } else {
          if (err.error && err.error.msgsErro && err.error.msgsErro.length > 0) {
            this.log.error(err.error.msgsErro[0], 'Erro no servidor.');
            return;
          }
          this.log.error(err.message, '2 Server-side error occured.');
        }
      });
  }
}
