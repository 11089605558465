import { Component, OnInit, ViewChild } from '@angular/core';
import { LogService } from '../../../services/log/log.service';
import { Config } from '../../../../environments/config';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { CurrentView } from '../../../../environments/currentview';
import { ApplicationService } from '../../../services/application/application.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { GeolocationService } from '../../../services/geolocation/geolocation.service';
import { AlertService } from '../../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { NativeInterfaceService } from '../../../services/native-interface/native-interface.service';
import { MatSort, Sort } from '@angular/material';

@Component({
  selector: 'app-arrecadacaoliquida',
  templateUrl: './arrecadacaoliquida.component.html',
  styleUrls: ['./arrecadacaoliquida.component.css']
})
export class ArrecadacaoLiquidaComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private geolocation: GeolocationService,
    private router: Router, private nativeInterface: NativeInterfaceService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;

  criterio = '7';
  tblData = [];
  filtrotbl = null;
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  numberPagetbl2 = [];
  periodos = [];
  exibirTabela = false;
  //
  ufs = [];
  timer = null;
  dataAtual = new Date();
  totArrecadacaoValor = null;
  totSaqueValor = null;
  totArrecadacaoLiq = null;
  dtPosicao: '';
  native: NativeInterfaceService;
  idUsuario = null;
  isFavorito = false;
  idFavorito = 0;
  idItemFavorito = 0;
  caminho = '';
  ordemPeriodoResultado = 'asc';

  multSelDropdownSettings = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  multSelDropdownSettingsGroupBy = {
    singleSelection: false,
    text: 'Selecione uma ou mais opções',
    selectAllText: 'Marcar Todas',
    unSelectAllText: 'Desmarcar Todas',
    enableSearchFilter: true,
    badgeShowLimit: 3,
    classes: 'dropdownmultiple',
    groupBy: 'category',
    searchPlaceholderText: 'Buscar',
    noDataLabel: 'Nenhum dado localizado.'
  };

  ufsSelected = [];
  pageSelected = 0;
  observacao = null;
  public p: number;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.log.info('ArrecadacaoLiquidaComponent.init()');
    this.analytics.trackEvent('controller', 'ArrecadacaoLiquidaController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      id: null,
      uf: [ 'Todas' ],
      periodo: [ 'M' ],
      periodoInicial: [ '' ],
      periodoFinal: [ '' ],
      detalhe2: null,
      ordemPeriodoResultado: 'C'
    });
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.initFavorite();
    this.pageSelected = 0;
    this.popularCampos();
    this.p = 1;
    this.ordemPeriodoResultado = 'asc';
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('RelatorioarrecadacaoliquidaController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('RelatorioarrecadacaoliquidaController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('RelatorioarrecadacaoliquidaController', 'initFavorite():error');
      let message = 'Falha ao configurar favorito RelatorioarrecadacaoliquidaController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  doFavorite() {
    const vm = this;

    // verificar operacao
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('RelatorioarrecadacaoliquidaController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('RelatorioarrecadacaoliquidaController', 'favoritos():error');
      let message = 'Falha ao atualizar a funcionalidade como favorita.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }

  datatbl2Reset() {
    this.numberPagetbl2 = Array(Math.ceil(this.tblData.length / this.itemsOnPagetbl2));
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoLiquidaController', 'listar()');
    this.filtrotbl = null;
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    vm.p = 1;

    let params = new HttpParams().append('uf', vm.getLista(vm.ufsSelected, 'itemName'));
    params = params.append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheUf', vm.myForm.get('detalhe2').value);
    params = params.append('ordemPeriodoResultado', vm.myForm.get('ordemPeriodoResultado').value);
    this.webservice.read('ws/relatorios/arrecadacaoliquida', params, success, fail);

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoLiquidaController', 'listar():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      vm.observacao = null;
      vm.dataAtual = new Date();
      if (res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          vm.tblData.push({
            'uf': element.uf,
            'periodo': element.periodo,
            'arrecadacaoBruta': element.arrecadacaoValor,
            'saque': element.saqueValor,
            'arrecadacaoLiq': element.arrecadacaoValor - element.saqueValor
          });
        });
      }
      vm.totArrecadacaoValor = res.totArrecadacaoValor;
      vm.totSaqueValor = res.totSaqueValor;
      vm.totArrecadacaoLiq = res.totArrecadacaoLiq;
      vm.numberPagetbl2 = Array(Math.ceil(vm.tblData.length / vm.itemsOnPagetbl2));
      if (res.observacao !== null) {
        vm.observacao = vm.appService.decodeUri(res.observacao);
        setTimeout(() => {
          const divobs = document.getElementById('observacao');
          divobs.innerHTML = vm.observacao;
        }, 250);
      }
      vm.exibirTabela = true;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArrecadacaoLiquidaController', 'listar():error');
      let message = 'Falha ao listar ArrecadacaoLiquidaController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      id: null,
      uf: 'Todas',
      periodo: 'M',
      periodoInicial: '',
      periodoFinal: '',
      detalhe2: null,
      ordemPeriodoResultado: 'C'
    });
    this.popularCampos();
    this.tblData = [];
    this.exibirTabela = false;
    this.observacao = null;
    this.ordemPeriodoResultado = 'asc';
  }

  limpaTabela() {
    this.exibirTabela = false;
  }

  popularCampos() {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoLiquidaController', 'popularCampos()');

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoLiquidaController', 'popularCampos():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.ufs = [];
      vm.ufsSelected = [];
      vm.periodos = [];
      if (res.data && res.data.length > 0) {
        vm.dtPosicao = res.dtPosicao;
        vm.periodos.push({
          'periodo': '',
          'periodofmt': 'Escolha o Período'
        });
        res.data.forEach((filtros) => {
          filtros.periodosInicial.forEach((dataPer) => {
            if (vm.myForm.get('periodo').value === 'M') {
              vm.periodos.push({
                'periodo': dataPer.periodo,
                'periodofmt': dataPer.periodo.substring(4, 6) + '/' + dataPer.periodo.substring(0, 4)
              });
            } else {
              vm.periodos.push({
                'periodo': dataPer.periodo,
                'periodofmt': dataPer.periodo
              });
            }
          });
        });
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('ArrecadacaoLiquidaController', 'popularCampos():error');
      let message = 'Falha ao popular campos ArrecadacaoLiquidaController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }

    const entrada = new HttpParams().append('periodo', vm.myForm.get('periodo').value);

    this.webservice.read('ws/relatorios/arrecadacaoliquida/filtros', entrada, success, fail);
  }

  carregaperiodo(per) {
    this.myForm.patchValue({
      periodoInicial: null,
      periodoFinal: null,
    });
    this.myForm.get('periodo').setValue(per);
    this.popularCampos();
  }

  public imprimir()  {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoLiquidaController', 'imprimir()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }

    let params = new HttpParams().append('uf', vm.getLista(vm.ufsSelected, 'itemName'));
    params = params.append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheUf', vm.myForm.get('detalhe2').value);

    this.webservice.read('ws/relatorios/imprimirarrecadacaoliquida', params, success, null);

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoLiquidaController', 'imprimir():success');
      const filename = 'ArrecadaçãoLiquida.pdf';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  public exportar()  {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoLiquidaController', 'exportar()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('uf', vm.getLista(vm.ufsSelected, 'itemName'));
    params = params.append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheUf', vm.myForm.get('detalhe2').value);
    params = params.append('ordemPeriodoResultado', vm.myForm.get('ordemPeriodoResultado').value);
    this.webservice.read('ws/relatorios/exportararrecadacaoliquida', params, success, null);

    function success(res) {
      vm.analytics.trackEvent('ArrecadacaoLiquidaController', 'exportar():success');
      const filename = 'ArrecadaçãoLiquida.xls';
      const linkElement = document.createElement('a');
      const sliceSize = 512;
      const b64Data = res.relat.replace(/\s/g, ''); // IE compatibility...
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: 'application/xls'});
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute('download', filename);
      const clickEvent = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      linkElement.dispatchEvent(clickEvent);
    }
  }

  ordenarResultado(val: string) {
    this.myForm.get('ordemPeriodoResultado').setValue(val);
    this.ordemPeriodoResultado = val === 'D' ? 'desc' : 'asc';
  }

  onItemSelect(item: any) {
    //
  }
  OnItemDeSelect(item: any) {
    //
  }
  onSelectAll(items: any) {
    //
  }
  onDeSelectAll(items: any) {
    //
  }

  getLista(arraylist, atrib) {
    let res = null;
    let arrayStr: Array<string> = null;
    if (arraylist != null) {
      if (atrib === 'id') {
        arrayStr = arraylist.map(o => o.id.trim());
      } else {
        arrayStr = arraylist.map(o => o.itemName.trim());
      }
    }

    if (arrayStr !== null && arrayStr.length > 0) {
      res = JSON.stringify(arrayStr);
      res = res.replace('[', '').replace(']', '');
    }
    return res;
  }

  public exportarCsv()  {
    const vm = this;
    this.analytics.trackEvent('ArrecadacaoLiquidaController', 'exportar()');
    if (vm.myForm.get('periodoInicial').value === '' || vm.myForm.get('periodoFinal').value === ''
        || vm.myForm.get('periodoInicial').value === null || vm.myForm.get('periodoFinal').value === null) {
      vm.alertService.showMessage('Atenção', 'Favor informar Período Inicial e Final.');
      return;
    }
    if (parseInt(vm.myForm.get('periodoInicial').value, 10) > parseInt(vm.myForm.get('periodoFinal').value, 10)) {
      vm.alertService.showMessage('Atenção', 'O período inicial deve ser menor ou igual ao período final.');
      return;
    }
    let params = new HttpParams().append('uf', vm.getLista(vm.ufsSelected, 'itemName'));
    params = params.append('periodo', vm.myForm.get('periodo').value);
    params = params.append('periodoInicial', vm.myForm.get('periodoInicial').value);
    params = params.append('periodoFinal', vm.myForm.get('periodoFinal').value);
    params = params.append('detalheUf', vm.myForm.get('detalhe2').value);
    params = params.append('ordemPeriodoResultado', vm.myForm.get('ordemPeriodoResultado').value);
    vm.webservice.postDownload('ws/relatorios/exportararrecadacaoliquidacsv', null, 'text/plain', true, 'arrecadacaoliquida.csv', params);
  }

  /**
   * Retorna os dados da tabela ordenados por uma coluna.
   */
  sortData(sort: Sort) {
    this.tblData = this.appService.sortData(sort, this.tblData);
  }
}
