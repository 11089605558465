import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';
import { PerfilfuncionalidadeComponent } from './perfilfuncionalidade.component';

const routes: Routes = [
  { path: '', component: PerfilfuncionalidadeComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PerfilfuncionalidadeRoutingModule { }
