import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../../../pipe.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerfilpastaRoutingModule } from './perfilpasta-routing.module';
import { PerfilpastaComponent } from './perfilpasta.component';

@NgModule({
  declarations: [PerfilpastaComponent],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, NgxPaginationModule, PipeModule,
    PerfilpastaRoutingModule
  ]
})
export class PerfilpastaModule { }
