import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import 'moment/locale/pt-br';
import { Config } from '../../../environments/config';
import { CurrentView } from '../../../environments/currentview';
import { AlertService } from '../../services/alert/alert.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ApplicationService } from '../../services/application/application.service';
import { LogService } from '../../services/log/log.service';
import { WebServiceService } from '../../services/web-service/web-service.service';
import { UtilsService } from './../../services/utils/utils.service';

@Component({
  selector: 'app-mensagemgestorcadastro',
  templateUrl: './mensagemGestorCadastro.component.html',
  styleUrls: ['./mensagemGestorCadastro.component.css'],

})
export class MensagemGestorCadastroComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService, private alertService: AlertService) {  }

  currentview = CurrentView;
  myForm: FormGroup;
  perfilContent = [];
  tblcontentFunctionClass = ['fa-pencil-square-o', 'fa-trash-o'];
  //
  timer = null;
  minDate: Moment;
  minDateEnd: Moment;

  ngOnInit() {
    this.log.info('MensagemGestorCadastroComponent.init()');
    this.analytics.trackEvent('controller', 'MensagemGestorCadastroController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      titulo: null,
      mensagem: null,
      dtInicial: null,
      dtFinal: null,
      destaque: null
    });
    moment.locale('pt-br');
    this.minDate = moment(new Date());
    this. atualizarData();
  }

  atualizarData() {
    this.minDateEnd = this.minDate;
    if (this.myForm.get('dtInicial') && this.myForm.get('dtInicial').value !== null) {
      this.minDateEnd = moment(this.myForm.get('dtInicial').value);
    }
  }

  retornar() {
    this.appService.openPage('mensagemgestor');
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      id: null,
      titulo: null,
      mensagem: null,
      dtInicial: null,
      dtFinal: null,
      destaque: null
    });
  }

  convertTextoEmpty(texto) {
    let res = '';
    if (texto && texto.value !== null) {
      res = texto.value;
    }
    return res;
  }

  incluir() {
    const vm = this;
    this.analytics.trackEvent('MensagemGestorCadastroController', 'incluir()');
    const titulo = vm.convertTextoEmpty(vm.myForm.get('titulo'));
    const dataInicial = vm.convertTextoEmpty(vm.myForm.get('dtInicial'));
    const dataFinal = vm.convertTextoEmpty(vm.myForm.get('dtFinal'));
    const destaque = vm.convertTextoEmpty(vm.myForm.get('destaque'));
    let mensagem = vm.convertTextoEmpty(vm.myForm.get('mensagem'));
    mensagem = vm.appService.fixedEncodeUri(mensagem);
    if (titulo.length === 0) {
      vm.alertService.showMessage('Atenção', 'Favor informar o título da mensagem.');
      return;
    }
    if (mensagem.length === 0) {
      vm.alertService.showMessage('Atenção', 'Favor informar o corpo da mensagem.');
      return;
    }
    if (dataInicial.length === 0 || dataFinal.length === 0) {
      vm.alertService.showMessage('Atenção', 'Favor informar as datas inicial e final.');
      return;
    }
    if ( moment(dataFinal).diff(moment(dataInicial), 'days') < 0 ) {
      vm.alertService.showMessage('Atenção', 'A data inicial deve ser menor ou igual a data final.');
      return;
    }

    function success(res) {
      vm.analytics.trackEvent('MensagemGestorCadastroController', 'incluir():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        vm.alertService.showMessage('Atenção', 'Inclusão realizada com sucesso.');
        vm.limpar();
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('MensagemGestorCadastroController', 'incluir():fail');
      let message = 'Falha ao incluir a mensagem.';
      message = (status === 404) && err ? err : message;
      if (status === 502 || err === 401) {
        this.application.goAuth();
      }
      vm.alertService.showMessage('Atenção', message);
    }

    const params = {
      'titulo': titulo,
      'mensagem': mensagem,
      'dataInicial': moment(dataInicial).format('DD/MM/YYYY'),
      'dataFinal': moment(dataFinal).format('DD/MM/YYYY'),
      'destaque': destaque
    };

    vm.webservice.post('ws/mensagemGestor', JSON.stringify(params), success, fail);
  }
}
