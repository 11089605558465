import { Component, OnInit } from '@angular/core';
import { LogService } from '../../../services/log/log.service';
import { Config } from '../../../../environments/config';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { CurrentView } from '../../../../environments/currentview';
import { ApplicationService } from '../../../services/application/application.service';
import { WebServiceService } from '../../../services/web-service/web-service.service';
import { GeolocationService } from '../../../services/geolocation/geolocation.service';
import { AlertService } from '../../../services/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { NativeInterfaceService } from './../../../services/native-interface/native-interface.service';
import { UiService } from '../../../ui.service';

@Component({
  selector: 'app-perfilpesquisar',
  templateUrl: './perfilpesquisar.component.html',
  styleUrls: ['./perfilpesquisar.component.css']
})

export class PerfilpesquisarComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    private appService: ApplicationService, private webservice: WebServiceService, private router: Router,
    private alertService: AlertService, private geolocation: GeolocationService, private nativeInterface: NativeInterfaceService,
    private uiservice: UiService) {
    this.native = nativeInterface;
  }

  currentview = CurrentView;
  myForm: FormGroup;
  perfilContent = [];
  tbl = ['Sigla', 'Descrição'];
  tblData = [];
  tblcontentFunctionClass = ['fa-pencil-square-o', 'fa-trash-o'];
  //
  filtrotbl = null;
  fulltbl2Data = [];
  pageOptions = [10, 20, 50, 100];
  itemsOnPagetbl2 = 10;
  numberPagetbl2 = [];
  numberPagetbl2Selected = 0;
  exibirTabela = false;
  native: NativeInterfaceService;
  perfil = null;
  acessoInclusao = false;
  acessoAlteracao = false;
  acessoExclusao = false;
  isFavorito = false;
  idFavorito = 0;
  idUsuario = null;
  idItemFavorito = 0;
  caminho = '';
  p: number;

  ngOnInit() {
    this.analytics.trackEvent('controller', 'PerfilpesquisarController');
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      sigla: null,
      descricao: null,
      status: null
    });
    this.p = 1;
    this.perfil = this.appService.getPerfilUsuario();
    this.idUsuario = this.appService.getIdUsuario();
    this.caminho = this.router.url.replace('/', '');
    this.acessoInclusao = this.appService.temAutorizacao('pesquisarUsuario', 'I');
    this.acessoAlteracao = this.appService.temAutorizacao('pesquisarUsuario', 'A');
    this.acessoExclusao = this.appService.temAutorizacao('pesquisarUsuario', 'E');
    this.initFavorite();
  }

  listar() {
    const vm = this;
    this.analytics.trackEvent('PerfilpesquisarController', 'listar()');
    vm.tblData = [];
    const sigla = vm.myForm.get('sigla').value;
    const desc = vm.myForm.get('descricao').value;
    let params = new HttpParams();
    vm.p = 1;
    if (sigla == null && desc == null) {
      this.webservice.read('ws/perfis', null, success, fail);
    } else {
      if (sigla != null) {
        params = params.append('sigla', sigla);
      }
      if (desc != null) {
        params = params.append('descricao', desc);
      }
      this.webservice.read('ws/perfis', params, success, fail);
    }


    function success(res) {
      vm.analytics.trackEvent('PerfilpesquisarController', 'listar():success');
      if (res && res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.tblData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          vm.tblData.push({
            'sigla': element.sigla,
            'descricao': element.descricao !== null ? element.descricao.trim() : null
          });
        });
      }
      vm.exibirTabela = true;
    }

    function fail(xhr, status, err) {
      vm.uiservice.failApi( vm, 'PerfilpesquisarController', 'listar():error', xhr, status, err);
    }
  }

  getPlatforms(platforms) {
    return '';
  }

  limpar() {
    this.myForm.patchValue({
      sigla: null,
      descricao: null
    });
    this.tblData = [];
    this.exibirTabela = false;
    this.p = 1;
  }

  alterar(row) {
    this.appService.openPage('perfilcadastro', row);
  }

  confirmDelete(sigla) {
    const vr = this;

    function success(res) {
      vr.analytics.trackEvent('PerfilpesquisarController', 'confirmDelete():success');
      if (res.temErro) {
        const msg = 'Ocorreu um erro ao excluir perfil - ' + res.msgsErro[0];
        vr.log.error(msg);
        vr.alertService.showMessage('Erro', msg);
      } else {
        vr.alertService.showMessage('Atenção', 'Perfil excluído com sucesso.');
        vr.listar();
      }
    }

    function fail(xhr, status, err) {
      let message = 'Falha ao excluir Perfil';
      if (status === 403 || status === 400) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vr.log.error(message);
      vr.alertService.showMessage('Erro', message);
    }



    function confirm() {
      vr.log.info('Exclusão confirmada. Executando exclusao no backend.');
      vr.webservice.deleta('ws/perfis/' + sigla, null, null, success, fail);
    }

    function deny() {
      vr.log.info('Exclusão cancelada pelo usuário.');
    }

    vr.alertService.showConfirm('Atenção', 'Confirma a exclusão deste item?', confirm, deny);
  }

  initFavorite() {
    const vm = this;
    this.analytics.trackEvent('PesquisarusuarioController', 'initFavorite()');

    function success(res) {
      vm.analytics.trackEvent('PesquisarusuarioController', 'initFavorite():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.isFavorito = res.idFavorito != null;
      vm.idFavorito = res.idFavorito;
      vm.idItemFavorito = res.idItemFavorito;
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('PesquisarusuarioController', 'initFavorite():error');
      let message = 'Falha ao configurar Favorito PesquisarusuarioController';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        vm.appService.goAuth();
      }
    }
    const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
    this.webservice.read('ws/favoritos', params, success, fail);
  }

  doFavorite() {
    const vm = this;

    // verificar operacao
    if (vm.idFavorito == null) {
      const params = {'idItemFavorito': this.caminho, 'idUsuario': vm.idUsuario};
      vm.webservice.create('ws/favoritos', params, success, fail);
    } else {
      vm.webservice.delete('ws/favoritos/' + vm.idFavorito + '/F', null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('PesquisarusuarioController', 'favoritos():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        const op = res.operacao;
        if (op === 'I') {
          vm.isFavorito = true;
          vm.idFavorito = res.idFavorito;
        } else {
          vm.isFavorito = false;
          vm.idFavorito = null;
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('FaleconoscoController', 'favoritos():error');
      let message = 'Falha ao atualizar o pesquisa usuario como favorito.';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      if (status === 400) {
        if (err) {
          message = err;
        } else {
          message = xhr;
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        vm.appService.goAuth();
      }
      vm.alertService.showMessage('Erro', message);
    }
  }
}
