import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { CurrentView } from '../../../environments/currentview';
import { AlertService } from '../../services/alert/alert.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ApplicationService } from '../../services/application/application.service';
import { LogService } from '../../services/log/log.service';
import { WebServiceService } from '../../services/web-service/web-service.service';

@Component({
  selector: 'app-cadastrarobservacaofuncionalidade',
  templateUrl: './cadastrarobservacaofuncionalidade.component.html',
  styleUrls: ['./cadastrarobservacaofuncionalidade.component.css']
})
export class CadastrarobservacaofuncionalidadeComponent implements OnInit {

  constructor(private log: LogService, private analytics: AnalyticsService, private formBuilder: FormBuilder,
    public appService: ApplicationService, private webservice: WebServiceService,
    private alertService: AlertService, private actroute: ActivatedRoute) {
    //
  }

  currentview = CurrentView;
  myForm: FormGroup;
  myOldForm: FormGroup;
  idObservacao = null;
  inclusao = false;
  funcionalidades = [];
  funcionalidade = null;
  hasChanged = false;
  timer = null;
  marcacao = null;

  // -------------------------------------------------------------------------------------------
  // Para evitar o erro: ERROR in node_modules/ng2-ckeditor/ckeditor.component.d.ts(36,9):
  //                     error TS1086: An accessor cannot be declared in an ambient context.
  // Incluir atributo em tsconfig.json: compilerOptions.skipLibCheck: true
  // -------------------------------------------------------------------------------------------
  public config = CKEDITOR.config;
  public editor = CKEDITOR.editor;
  @ViewChild('ckeObservacaoFuncionalidade', {static: false}) ckeditor: any;
  marcadores = [];
  marcadoresHistorico = [
    {'id': '##', 'descricao': 'Ano (##)'},
    {'id': '$$', 'descricao': 'Título ($$)'},
    {'id': '**', 'descricao': 'Descrição (**)'},
    {'id': '%%', 'descricao': 'Complemento (%%)'}
  ];
  marcadoresPlanejamento =  [
    {'id': '##', 'descricao': 'Titulo (##)'},
    {'id': '$$', 'descricao': 'Subtítulo ($$)'},
    {'id': '**', 'descricao': 'Descrição (**)'},
    {'id': '++', 'descricao': 'Objetivos (++)'},
    {'id': '--', 'descricao': 'Item objetivos (--)'},
    {'id': '&&', 'descricao': 'Link anual (&&)'},
    {'id': '%%', 'descricao': 'Link referência anual (%%)'},
    {'id': '!!', 'descricao': 'Link normativos (!!)'},
    {'id': ';;', 'descricao': 'Link referência normativos (;;)'}
  ];

  ngOnInit() {
    const vm = this;
    this.currentview.locked = false;
    this.currentview.menu = true;
    this.myForm = this.formBuilder.group({
      funcionalidade: [ null , [ Validators.required ]],
      ambiente: 'T',
      id: null,
      descricao: null
    });
    this.myOldForm = this.formBuilder.group({
      funcionalidade: [ null , [ Validators.required ]],
      ambiente: 'T',
      id: null,
      descricao: null
    });

    // Obter o id selecionado na listagem da tela de consulta.
    this.idObservacao = this.actroute.snapshot.params['id'];
    const prms = this.actroute.snapshot.params;
    this.popularForm(prms);
    this.onValueChanges();
    this.hasChanged = this.myForm.get('id').value == null;

    // configurar toolbar do CKEditor.
    vm.config = {
      language: 'pt-br',
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      toolbar: [
        { name: 'styles', items: [ 'Font', 'FontSize' ] },
        { name: 'colors', items: [ 'TextColor' ] },
        { name: 'others', items: [ '-' ] },
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] }, // items: [ 'Source' ] },
        '/',
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike', '-', 'CopyFormatting', 'RemoveFormat' ] },
        { name: 'insert', items: [ 'Image', 'Table' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
      ]
    };
  }

  popularForm(dados) {
    const vm = this;
    this.inclusao = dados && dados.id == null;
    this.analytics.trackEvent('CadastrarObservacaoFuncionalidadeComponent', 'popularForm()');
    this.webservice.read('ws/observacoesfuncionalidades/filtros', null, success, fail);

    function success(res) {
      vm.analytics.trackEvent('CadastrarObservacaoFuncionalidadeComponent', 'popularForm():success');
      if (res.temErro) {
        vm.log.error(res.msgsErro[0]);
        return;
      }
      vm.funcionalidades = [];
      vm.funcionalidade = null;
      if (res.data && res.data.length > 0) {
        res.data.forEach((f) => {
          vm.funcionalidades.push({
            'id': f.id,
            'descricao': f.descricao
          });
        });

        let amb = null;
        let fun = null;
        let obs = null;
        if (vm.inclusao) {
          amb = 'T';
        } else {
          amb = dados.idAmbiente;
          // ATENÇÃO: converter o id de funcionalidade para numerico. Sem isto, não selecionava o item da funcionalidade e nem exibia na combo.
          fun = +dados.idFuncionalidade;
          obs = dados.descricao;
        }
        vm.myForm.patchValue({
          id: dados.id,
          funcionalidade: fun,
          ambiente: amb,
          descricao: obs
        });
        vm.myOldForm.patchValue({
          id: dados.id,
          funcionalidade: fun,
          ambiente: amb,
          descricao: obs
        });
        vm.funcionalidades.forEach((f) => {
            if (f.id === vm.myForm.get('funcionalidade').value) {
              vm.funcionalidade = f.descricao;
            }
        });
        if (vm.funcionalidade === 'Histórico' || vm.funcionalidade === 'Indicador Estratégico') {
          vm.config = {
            language: 'pt-br',
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true,
            toolbar: [
              { name: 'styles', items: [ 'Font', 'FontSize' ] },
              { name: 'others', items: [ '-' ] },
              '/',
              { name: 'insert', items: [ 'Image', 'Table' ] }
            ]
          };
          vm.marcadores = [];
          if (vm.funcionalidade === 'Histórico') {
            vm.marcadores = vm.marcadoresHistorico;
          } else {
            vm.marcadores = vm.marcadoresPlanejamento;
          }
        }
      }
    }

    function fail(xhr, status, err) {
      vm.analytics.trackEvent('CadastrarObservacaoFuncionalidadeComponent', 'popularForm():error');
      let message = 'Falha ao popular form CadastrarObservacaoFuncionalidadeComponent';
      if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message, err);
      if (xhr.status === 502 || err === 401) {
        this.application.goAuth();
      }
    }
  }

  limpar() {
    this.myForm.patchValue({
      funcionalidade: null,
      ambiente: 'T',
      id: null,
      descricao: null
    });
    this.myOldForm.patchValue({
      funcionalidade: null,
      ambiente: 'T',
      id: null,
      descricao: null
    });
    this.marcacao = '';
  }

  onValueChanges(): void {
    const vm = this;
    if (this.myForm.get('id').value !== null) {
      this.myForm.valueChanges.subscribe(val => {
        vm.hasChanged = !_.isEqual(val, vm.myOldForm.value);
      });
    }
  }

  salvar() {
    const vm = this;
    this.analytics.trackEvent('CadastrarObservacaoFuncionalidadeComponent', 'salvar()');
    const fun = this.myForm.get('funcionalidade').value;
    if (!fun || fun === '') {
      vm.alertService.showMessage('Atenção', 'O campo Funcionalidade deve ser informado.');
      return;
    }
    const amb = this.myForm.get('ambiente').value;
    if (!amb || amb.trim() === '') {
      vm.alertService.showMessage('Atenção', 'O campo Ambiente deve ser informado.');
      return;
    }
    let obs = this.myForm.get('descricao').value;
    obs = obs.replaceAll(/<[^>]*>/gi, '');
    obs = obs.replaceAll(' ', '');
    obs = obs.replaceAll('&nbsp;', '');
    if (!obs || obs.trim() === '') {
      vm.alertService.showMessage('Atenção', 'O campo Observação deve ser informado.');
      return;
    }
    const observ = vm.myForm.value;
    observ.descricao = vm.appService.encodeUri(observ.descricao);
    if (vm.inclusao) {
      vm.webservice.create('ws/observacoesfuncionalidades', observ, success, fail);
    } else {
      vm.webservice.update('ws/observacoesfuncionalidades', observ, null, success, fail);
    }

    function success(res) {
      vm.analytics.trackEvent('CadastrarObservacaoFuncionalidadeComponent', 'salvar():success');
      if (res.temErro) {
        const msg = res.msgsErro[0];
        vm.log.error(msg);
        vm.alertService.showMessage('Erro', msg);
      } else {
        let oper = null;
        if (vm.inclusao) {
          oper = 'incluída';
        } else {
          oper = 'alterada';
        }
        vm.alertService.showMessage('Atenção', 'Observação ' + oper + ' com sucesso.');
        if (vm.inclusao) {
          vm.limpar();
        } else {
          vm.hasChanged = false;
        }
      }
    }

    function fail(xhr, status, err) {
      let message = 'Falha ao ';
      if (vm.inclusao) {
        message = message + 'incluir ';
      } else {
        message = message + 'alterar ';
      }
      message = message + 'Observação.';
      if (status === 403 || status === 400) {
        if (err) {
          message = err;
        } else if (xhr) {
          message = xhr;
        }
      } else if (xhr && xhr.responseText) {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response && response.msgsErro && response.msgsErro.length > 0) {
            message = response.msgsErro[0];
          }
        } catch (ignore) {
        }
      }
      vm.log.error(message);
      vm.alertService.showMessage('Erro', message);
    }
  }

  onChange(e) {
  }
  onPaste(e) {
  }

  inserirCampo_onChange(val) {
    if (val !== '') {
      this.ckeditor.instance.insertText(val);
      setTimeout(() => this.marcacao = '');
    }
  }
}
